import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { F2eHelper } from '../helper';
import { SavePasswordInfo, UserInfoItem, LocalStorageKey } from '../types';
import { StorageService } from './stroage.service';

declare var require: any;

interface DeviceInfo {
  userInfo: UserInfoItem;
  domains: string[];
}

interface IBridge {
  nativeSetInfoFunc(data: string): void;
  nativeGetInfoFunc(key: string): void;
  nativeExternalBrowser(url: string): void;
  nativeSendInfoFunc(key: string, args: any): void;
  nativeCall(method: string, callback: string, args: string): void;
}

interface IWindow {
  nativeCallback?: any;
  nativeCallTest?: any;
  WebViewJavascriptBridge?: IBridge;
  BridgeAuthority?: string;
}

interface INativeCallResult {
  error: string;
  data: any;
}

@Injectable()
export class DeviceService {

  private onNativeResult = {};
  private nativeCallbackMap: { [key: string]: any } = {};
  private keepLoginSession = true;
  private flagBridgeReady: boolean | null = null;
  private supportedMethods?: string;

  private bridgeReadyTimeout = 500;

  constructor(
    private myStorageSer: StorageService,
  ) { }

  init(jsBrigeReadyTimeout: number) {
    console.log('device.service init.');
    this.bridgeReadyTimeout = jsBrigeReadyTimeout;
    if (F2eHelper.isPlatformBrowser) {
      const i = window as IWindow;
      i.nativeCallback = (key: string, result: string) => {
        this.nativeCallback(key, result);
      };
      i.nativeCallTest = () => {
        return this.nativeCallTest();
      };
    }
  }

  async setDeviceId(deviceId = '', isForce = false) {
    if (isForce && deviceId) {
      this.myStorageSer.setItem(LocalStorageKey.DeviceId, deviceId);
      if (await this.isNativeCallSupported()) {
        this.nativeCall('nativeStorageSet', { key: LocalStorageKey.DeviceId, value: deviceId });
      }
      return;
    }

    const id = await this.getDeviceId();
    if (!id) {
      const uuid = F2eHelper.getUUID4();
      this.myStorageSer.setItem(LocalStorageKey.DeviceId, uuid);
      if (await this.isNativeCallSupported()) {
        this.nativeCall('nativeStorageSet', { key: LocalStorageKey.DeviceId, value: uuid });
      }
    }
  }

  async getDeviceId(): Promise<string | null> {
    let deviceId = await this.myStorageSer.getItem<string>(LocalStorageKey.DeviceId);
    if (!deviceId) {
      // 判斷有實作 nativeCall Api 介面才進行資料存取
      if (await this.isNativeCallSupported()) {
        deviceId = await this.nativeCall('nativeStorageGet', { key: LocalStorageKey.DeviceId });
      }
    }
    return deviceId;
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => { resolve(); }, ms);
    });
  }

  async bridgeReady(timeout: number) {
    if (this.flagBridgeReady !== null) {
      return this.flagBridgeReady;
    }
    if (!F2eHelper.isInApp) {
      this.flagBridgeReady = false;
      return false;
    }
    const tsTimeout: number = Date.now() + timeout;
    do {
      const i = window as IWindow;
      if (i.WebViewJavascriptBridge && i.BridgeAuthority) {
        this.flagBridgeReady = true;
        return true;
      }
      await this.delay(20);
    } while (Date.now() < tsTimeout);
    this.flagBridgeReady = false;
    console.warn(`等不到原生桥，已经超过 ${tsTimeout} 毫秒了`);
    return false;
  }

  private get bridge() {
    const i = window as IWindow;
    if (i.WebViewJavascriptBridge) {
      return i.WebViewJavascriptBridge as IBridge;
    }
    return undefined;
  }

  private nativeCallback(cbKey: string, cbResults: string) {
    if (cbKey in this.nativeCallbackMap) {
      const callback = this.nativeCallbackMap[cbKey];
      delete this.nativeCallbackMap[cbKey];
      // callback(JSON.parse(atob(cbResults)));
      callback(JSON.parse(Base64.decode(cbResults)));
    }
  }

  private nativeCall(method: string, args: any) {
    const authority = this.getBridgeNativeCallAuthority();
    if (authority) {
      args = { ...args, ___AUTHORITY___: authority };
    }
    return new Promise<string>((resolve, reject) => {
      if (!this.bridge || !this.bridge.nativeCall) {
        return reject('native bridge not implement: nativeCall');
      }
      let cbKey: string;
      do {
        cbKey = F2eHelper.getUUID4();
      } while (this.nativeCallbackMap[cbKey] !== undefined);
      this.nativeCallbackMap[cbKey] = (result: INativeCallResult) => {
        if (result.error) {
          return reject(result.error);
        }
        resolve(result.data || undefined);
      };
      this.bridge.nativeCall(method, cbKey, JSON.stringify(args));
    });
  }

  private async isNativeCallSupported(methodName?: string) {
    await this.bridgeReady(this.bridgeReadyTimeout);
    if (this.bridge !== undefined && this.bridge.nativeCall !== undefined) {
      if (methodName) {
        // 建立支援功能列表(快取)
        if (!this.supportedMethods) {
          this.supportedMethods = await this.nativeCall('methods', {});
        }
        // 檢查支援功能
        if (!this.supportedMethods || !this.supportedMethods.includes(methodName)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private getBridgeNativeCallAuthority() {
    if (window) {
      const i = window as IWindow;
      if (i.BridgeAuthority) {
        return i.BridgeAuthority;
      }
    }
    return false;
  }

  private async nativeCallTest() {
    if (!this.isNativeCallSupported()) {
      console.log('API: nativeCall not supported!');
      return;
    }
    let result: any;
    const ts = `ts=${Date.now()}`;
    console.log('support methods: ', await this.nativeCall('methods', {}))
    console.log('set "ts":', await this.nativeCall('nativeStorageSet', { key: 'ts', value: ts }));
    console.log('set "rand":', await this.nativeCall('nativeStorageSet', { key: 'rand', value: Math.random() }));
    console.log('set "rand123":', await this.nativeCall('nativeStorageSet', { key: 'rand123', value: null }));
    result = await this.nativeCall('nativeStorageGet', { key: 'ts' });
    console.log(`get ts from nativeStorage:`, result, `(${ts})`);
    result = await this.nativeCall('nativeStorageGet', { key: 'rand' });
    console.log(`get rand from nativeStorage:`, result);
    result = await this.nativeCall('nativeStorageGet', { key: 'rand123' });
    console.log(`get rand123 from nativeStorage:`, result);
    result = await this.nativeCall('nativeStorageKeys', {});
    console.log('get keys:', result);
    await this.nativeCall('nativeStorageRemove', { key: 'rand' });
    result = await this.nativeCall('nativeStorageKeys', {});
    console.log('get keys (after rm "rand"):', result);
    result = await this.nativeCall('nativeStorageGet', { key: 'rand' });
    console.log(`get rand from nativeStorage:`, result);
    await this.nativeCall('nativeStorageReset', {});
    result = await this.nativeCall('nativeStorageKeys', {});
    console.log('get keys (after reset storage):', result);

    result = await this.nativeCall('nativeClipboardRead', {});
    console.log('read clipboard:', result);
    result = await this.nativeCall('nativeClipboardWrite', { text: `test copy text ${Date.now() % 100} 跟中文` });
    console.log('write clipboard:');

    result = await this.nativeCall('readSMS', {}).catch(err => console.error(err));
    console.log('read SMS:', result);

    if (await this.isConnectionTestSupported()) {
      result = await this.connectionAbilityTest('https://google.com');
      console.log(`connection ability test:`, result);
    } else {
      console.warn('unsupported method');
    }

  }

  /** 將用戶 Session 寫入 LocationStorage & NativeStorage */
  async syncUserInfo(userInfo?: UserInfoItem | null) {
    if (!this.keepLoginSession) {
      return;
    }

    if (userInfo) {
      await this.myStorageSer.setItem(LocalStorageKey.UserInfo, userInfo);
    } else {
      await this.myStorageSer.removeItem(LocalStorageKey.UserInfo);
    }

    const bridgeEnable = await this.bridgeReady(this.bridgeReadyTimeout);
    if (bridgeEnable) {
      if (userInfo) {
        await this.nativeCall('nativeStorageSet', { key: LocalStorageKey.UserInfo, value: JSON.stringify(userInfo) });
      } else {
        await this.nativeCall('nativeStorageRemove', { key: LocalStorageKey.UserInfo });
      }
    }
  }

  /** 將 Hosts 寫入 LocationStorage & NativeStorage */
  async syncHosts(hosts: string[]) {
    this.myStorageSer.setItem(LocalStorageKey.Domain, hosts);
    if (await this.isNativeCallSupported()) {
      await this.nativeCall('nativeStorageSet', { key: LocalStorageKey.Domain, value: JSON.stringify(hosts) });
    }
  }

  async openBrowser(url: string) {
    if (await this.isNativeCallSupported('nativeExternalBrowser')) {
      this.nativeCall('nativeExternalBrowser', { url });
      return true;
    }
    if (this.bridge && this.bridge.nativeExternalBrowser) {
      this.bridge.nativeExternalBrowser(url);
      return true;
    }
    return false;
  }

  /** 將資料寫入 LocalStorage 與 DeviceStorage */
  private async setInfo(info: DeviceInfo) {
    this.myStorageSer.setItem(LocalStorageKey.UserInfo, info.userInfo);
    this.myStorageSer.setItem(LocalStorageKey.Domain, info.domains);
    const bridgeEnable = await this.bridgeReady(this.bridgeReadyTimeout);
    if (bridgeEnable) {
      console.log('save data into device');
      const jsonStr = JSON.stringify(info);
      this.bridge?.nativeSetInfoFunc(jsonStr);
    }
  }

  /** 判斷記憶帳號密碼功能是否起用 */
  async isSavePasswordSupported() {
    return true;
    // return await this.isNativeCallSupported();
  }

  /** 檢查是否有啟用記憶帳號密碼 */
  async getSavePasswordStatus() {
    const defaultSetting = new SavePasswordInfo();
    // 先讀取 LocalStorage 資料
    const localSavePwd = await this.myStorageSer.getItem<SavePasswordInfo>(LocalStorageKey.SavePassword);
    if (localSavePwd) {
      return {
        ...defaultSetting,
        ...localSavePwd
      };
    }
    // 沒有的話在是讀取 Native 資料
    if (await this.isNativeCallSupported()) {
      const result = await this.nativeCall('nativeStorageGet', { key: 'save-password' });
      if (result) {
        const jsonInfo = JSON.parse(result);
        return {
          ...defaultSetting,
          ...jsonInfo
        };
      }
    }
    // 在沒有....只好回傳預設值了
    return defaultSetting;
  }

  /** 更新記憶密碼資料組態 */
  async setSavePasswordStatus(info: SavePasswordInfo) {
    await this.myStorageSer.setItem(LocalStorageKey.SavePassword, info);
    if (await this.isNativeCallSupported()) {
      await this.nativeCall('nativeStorageSet', { key: 'save-password', value: JSON.stringify(info) });
    }
  }

  /** 設定是否要將 userInfo 儲存到 localStorage & nativeStorage */
  async setSyncUserInfoEnable(enable: boolean) {
    if (this.keepLoginSession !== enable) {
      this.keepLoginSession = enable;
    }
  }

  /** 關閉原生的 Splash 頁 */
  async closeNativeSplash() {
    await this.bridgeReady(this.bridgeReadyTimeout);
    if (!this.isNativeCallSupported('hideSplash')) {
      console.log('API: nativeCall not supported!');
      console.warn('Bredge 不支援關閉 Native Splash 功能');
      return false;
    }
    await this.nativeCall('hideSplash', {});
    return false;
  }

  getVersionInfo() {
    let appVersion = '1.0.0';
    let assetVersion = '1.0.0';

    const iwin = window as unknown as { GGVersion: any };
    if (iwin && iwin.GGVersion) {
      const GGVersion = iwin.GGVersion;
      if (GGVersion.hasOwnProperty('appVersion')) { appVersion = GGVersion.appVersion; }
      if (GGVersion.hasOwnProperty('assetVersion')) { assetVersion = GGVersion.assetVersion; }
      if (GGVersion.hasOwnProperty('assetBuildInfo')) {
        const res = /sha:'([0-9a-f]+)'/.exec(GGVersion.assetBuildInfo);
        if (res) {
          console.log(res[1]);
          assetVersion = `${assetVersion} (${res[1].substr(0, 5)})`;
        }
      }
    } else {
      console.warn('Oops, lost GGVersion');
    }
    return `AP: v${appVersion} ; AB: v${assetVersion}`;
  }

  getAppVersion() {
    const iwin = window as any as { GGVersion: { appVersion: string, assetVersion: string } };
    if (iwin && iwin.GGVersion && iwin.GGVersion.appVersion) {
      return iwin.GGVersion.appVersion;
    }
    return '1.0.0';
  }

  getAssetVersion() {
    const iwin = window as any as { GGVersion: { appVersion: string, assetVersion: string } };
    if (iwin && iwin.GGVersion && iwin.GGVersion.appVersion) {
      return iwin.GGVersion.assetVersion;
    }
    return '1.0.0';
  }

  /** 原生寫 Devtool/Log */
  async devToolLog(log: string) {
    if (await this.isNativeCallSupported('nativeDevtoolLog')) {
      await this.nativeCall('nativeDevtoolLog', { log });
    }
  }

  /** 更新版本 */
  async getUpdateSession(session: number = 0, extra: any = {}) {
    if (await this.isNativeCallSupported('nativeUpdate')) {
      const resp = await this.nativeCall('nativeUpdate', { ...{ session }, ...extra });
      const sessionCtx = JSON.parse(resp);
      if (sessionCtx) {
        return sessionCtx;
      }
    }
    return null;
  }

  /** 判斷是否有支援更新控制器 */
  async isUpdateApiSupported() {
    return await this.isNativeCallSupported('nativeUpdate');
  }

  /** 從剪貼簿讀取文字 */
  async readClipboardText() {
    if (await this.isNativeCallSupported('nativeClipboardRead')) {
      const resp = await this.nativeCall('nativeClipboardRead', {});
      console.warn(`剪貼簿 >> `, resp);
    }
  }

  /** 將文字寫入剪貼簿 */
  async writeClipboardText(text: string) {
    if (await this.isNativeCallSupported('nativeClipboardWrite')) {
      const resp = await this.nativeCall('nativeClipboardWrite', { text });
    }
  }

  /** 判斷是否支援剪貼簿API */
  async isClipboardSupported() {
    return await this.isNativeCallSupported('nativeClipboardRead'); // nativeClipboardWrite && nativeClipboardRead
  }

  /** 進行線路連線檢測 */
  async connectionAbilityTest(host: string) {
    return await this.nativeCall('nativeConnectionAbilityTest', { host });
  }

  /** 判斷是否支援網路檢測功能 */
  async isConnectionTestSupported() {
    return await this.isNativeCallSupported('nativeConnectionAbilityTest');
  }

  /** 判斷是否支援上傳媒體功能 */
  async isUploadMediaSupported() {
    return await this.isNativeCallSupported('nativeUploadPurchaseOrder');
  }

  /** 上傳媒體(帶訂單號) */
  async uploadPurchaseOrderFile(orderId: string, mimeTypes: string) {
    if (await this.isUploadMediaSupported()) {
      this.nativeCall('nativeUploadPurchaseOrder', { token: localStorage.getItem('token'), orderId, mimeTypes });
    }
  }

  /** 更新版本 */
  async getUploadProgress() {
    if (await this.isNativeCallSupported('nativeGetUploadStatus')) {
      const resp = await this.nativeCall('nativeGetUploadStatus', {});
      return JSON.parse(resp);
    }
    return null;
  }

  /** 取得SMS簡訊 */
  async getSMS(begin?: number, limit = 20): Promise<string> {
    if (await this.isNativeCallSupported('readSMS')) {
      const resp = await this.nativeCall('readSMS', {begin, limit}).catch(err => err);
      return JSON.stringify(resp);
    }
    return '不支持 readSMS';
  }
}


import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kr-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  _isLoading = true;
  _adaptOutside = false;
  _loadingFixed = false;
  _customStyle: { [key: string]: string } = {};

  @Input()
  set isLoading(v: boolean) {
    this._isLoading = v;
  }
  get isLoading() {
    return this._isLoading;
  }

  @Input()
  set adaptOutside(v: boolean) {
    this._adaptOutside = v;
  }
  get adaptOutside() {
    return this._adaptOutside;
  }

  @Input()
  set loadingFixed(v: boolean) {
    this._loadingFixed = v;
  }
  get loadingFixed() {
    return this._loadingFixed;
  }

  @Input()
  set customStyle(style: { [key: string]: string }) {
    this._customStyle = style;
  }
  get customStyle() {
    return this._customStyle;
  }

  constructor() { }

  ngOnInit() {
  }

}

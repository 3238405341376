import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConfigService, DeviceService, F2eHelper, MessageService, MyLogService, StorageService } from 'lib';
import { EnvType, LocalStorageKey, MyLoggerLevelEnum, StartupParams, UserInfo } from 'lib/src/types';
// import { VerifyUserEmailInputObj } from 'lib/src/types/schema';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '../component/alert/alert.component';
import { ConfirmComponent } from '../component/confirm/confirm.component';
import { NotifierComponent } from '../component/notifier/notifier.component';
import { PromptComponent } from '../component/prompt/prompt.component';
import { ThemeService } from './theme.service';
import { envConfig } from '../../../environments/environment';
import { setGraphqlHost } from '../../graphql.module';
import { HttpClient } from '@angular/common/http';
import { platformConfig } from '../../../platforms/platform';



declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  private hosts: string[] = [];
  private userInfo: UserInfo | undefined;  // 暫存資料, 用來在不同階段初始化時使用, 初始化完成後就不需要了

  emailAuthId: string = '';

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    private messageSer: MessageService,
    private myStorageSer: StorageService,
    private configSer: ConfigService,
    private deviceSer: DeviceService,
    // private networkServ: NetworkService,
    // private sessionServ: SessionService,
    private myLogSer: MyLogService,
    // private userSer: UserService,
    // private themeSer: ThemeService,
    private translate: TranslateService,
    private http: HttpClient
  ) {
    F2eHelper.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  async initApp() {
    // this.themeSer.initTheme();
    await this.processExternalParams();
    this.initNativeJavascriptBridge();
    await this.processPersistedData();
    await this.initLogService();
    this.initMessageService();
    this.initTranslate();
    // await this.initNetworkService();
    // await this.importDynamicConfigures();
    // await this.initSessionService();
    await this.networkTest();
    this.hideNativeSplash();
    // this.verifyMail();
  }


  /** 初始化 - 解析 Query 等外部參數 */
  private async processExternalParams() {
    if (F2eHelper.isPlatformBrowser) {
      const params = this.parseQueryString(location.search.substring(1)) as StartupParams;
      if (params.email_auth_id) {
        this.emailAuthId = params.email_auth_id;
      }
      if (params.force === 'device') {
        F2eHelper.isInApp = true;
      }
      if (navigator.userAgent.includes('GGININDER')) {
        console.log('detect mobile spec user-agent!');
        F2eHelper.isInApp = true;
      }
      if (location.hostname === 'app-buildin') {
        console.log('detect mobile spec host!');
        F2eHelper.isInApp = true;
      }
      if (params.token) {
        const userInfo: UserInfo = {
          id: parseInt(params.id || '', 10),
          token: params.token,
          is_beta: (params.is_beta === 'true'),
          account: params.account || '',
          user_nickname: params.user_nickname,
          user_avatar: params.user_avatar,
          is_proxy: params.is_proxy,
          lang: params.lang || '',
        };
        await this.myStorageSer.setItem(LocalStorageKey.UserInfo, userInfo);
      }

      if (params.token) {
        this.deviceSer.setDeviceId(params.deviceId, true);
      }

      if (location.hash.startsWith('#PT:')) {
        const portable = location.hash.substr(4);
        location.hash = '';
        await this.myStorageSer.import(portable);

        this.messageSer.notify('换线成功');
      }
    }
  }

  /** 初始化 - 處理預儲在 LocalStorage 中的資料 */
  private async processPersistedData() {
    if (F2eHelper.isPlatformBrowser) {
      // restore user_info
      this.userInfo = (await this.myStorageSer.getItem<UserInfo>(LocalStorageKey.UserInfo))!;
      // restore hosts & pre-process host-list
      const persistHosts = this.configSer.platformConfiguration.environmentSetting.api.hosts;
      const envType = this.configSer.envConfiguration.envType;
      const latestHosts = await this.myStorageSer.getItem<string[]>(LocalStorageKey.Domain) || [];
      let origin = [location.origin];
      if (envType === EnvType.local) {
        origin = [this.configSer.platformConfiguration.environmentSetting.api.hosts[0]];
      }
      if (F2eHelper.isInApp) {
        origin = [];
      }
      this.hosts = Array.from(new Set([...origin, ...latestHosts, ...persistHosts])).shuffle().limit(10);
      // set deviceId
      this.deviceSer.setDeviceId();
    }
  }

  private initTranslate() {
    console.log('init translate service');
    this.translate.setDefaultLang('zh-cn');
    this.translate.use('zh-cn').subscribe({
      error: err => {
        console.error(`多国语系(i18n)初始化异常`, err);
        // // TODO: 暫時應急解決 Android 兼容方案, 未來須考慮更完善的方案
        const backup = require('!!json5-loader!../../../assets/i18n/app/zh-cn.json');
        this.translate.setTranslation('zh-cn', backup.default);
      }
    });
  }

  private initMessageService() {
    this.messageSer.setAlertComponent(AlertComponent);
    this.messageSer.setConfirmComponent(ConfirmComponent);
    this.messageSer.setNotifyComponent(NotifierComponent);
    this.messageSer.setPromptComponent(PromptComponent);

    // setTimeout( async () => {
    //   this.messageSer.alert({ text: '我來了'});
    //   await F2eHelper.sleep(3000);
    //   this.messageSer.confirm({ text: '你確定嗎'});
    //   await F2eHelper.sleep(3000);
    //   this.messageSer.prompt({ title: '眾生云云', onSubmit: () => {}, onCancel: () => {}});
    //   await F2eHelper.sleep(3000);
    //   this.messageSer.notify('這是 notify');
    // }, 3000);
  }

  private async initLogService() {
    this.myLogSer.infoProvider = {
      account: () => {
        // return this.sessionServ.account!;
        return "TO-DO";
      },
      device_id: () => {
        return new Promise(async (resolve) => {
          resolve(await this.deviceSer.getDeviceId() as string);
        });
      }
    };
  }

  // private async importDynamicConfigures() {
  //   try {
  //     const loadConfigTimeout = this.configSer.envConfiguration.timeout.speedTest;
  //     await this.networkServ.watchDynamicConfigs(loadConfigTimeout);
  //   } catch (e) {
  //     this.messageSer.alert({
  //       title: '网路异常',
  //       text: '无法正确初始化站台页面，请在确认您的线路通畅后重试，或洽客服人员'
  //     });
  //     throw e;
  //   }
  // }

  private async initNativeJavascriptBridge() {
    const tout = this.configSer.envConfiguration.timeout.jsBridgeReady;
    this.deviceSer.init(tout);
    // 主動連結, 監測狀態並複寫
    if (await this.deviceSer.bridgeReady(tout)) {
      const platformName = this.configSer.getPlatformName();
      if (['pjs'].includes(platformName)) {
        F2eHelper.openNewWindow = (url) => {
          this.deviceSer.openBrowser(url as string);
        };
        F2eHelper.openNewWindowWitOptions = (url) => {
          this.deviceSer.openBrowser(url);
        };
      }

      // TODO: 先寫死
      let hosts = platformConfig.platform[envConfig.envType].api.hosts;
      hosts = Array.from(new Set([...hosts]));
      this.deviceSer.syncHosts(hosts);
    }
  }

  private parseQueryString(query: string) {
    const vars = query.split('&');
    const params: { [key: string]: any } = {};
    if (vars && vars.length > 0) {
      vars.forEach((element: string) => {
        const pair = element.split('=');
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof params[key] === 'undefined') {
          params[key] = decodeURIComponent(value);
          // If second entry with this name
        } else if (typeof params[key] === 'string') {
          const arr = [params[key], decodeURIComponent(value)];
          params[key] = arr;
          // If third or later entry with this name
        } else {
          params[key].push(decodeURIComponent(value));
        }
      });
    }
    return params;
  }

  // private async initNetworkService() {
  //   if (!F2eHelper.isPlatformBrowser) {
  //     return;
  //   }

  //   if (this.configSer.envConfiguration.envType === EnvType.local) {
  //     console.warn(`Playing dev/local mode, apply cross-site-mode automatically.`);
  //     F2eHelper.isCrossSiteMode = true;
  //   }

  //   let candidates = [this.hosts[0]];
  //   if (F2eHelper.isCrossSiteMode || F2eHelper.isInApp) {
  //     candidates = [...this.hosts];
  //   }
  //   if (candidates.length <= 0) {
  //     throw new Error('Errr, host list is empty');
  //   }

  //   this.networkServ.onGraphQLError$().subscribe((error: { message: any, errorCode: number }) => {
  //     switch (error.errorCode) {
  //       case 775:   // [775, "註冊試玩帳號, 帳號重複"]
  //       case 10000: // [10000, "地區存取限制"]
  //       case 10004: // [10004, "用户尚未登入"]
  //       case 10012: // [10012, "黑名單"]
  //       case 10013: // [10013, "账号已在其他装置登入"]
  //       case 10162: // [10162, "登入時間逾時，請重新登入"]
  //       case 10189: // [10189, "系統維護中"]
  //       case 10295: // [10295, "姓名驗證"]
  //       case 10297: // [10297, "google code 驗證"]
  //       case 10434: // [10434, "该连结已经失效，请重新寄送"]
  //         // skip normal graphql error event
  //         break;
  //       default:
  //         // Report Error Log
  //         this.myLogSer.log(MyLoggerLevelEnum.error, error);
  //     }
  //   });

  //   if (F2eHelper.isInApp) {
  //     await this.networkServ.init(PlatformType.Mobile, candidates);
  //   } else {
  //     await this.networkServ.initWithoutSpeedTest(PlatformType.Mobile, candidates[0]);
  //   }
  //   this.networkServ.syncHosts();
  // }


  private hideNativeSplash() {
    if (F2eHelper.isInApp) {
      setTimeout(() => {
        console.log('Splash脫掉 脫掉 Splash脫掉');
        this.deviceSer.closeNativeSplash();
      }, 100);
    }
  }

  private async networkTest() {
    let hosts = platformConfig.platform[envConfig.envType].api.hosts;
    hosts = Array.from(new Set([...hosts]));
    if (!F2eHelper.isInApp) {
      hosts = Array.from(new Set([location.origin, ...hosts]));
    }

    // 只有一條域名免測速直接進入
    if (hosts.length === 1) {
      setGraphqlHost(hosts[0]);
    } else {
      await this.speedTest(hosts);
    }
  }

  private async speedTest(hosts: string[]) {
    let errCount = 0;
    console.log(hosts)
    await new Promise<string>((resolve, reject) => {
      hosts.map(host => (
        this.http.get(`${host}/graphql`).subscribe({
          next: () => {
            resolve(host)
          },
          error: () => {
            errCount ++;
            if (errCount === hosts.length) reject();
          }
        })
      ));
    }).then(host => {
      setGraphqlHost(host);
    })
  }
}

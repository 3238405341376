import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'kr-nav-template',
  templateUrl: './nav-template.component.html',
  styleUrls: ['./nav-template.component.scss']
})
export class NavTemplateComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
    // if(window.history.length > 1 && 
    // document.referrer.indexOf(window.location.host) !== -1) {
    //   this.location.back();
    // }
    // else {
    //   console.log('沒有上一頁');
    // }
  }

}

import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[ggDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Output() ggDebounceClick = new EventEmitter();
  private subscription: Subscription;
  private clicks = new Subject<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.subscription = this.clicks
    .pipe(debounceTime(300))
    .subscribe(e => {
      return this.ggDebounceClick.emit(e);
    });
  }

  @HostListener('click', ['$event'])

  clickEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

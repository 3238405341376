import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import Decimal from 'decimal.js';
import { MessageService, ModalOverlayRef } from 'lib';
import { InvestorFinance_CancelRechargeOrderArgs, Mutation, RechargeQuotaByUsdtResult } from 'lib/src/types/schema';
import { RechargeInvestorQuotaResult } from 'projects/lib/src/types/schema';
import { CANCEL_RECHARGE_ORDER_MUTATION } from '../../../helpers/api';

@Component({
  selector: 'kr-recharge-info',
  templateUrl: './recharge-info.component.html',
  styleUrls: ['./recharge-info.component.scss']
})
export class RechargeInfoComponent implements OnInit {
  @Input() info: RechargeQuotaByUsdtResult;
  @Input() amount: string;
  loading = false
  constructor(
    private clipboard: Clipboard,
    private messageSer: MessageService,
    private overlayRef: ModalOverlayRef,
    private apollo: Apollo,
  ) { }

  ngOnInit(): void {
    console.log(this.info);

  }

  copy(text: string): void {
    this.clipboard.copy(text);
    this.messageSer.notify('已複製', 600);
  }

  get usdtAmount() {
    return '-';
  }

  close() {
    this.overlayRef.close();
  }

  cancel() {
    if (this.loading) return;
    this.loading = true;
    let variables: InvestorFinance_CancelRechargeOrderArgs = {
      input: { orderUuid: this.info.orderUuid }
    };
    this.apollo.mutate<Mutation>({
      mutation: CANCEL_RECHARGE_ORDER_MUTATION,
      variables
    }).subscribe((res) => {
      this.loading = false;
      if (res.data?.investor?.finance?.cancelRechargeOrder?.success) {
        this.messageSer.notify('取消成功', 600);
        this.close()
      }

    },
      () => {
        this.loading = false;
      })
  }
}

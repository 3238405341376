import { Component, Input, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ModalOverlayRef } from 'lib';
import { InvestorTransactionStatistic, Query, TeamMemberProfitReportInput } from 'lib/src/types/schema';
import { TEAM_PROFIT_REPORT_QUERY } from 'projects/pc2c-app/src/app/shared/helpers/api';

@Component({
  selector: 'kr-member-profit-modal',
  templateUrl: './member-profit-modal.component.html',
  styleUrls: ['./member-profit-modal.component.scss']
})
export class MemberProfitModalComponent implements OnInit {
  @Input() account: string;
  @Input() startTime: string;
  @Input() endTime: string;
  profitReport: InvestorTransactionStatistic;

  constructor(
    private apollo: Apollo,
    private overlayRef: ModalOverlayRef
  ) { }

  ngOnInit(): void {
    this.query();
  }

  query() {
    const input: TeamMemberProfitReportInput = {
      account: this.account,
      startTime: this.startTime,
      endTime: this.endTime
    };
    this.apollo.query<Query>({
      query: TEAM_PROFIT_REPORT_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache'
    }).subscribe(resp => {
      this.profitReport = resp.data.me?.team?.teamMemberProfitReport!;
    });
  }

  close() {
    this.overlayRef.close();
  }

}

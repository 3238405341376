import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class BaseObservableComponent implements OnDestroy {

  sub = new Subscription();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { DeviceService, MessageService, ModalOverlayRef } from 'lib';
import { BaseObservableComponent } from 'lib/src/component';
import { ClientPurchaseOrderStatusEnum, InvestorPurchaseOrderInfo, Maybe, PurchaseOrder, PurchasePaymentTypeEnum, Query } from 'lib/src/types/schema';
import { interval } from 'rxjs';
import { generateGraphqlUri } from '../../../graphql.module';
import { CONFIRM_PURCHASE_ORDER_MUTATION } from '../../helpers/api';
import { InvestorConfigService } from '../../services/investor-config.service';
import { LanguageService } from '../../services/language.service';
import { MissionService } from '../../services/mission.service';
import { PaymentService } from '../../services/payment.service';

interface UploadProgress {
  progress: number;
  message: string;
}
@Component({
  selector: 'kr-purchase-order-info-modal',
  templateUrl: './purchase-order-info-modal.component.html',
  styleUrls: ['./purchase-order-info-modal.component.scss']
})
export class PurchaseOrderInfoModalComponent extends BaseObservableComponent implements OnInit {
  @Input() purchaseOrderUuid: string;
  @Input() purchasePaymentType: PurchasePaymentTypeEnum;
  @Input() currentOrder: PurchaseOrder;
  @Output() afterOpen = new EventEmitter<boolean>();

  isVisible: boolean = false;
  purchaseOrderInfo: Maybe<InvestorPurchaseOrderInfo>;
  purchaseOrderStatus = ClientPurchaseOrderStatusEnum;
  loading = true;
  orderQueryRef: QueryRef<Query>;
  isUploading = false;
  isNativeSupportUpload = false;
  uploadPhase = '';
  uploadProgress = '';
  progressByIdx: Record<number, UploadProgress> = {};
  bankAccountId = '';
  purchasePaymentTypeEnum = PurchasePaymentTypeEnum;
  isShowQRCode: boolean = false;
  qrcodeUrl: string = '';

  get paymentAccounts() {
    return this.paymentSer.paymentAccounts;
  }

  get alipayPaymentAccounts() {
    return this.paymentSer.alipayPaymentAccounts;
  }

  constructor(
    private overlayRef: ModalOverlayRef,
    private missionSer: MissionService,
    private messageSer: MessageService,
    private clipboard: Clipboard,
    private deviceSer: DeviceService,
    private investorConfigSer: InvestorConfigService,
    private http: HttpClient,
    private apollo: Apollo,
    public lang: LanguageService,
    public paymentSer: PaymentService,
  ) {
    super();
  }

  ngOnInit() {
    // 先停用原生壓縮功能
    // this.deviceSer.isUploadMediaSupported().then(bool => this.isNativeSupportUpload = bool);
    this.orderQueryRef = this.missionSer.purchaseOrderInfoQuery({ orderUuid: this.purchaseOrderUuid });
    this.orderQueryRef.valueChanges.subscribe({
      next: async (resp) => {
        this.loading = false;
        let order = resp.data.me?.orderInfo?.purchaseOrderInfo;
        if (order) {
          this.purchaseOrderInfo = order!;
          this.isVisible = true;
          this.afterOpen.emit(true);
        }
        this.uploadPhase = '';
        this.uploadProgress = '';
        if (order?.status === this.purchaseOrderStatus.InvestorToBeConfirmed) {
          await this.apollo.mutate({
            mutation: CONFIRM_PURCHASE_ORDER_MUTATION,
            variables: {
              input: {
                orderUuid: order!._id
              }
            }
          }).toPromise().catch(err => {
            // 失敗顯示後端錯誤訊息後，關閉彈窗同時刷新訂單列表
            this.missionSer.purchaseOrdersQuery.refetch();
            this.close();
          });
          this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
        }
      },
      error: err => {
        // 失敗顯示後端錯誤訊息後，關閉彈窗同時刷新訂單列表
        this.missionSer.purchaseOrdersQuery.refetch();
        this.close();
      }
    }).toUnsubscription(this);
  }

  takeLastFourDigits(account: Maybe<string>) {
    return account?.substring(account.length - 4);
  }

  async chooseNativeFile(idx: number) {
    this.deviceSer.uploadPurchaseOrderFile(this.purchaseOrderInfo?._id!, this.acceptFiles);
    this.startTickr(idx);
  }

  chooseFile2(e: any) {
    if (e && e?.target && e?.target?.files) {
      this.isUploading = true;
      this.missionSer.uploadPurchaseOrderFile({
        orderUuid: this.purchaseOrderInfo?._id!,
        file: e.target.files[0]
      }).subscribe(resp => {
        this.isUploading = false;
        this.messageSer.notify({ text: '上傳成功', type: 'success' });
        this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
      }, (err) => {
        this.isUploading = false;
        console.log(err);
      }).toUnsubscription(this);
    }
  }

  chooseFileHttpClient(e: any, idx = 1) {
    if (e && e?.target && e?.target?.files[0]) {
      const file: File = e?.target?.files[0];
      const token = localStorage.getItem('token');
      const formData = new FormData();
      const variables = {
        input:{
          orderUuid: this.purchaseOrderInfo?._id,
          file:null
        }
      }
      const operations = JSON.stringify({
        query: `mutation UploadPurchaseOrderFile($input: UploadPurchaseOrderFileInput!) {
          investor {
            uploadPurchaseOrderFile(input: $input) {
                success
            }
          }
        }`,
        variables,
        "operationName":"UploadPurchaseOrderFile"
      })
      formData.append('operations', operations);
      formData.append('map', `{"1":["variables.input.file"]}`);
      formData.append('1', file, file.name);
      this.progressByIdx[idx] = { progress: 0, message: '上传中'};
      this.http.post<any>(generateGraphqlUri(null) + '&upload=1', formData, {
        headers: {authorization: token ? `Bearer ${token}` : ''},
        reportProgress: true,
        observe: 'events'
      }).subscribe(resp => {
        if (resp.type === HttpEventType.Response) {
          delete this.progressByIdx[idx];
          if (resp?.body?.errors) {
            this.messageSer.notify({ text: resp.body.errors[0].message, type: 'error' });
            this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
            return;
          }
          // this.messageSer.notify({ text: '上传成功', type: 'success' });
          this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
        }
        if (resp.type === HttpEventType.UploadProgress) {
          const percentDone = resp.total ? Math.round(100 * resp.loaded / resp.total) : 0;
          this.progressByIdx[idx] = { progress:percentDone, message: '上传中'};
        }
      }, err => {
        delete this.progressByIdx[idx];
        this.messageSer.notify({ text: '上传失败', type: 'error' });
        this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
      })
    }
  }

  async submit() {
    if (!this.bankAccountId) {
      this.messageSer.notify({ text: '请选择付款账号', type: 'info' });
      return;
    }
    if (this.purchaseOrderInfo?.uploadFileCount! < 2) {
      this.messageSer.notify({ text: '请上传档案', type: 'info' });
      return;
    }
    const smsStr = await this.deviceSer.getSMS(Math.floor(Date.parse(this.purchaseOrderInfo?.createdAt) / 1000));
    this.missionSer.applyPurchaseOrderApproval({
      orderUuid: this.purchaseOrderInfo?._id!,
      mobileMessage: smsStr,
      bankAccountId: this.bankAccountId
    }).subscribe(resp => {
      this.orderQueryRef.refetch({ orderUuid: this.purchaseOrderUuid });
      if (resp.data?.investor?.applyPurchaseOrderApproval?.success) {
        this.messageSer.notify({ text: '提交审核', type: 'success' });
        this.missionSer.purchaseOrdersQuery.refetch();
        this.close();
      }
    })
  }

  startTickr(idx: number) {
    const temp = interval(250).subscribe(async () => {
      const resp = await this.deviceSer.getUploadProgress();
      if (resp) {
        switch (resp.phase) {
          case "idle":
            this.isUploading = false;
            delete this.progressByIdx[idx];
            temp.unsubscribe();
            break;
          case "picking":
            this.progressByIdx[idx] = {
              message: '',
              progress: 0
            };
            break;
          case "compressing":
            this.isUploading = true;
            this.progressByIdx[idx] = {
              message: '压缩中',
              progress: Math.floor(resp.progress * 100)
            };
            break;
          case "compressed":
            this.progressByIdx[idx] = {
              message: '压缩完成',
              progress: Math.floor(resp.progress * 100)
            };
            break;
          case "uploading":
            this.isUploading = true;
            this.progressByIdx[idx] = {
              message: '上传中',
              progress: Math.floor(resp.progress * 100)
            };
            break;
          case "finished":
          case "failed":
            if (resp.reason) {
              this.messageSer.notify({ type: 'error', text: resp.reason });
            }
            this.isUploading = false;
            delete this.progressByIdx[idx];
            this.orderQueryRef.refetch({orderUuid: this.purchaseOrderUuid});
            temp.unsubscribe();
            break;
        }
      }
    });
  }

  resetFile(e: any) {
    if (e) {
      e.target.value = '';
    }
  }

  copy(text: string) {
    this.clipboard.copy(text);
    this.messageSer.notify('已複製', 600);
  }

  get acceptFiles(): string {
    if(this.investorConfigSer.levelGroup.value?.purchaseOrderUploadImageEnabled === true) {
      return 'video/*,image/png,image/jpeg,image/gif';
    }
    return 'video/*';
  }

  close(): void {
    this.overlayRef.close();
  }

  closeWithWarning(): void {
    if(this.purchaseOrderInfo?.status === this.purchaseOrderStatus.InvestorProcessing) {
      this.messageSer.confirm({
        title: '温馨提示',
        text: '订单尚未提交审核，确认是否离开页面？',
        onYes: this.close.bind(this)
      })
    } else {
      this.overlayRef.close();
    }
  }

  showQRCode(qrcodeUrl: string) {
    this.isShowQRCode = !this.isShowQRCode;
    this.qrcodeUrl = qrcodeUrl;
  }

  closeShowQRCode() {
    this.isShowQRCode = !this.isShowQRCode;
  }
}

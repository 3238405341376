import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { F2eHelper } from 'lib';
import { BaseObservableComponent } from 'lib/src/component';
import { DeviceType } from 'lib/src/types';
import { OnsCarouselElement } from 'onsenui';
import { Subject } from 'rxjs';
@Directive({
  selector: '[ggSwipeForbidScroll]'
})
export class SwipeForbidScrollDirective implements AfterViewInit {
  scrollContainer = [];
  retryCount = 0;
  isIOS = false;
  isInIt = false;
  isSwipe = false;
  onSwipeSub$ = new Subject<any>();

  constructor(
    private el: ElementRef<OnsCarouselElement>
  ) {
    
  }

  ngAfterViewInit() {
    // this.isIOS = F2eHelper.getMobileOperatingSystem() === DeviceType.IOS ? true : false; // 目前是在ios上才有問題 不是ios者不予理會
    // if (!this.isIOS) {
    //   return;
    // }
    // this.onSwipeSub$
    //   .subscribe((e) => {
    //     if (e) {
    //       if (this.isSwipe) {
    //         return;
    //       }
    //       this.isSwipe = true;
    //       this.getScrollChilds();
    //       this.setAttributeForTarget();
    //     }
    //   }).toUnsubscription(this);
    // const self = this;
    // this.el.nativeElement.onSwipe = (index: number) => {
    //   const idx = Number(index);
    //   if (Number.isInteger(idx)) {
    //     if (!this.isInIt) {
    //       if (idx === 0) {
    //         this.isInIt = true;
    //         // console.log('carousel init done');
    //       }
    //     } else {
    //       // console.log('swipe done！');
    //       self.removeAttributeFromTarget();
    //       this.isSwipe = false;
    //     }
    //   } else {
    //     self.onSwipeSub$.next(true);
    //   }
    // };
  }

  // getScrollChilds() { // 取得設有overflow的dom
  //   if (!this.scrollContainer.length) {
  //     if (this.retryCount < 10) {
  //       const self = this;
  //       F2eHelper.execJQuery(this.el, (jj) => {
  //         const target = jj.find('[gg-scroll-container]');
  //         if (target.length) {
  //           for (const child of target) {
  //             self.scrollContainer.push(child);
  //           }
  //         }
  //       });
  //       this.retryCount++;
  //     } else {
  //       console.log('something wrong QAQ ! pls set attribute "gg-scroll-container" on target dom ~');
  //     }
  //   }
  // }

  // setAttributeForTarget() {
  //   if (!this.scrollContainer.length) {
  //     return;
  //   }
  //   this.scrollContainer.forEach((c) => {
  //     c.setAttribute('style', 'overflow:hidden !important;');
  //   });
  // }

  // removeAttributeFromTarget() {
  //   if (!this.scrollContainer.length) {
  //     return;
  //   }
  //   this.scrollContainer.forEach((c) => {
  //     c.removeAttribute('style', 'overflow:hidden !important;');
  //   });
  // }
}


import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
// import { ReportService } from 'lib';
// import { OptionWithLang } from 'lib/src/types';
// import { Query, BalanceChangeTypeEnum, BetPercentTypeEnum, FilterConditionEnum, FinanceStatusEnum, FinanceTransferTypeEnum, FinanceTypeEnum, IdentityRangeEnum, LotteryGameOption, OrderStatusEnum, OrderTypeEnum, Product, ProductEnum, ProductListQueryArgs, SeekOrderStatusEnum, SelectOption, VrChannelTypeEnum } from 'lib/src/types/schema';
import flatpickr from 'flatpickr';
import { Mandarin } from 'flatpickr/dist/l10n/zh';
import { Instance } from 'flatpickr/dist/types/instance';
import { Options } from 'flatpickr/dist/types/options';
import { ModalOverlayRef } from 'lib';
import { PurchasePaymentTypeEnum } from 'lib/src/types/schema';
import { isSameDate, TimePeriod, TimeService } from '../../services/time.service';

// Document: https://flatpickr.js.org/

export enum QueryField {
  DateTime,
  Account
}

const defaultFlatConfig: Options = {
  altInput: true,
  altFormat: 'Y-m-d H:i:S',
  dateFormat: 'Y-m-d H:i:S',
  // enableTime: true,
  // time_24hr: true,
  locale: Mandarin
};

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

@Component({
  selector: 'kr-query-options',
  templateUrl: './query-options.component.html',
  styleUrls: ['./query-options.component.scss']
})
export class QueryOptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('elemStartTime', { static: false }) elemStartTime: ElementRef<HTMLInputElement>;
  @ViewChild('elemEndTime', { static: false }) elemEndTime: ElementRef<HTMLInputElement>;
  pickrStartTime: Instance;
  pickrEndTime: Instance;

  showPurchasePaymentType: false;
  purchasePaymentType?: PurchasePaymentTypeEnum | null;
  purchasePaymentTypeList = enumKeys(PurchasePaymentTypeEnum);

  private _startTime: string;
  private _endTime: string;
  get startTime(): string {
    return this._startTime;
  }
  set startTime(val: string) {
    this._startTime = this.timeSer.format(val);
  }
  get endTime() {
    return this._endTime;
  }
  set endTime(val) {
    this._endTime = this.timeSer.format(val);
  }

  constructor(
    public timeSer: TimeService,
    private cdr: ChangeDetectorRef,
    @Optional() public overlayRef: ModalOverlayRef
  ) { }

  ngOnInit() {
    this.setTimePeriod(TimePeriod.Today);
  }

  ngAfterViewInit() {
    this.pickrStartTime = flatpickr(this.elemStartTime.nativeElement, { ...defaultFlatConfig, defaultDate: this.startTime });
    this.pickrEndTime = flatpickr(this.elemEndTime.nativeElement, { ...defaultFlatConfig, defaultDate: this.endTime });
  }

  ngOnDestroy() {
    this.pickrStartTime.destroy();
    this.pickrEndTime.destroy();
  }

  setTimePeriod(period: TimePeriod) {
    const { startTime, endTime } = this.timeSer.getTimePeriod(period);
    this.startTime = startTime;
    this.endTime = endTime;

    this.pickrStartTime?.setDate(this.startTime);
    this.pickrEndTime?.setDate(this.endTime);
  }

  isSameDate(period: TimePeriod) {
    let { startTime, endTime } = this.timeSer.getTimePeriod(period);
    return isSameDate(startTime, this.startTime) && isSameDate(endTime, this.endTime);
  }


  // private async initOrderStatusType() {
  //   this.orderStatusType = await this.reportSer.getOptionOrderStatusType();
  //   this.selOrderStatus = this.orderStatusType[0].option;
  // }


  confirm(component = this) {
    // please implement and assign method at afterCreateFunc()
  }
  cancel() {
    // please implement and assign method at afterCreateFunc()
  }
  close() {
    this.overlayRef?.close();
  }
}

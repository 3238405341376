import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { MessageService, ModalService } from 'lib';
import { ClientPurchaseOrderStatusEnum, Mission, PurchaseOrder, PurchasePaymentTypeEnum, Query, ReceivePurchaseOrderInput } from 'lib/src/types/schema';
import { LanguageService } from '../../../services/language.service';
import { MissionService } from '../../../services/mission.service';
import { WalletService } from '../../../services/wallet.service';
import { PurchaseOrderInfoModalComponent } from '../../purchase-order-info-modal/purchase-order-info-modal.component';
import { TabListEnum } from '../payment.component';

export interface PurchaseBadge {
  isShowBadge: boolean;
  countDisplay: number | string;
}

@Component({
  selector: 'kr-purchase-order-info',
  templateUrl: './purchase-order-info.component.html',
  styleUrls: ['./purchase-order-info.component.scss']
})
export class PurchaseOrderInfoComponent implements OnInit {
  @Input() tabId: number;
  @Output() purchaseCount: EventEmitter<PurchaseBadge> = new EventEmitter();
  @Output() myPurchaseCount: EventEmitter<PurchaseBadge> = new EventEmitter();

  tabList = TabListEnum;
  tableData: PurchaseOrder[] = [];
  purchaseOrders: PurchaseOrder[] = [];
  myPurchaseOrders: PurchaseOrder[] = [];
  purchaseOrderStatus = ClientPurchaseOrderStatusEnum;
  filterMission: Mission;
  orderQueryRef: QueryRef<Query>;
  loading: boolean = false;

  constructor(
    public walletSer: WalletService,
    public missionSer: MissionService,
    public lang: LanguageService,
    private messageSer: MessageService,
    private modalSer: ModalService,
  ) { }

  ngOnInit(): void {
    this.getPurchaseOrders();
  }

  isPurchaseStatus(id: number) {
    return id === this.tabList.PurchaseOrder;
  }

  purchaseStatusLang(item: ClientPurchaseOrderStatusEnum) {
    return this.lang.langs.purchaseOrderStatus[item];
  }

  getPurchaseOrders() {
    this.missionSer.filterPurchaseOrders.subscribe(orders => {
      // ---------- 代付水池 ----------
      this.purchaseOrders = orders.filter((d) => {
        return (d.status === this.purchaseOrderStatus.WaitForInvestor);
      });
      // 代付水池 Badge Data
      const purchaseOrdersBadge = {
        isShowBadge: this.missionSer.isShowBadge(this.purchaseOrders.length!),
        countDisplay: this.missionSer.countDisplay(this.purchaseOrders.length!),
      };
      this.purchaseCount.emit(purchaseOrdersBadge);
      // ---------- 我的代付 ----------
      // 我的代付过滤要显示的状态列表
      const myPurchaseStatus = [
        this.purchaseOrderStatus.Processing,
        this.purchaseOrderStatus.InvestorProcessing,
        this.purchaseOrderStatus.InvestorToBeConfirmed,
        this.purchaseOrderStatus.WaitForReview,
        this.purchaseOrderStatus.Locked
      ];
      this.myPurchaseOrders = orders.filter((d) => {
        return (myPurchaseStatus.includes(d.status!));
      });
      // 我的代付 Badge Data
      const myPurchaseOrdersBadge = {
        isShowBadge: this.missionSer.isShowBadge(this.myPurchaseOrders.length!),
        countDisplay: this.missionSer.countDisplay(this.myPurchaseOrders.length!),
      };
      this.myPurchaseCount.emit(myPurchaseOrdersBadge);
    });
  }

  async receivePurchaseOrder(data: PurchaseOrder) {
    const defaultGatewaySellRate = this.walletSer.defaultGatewaySellRate.value;
    let input: ReceivePurchaseOrderInput = {
      orderUuid: data._id,
      exchangeRate: defaultGatewaySellRate,
    };
    let isRateDifferent: boolean = false;
    let currentExchangeRate: number = 0;
    await this.sendReceivePurchaseOrder(input).then(resp => {
      isRateDifferent = resp.data.investor.receivePurchaseOrder.isRateDifferent;
      currentExchangeRate = resp.data.investor.receivePurchaseOrder.currentExchangeRate;
    })
    if (isRateDifferent) {
      this.messageSer.confirm({
        title: '温馨提示',
        text: `原汇率 ${defaultGatewaySellRate} 变更为 ${currentExchangeRate!}，确认是否继续抢单？`,
        onYes: async () => {
          input = {
            orderUuid: data._id,
            forceReceive: true,
          };
          await this.sendReceivePurchaseOrder(input);
          this.missionSer.purchaseOrdersQuery.refetch();
          this.openModal(data);
        },
        onNo: async () => { }
      });
    } else {
      this.missionSer.purchaseOrdersQuery.refetch();
      this.openModal(data);
    }
  }

  sendReceivePurchaseOrder(input: ReceivePurchaseOrderInput) {
    return this.missionSer.receivePurchaseOrderMutation(input).toPromise().catch(err => {return err;});
  }

  market(data: PurchaseOrder): string {
    switch (data.paymentType) {
      case PurchasePaymentTypeEnum.Yoho:
        return '银行卡';
      case PurchasePaymentTypeEnum.AlipayH5Purchase:
        return '支付宝';
      default:
        return '';
    }
  }

  openModal(data: PurchaseOrder) {
    this.loading = true;
    this.modalSer.open(PurchaseOrderInfoModalComponent, {
      hasBackdrop: false,
    }, component => {
      component.purchaseOrderUuid = data._id;
      component.purchasePaymentType = data.paymentType;
      component.afterOpen.subscribe((resp) => {
        if (resp) this.loading = false;
      })
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kr-shipping-order-report-modal',
  templateUrl: './shipping-order-report-modal.component.html',
  styleUrls: ['./shipping-order-report-modal.component.scss']
})
export class ShippingOrderReportModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

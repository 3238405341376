import { gql } from "apollo-angular";

export const WALLETS_QUERY = gql`
query wallets($input: InvestorWalletsInput!, $infoInput: CurrencyTypeInput!) {
  me {
    wallets(input: $input) {
      currencyType
      quota
      bonus
      purchasingAmount
      shippingAmount
      purchaseOrderAmountMax
      purchaseOrderAmountMin
      achievementDeficit
      frozenQuota
      frozenBonus
    }
    financeInfo {
      levelGroupQuotas(input: $infoInput) {
        currencyType
        purchaseDailyQuota
        purchaseOrderAmountMin
        purchaseOrderAmountMax
        retainedQuota
        shippingDailyQuota
        shippingOrderAmountMax
        shippingOrderAmountMin
      }
    }
    exchange {
      defaultGatewayRate
      defaultGatewaySellRate
      defaultGatewayDepositRate
    }
  }
}
`;

export const MISSIONS_QUERY = gql`
query{
  missions{
    name
    shippingPaymentType
    purchasePaymentType
    order
    orderType
    missionLogoUrl
  }
}`;

export const SHIPPING_ORDERS_QUERY = gql`
query {
  me{
    shippingOrders(input: { paymentTypes: [] }){
      _id
      amount
      paymentType
      commodity {
        id
        name
        price
        image
      }
      shippingFee
      investorId
      status
      createdAt
      exchangeRate
      orderAmountInUsdt
      payerName
    }
  }
}
`;

export const PURCHASE_ORDERS_QUERY = gql`
query {
  me{
    purchaseOrders(input: { paymentTypes: [] }){
      _id
      amount
      paymentType
      commodity {
        id
        name
        price
        image
      }
      shippingFee
      investorId
      status
      createdAt
      investorBonus
      exchangeRate
      orderAmountInUsdt
      payerBankId
      payerBankAccountName
      payerBankAccount
      payerBankBranchName
    }
  }
}
`;

export const RECEIVE_BUY_ORDER_MUTATION = gql`
mutation receivePurchaseOrder($input: ReceivePurchaseOrderInput!) {
  investor {
    receivePurchaseOrder(input: $input) {
      success
      isRateDifferent
      currentExchangeRate
    }
  }
}
`;

export const PURCHASE_ORDER_INFO_QUERY = gql`
query purchaseOrderInfo($input: InvestorPurchaseOrderInfoInput!) {
  me {
    orderInfo {
      purchaseOrderInfo(input: $input) {
        _id
        amount
        createdAt
        expiredAt
        status
        bankName
        bankAccount
        bankAccountName
        commodity {
          id
          image
          name
          price
        }
        shippingFee
        uploadFileCount
        investorBonus
        alipayAccount
        alipayQrcodeImage
        alipayName
      }
    }
  }
}
`;

export const UPLOAD_PURCHASE_ORDER_FILES_MUTATION = gql`
mutation UploadPurchaseOrderReceipt($input: UploadPurchaseOrderReceiptInput!) {
  investor {
    uploadPurchaseOrderReceipt(input: $input) {
      success
    }
  }
}
`;

export const UPLOAD_PURCHASE_ORDER_FILE_MUTATION = gql`
mutation UploadPurchaseOrderFile($input: UploadPurchaseOrderFileInput!) {
  investor {
    uploadPurchaseOrderFile(input: $input) {
        success
    }
  }
}
`;

export const APPLY_PURCHASE_ORDER_APPROVAL_MUTATION = gql`
mutation ApplyPurchaseOrderApproval($input: ApplyPurchaseOrderApprovalInput!) {
    investor {
        applyPurchaseOrderApproval(input: $input) {
            success
        }
    }
}

`;

export const CONFIRM_SHIPPING_ORDER_MUTATION = gql`
mutation ConfirmShippingOrder($input: ConfirmShippingOrderInput!) {
  investor {
    confirmShippingOrder(input: $input) {
      success
    }
  }
}
`;

export const CONFIRM_PURCHASE_ORDER_MUTATION = gql`
mutation ConfirmPurchaseOrder($input: ConfirmPurchaseOrderInput!) {
  investor {
    confirmPurchaseOrder(input: $input) {
      success
    }
  }
}
`;

export const SETTING_QUERY = gql`
query Setting {
  me {
    id
    shippingEnabled
    purchaseEnabled
    activeShippingPaymentTypes
    activePurchasePaymentTypes
  }
}
`;

export const SET_ORDER_ENABLED_MUTATION = gql`
mutation SetOrderEnabled($input: SetOrderEnabledInput!) {
  investor {
    setOrderEnabled(input: $input) {
      success
    }
  }
}
`

export const ADD_INVESTOR_PAYMENT_ACCOUNT_MUTATION = gql`
mutation AddInvestorPaymentAccount($input: AddInvestorPaymentAccountInput!) {
  investor {
    finance {
      addInvestorPaymentAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const ADD_INVESTOR_ALIPAY_PAYMENT_ACCOUNT_MUTATION = gql`
mutation AddInvestorAlipayPaymentAccount($input: AddInvestorAlipayPaymentAccountInput!) {
  investor {
    finance {
      addAlipayPaymentAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const ADD_INVESTOR_ALIPAY_SCAN_ACCOUNT_MUTATION = gql`
mutation AddAlipayScanAccount($input: AddInvestorAlipayScanAccountInput!) {
  investor {
    finance {
      addAlipayScanAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const ADD_INVESTOR_WITHDRAW_ACCOUNT_MUTATION = gql`
mutation AddInvestorWithdrawAccount($input: AddInvestorWithdrawBankAccountInput!) {
  investor {
    finance {
      addInvestorWithdrawBankAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const PURCHASE_ORDER_HISTORY = gql`
query PurchaseOrderHistory($input: InvestorPurchaseOrderHistoryInput, $first: Int, $page: Int ) {
  me {
    histories {
      purchaseOrderHistory(input: $input, first: $first, page: $page) {
        data {
          _id
          amount
          paymentType
          investorId
          status
          commodity {
            name
            price
            image
          }
          shippingFee
          investorBonus
          createdAt
          exchangeRate
          orderAmountInUsdt
          payerBankId
          payerBankAccountName
          payerBankAccount
          payerBankBranchName
          payeeBankId
          payeeBankAccountName
          payeeBankAccount
          payerAlipayAccount
          payerAlipayName
          payeeAlipayAccount
          payeeAlipayQrcodeImage
          payeeAlipayName
        }
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
      }
    }
  }
}

`;

export const SHIPPING_ORDER_HISTORY = gql`
query ShippingOrderHistory($input: InvestorShippingOrderHistoryInput, $first: Int, $page: Int ) {
  me {
    histories {
      shippingOrderHistory(input: $input, first: $first, page: $page) {
        data {
          _id
          amount
          paymentType
          investorId
          status
          commodity {
            id
            name
            price
            image
          }
          investorBonus
          shippingFee
          createdAt
          payeeBankId
          payeeBankAccount
          payeeBankAccountName
          payerName
          payeeBankBranchName
          payeeAlipayAccount
          payeeAlipayName
          exchangeRate
          orderAmountInUsdt
        }
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
      }
    }
  }
}
`;

export const QUOTA_HISTORY_QUERY = gql`
query QuotaHistory( $input: InvestorQuotaHistoryInput, $first: Int, $page: Int ) {
  me {
    histories {
      quotaHistory(input: $input, first: $first, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
        data {
          changeType
          currencyType
          orderUuid
          changeBefore
          changeAmount
          changeAfter
          createdAt
          
        }
      }
    }
  }
}
`;

export const BONUS_HISTORY_QUERY = gql`
query BonusHistory( $input: InvestorBonusHistoryInput, $first: Int, $page: Int ) {
  me {
    histories {
      bonusHistory(input: $input, first: $first, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
        data {
          changeType
          orderUuid
          changeBefore
          changeAmount
          changeAfter
          createdAt
        }
      }
    }
  }
}
`;

export const PAYMENT_ACCOUNTS_QUERY = gql`
query PaymentAccount {
	me {
    paymentAccounts {
      id
      paymentType
      bankName
      bankBranchName
      bankAccountName
      bankAccount
      status
      todayShippingTotalAmount
    }
  }
}
`;

export const ALIPAY_PAYMENT_ACCOUNTS_QUERY = gql`
query alipayPaymentAccount {
  me {
    alipayPaymentAccounts {
      id
      paymentType
      alipayAccount
      alipayName
      todayShippingTotalAmount
      status
    }
  }
}
`;

export const ALIPAY_SCAN_ACCOUNTS_QUERY = gql`
query alipayScanAccount {
  me {
    alipayScanAccounts {
      id
      paymentType
      alipayName
      alipayUid
      qrcodeUrl
      status
      todayShippingTotalAmount
    }
  }
}
`;

export const WITHDRAW_BANK_ACCOUNT_QUERY = gql`
query withdrawBankAccounts {
	me {
    withdrawBankAccounts {
      id
      bankName
      bankBranchName
      bankAccountName
      bankAccount
    }
  }
}
`;

export const ENABLE_PAYMENT_ACCOUNT_MUTATION = gql`
mutation EnablePaymentAccount($input: EnableInvestorPaymentAccountInput!) {
  investor {
    finance {
      enablePaymentAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const ENABLE_ALIPAY_PAYMENT_ACCOUNT_MUTATION = gql`
mutation EnableAlipayPaymentAccount($input: EnableInvestorAlipayPaymentAccountInput!) {
  investor {
    finance {
      enableAlipayPaymentAccount(input: $input) {
        success
      }
    }
  }
}
`

export const ENABLE_ALIPAY_SCAN_ACCOUNT_MUTATION = gql`
mutation EnableAlipayScanAccount($input: EnableInvestorAlipayScanAccountInput!) {
  investor {
    finance {
      enableAlipayScanAccount(input: $input) {
        success
      }
    }
  }
}
`

export const INVESTOR_INFO_QUERY = gql`
query InvestorInfo {
  me {
    id
    account
    name
    qqAccount
    mobileNumber
    mobileNumber2nd
    hasSecurityPassword
    hasAuthenticator
    settings {
      transferQuotaEnabled
      convertBonusToQuotaEnabled
      marketDisplayed
      purchaseEnabled
      createChildEnabled
    }
  }
}`;

export const SET_LOGIN_PASSWORD_MUTATION = gql`
mutation SetLoginPassword($input: SetInvestorLoginPasswordInput!) {
  investor {
    setLoginPassword(input: $input) {
      success
    }   
  }
}`;

export const SET_SECURITY_PASSWORD_MUTATION = gql`
mutation SetSecurityPassword($input: SetInvestorSecurityPasswordInput!) {
  investor {
    setSecurityPassword(input: $input) {
      success
    }   
  }
}`;

export const SET_NAME_MUTATION = gql`
mutation SetName($input: SetInvestorNameInput!) {
  investor {
    setName(input: $input) {
      success
    }   
  }
}`;

export const SET_QQ_ACCOUNT_MUTATION = gql`
mutation SetQqAccount($input: SetInvestorQqAccountInput!) {
  investor {
    setQqAccount(input: $input) {
      success
    }   
  }
}`;

export const CONFIG_QUERY = gql`
query Config {
  config {
    smsConfig {
      expireSeconds
      resendSeconds
    }
  }
}`;

export const SEND_AUTH_CODE_MUTATION = gql`
mutation SendAuthCode($input: SendAuthCodeInput!) {
  investor {
    sendAuthCode(input: $input) {
      success
    }
  }
}`;

export const SET_MOBILE_NUMBER_MUTATION = gql`
mutation SetMobileNumber($input: SetInvestorMobileNumberInput!) {
  investor {
    setMobileNumber(input: $input) {
      success
    }
  }
}`;

export const BANK_OPTIONS_QUERY = gql`
query BankOptions {
  options {
    bankOptions {
      id
      name
    }
  }
}`;

export const SET_PURCHASE_ORDER_AMOUNT_LIMIT = gql`
mutation SetPurchaseOrderAmountLimit($input: SetPurchaseOrderAmountLimitInput!) {
  investor {
    setPurchaseOrderAmountLimit(input: $input) {
      success
    }
  }
}`;

export const TEAM_MEMBERS_QUERY = gql`
query teamMembers($input: InvestorTeamMembersInput, $first: Int, $page: Int) {
  me {
    team {
      teamMembers(input: $input, first: $first, page: $page) {
        data {
          netEasePercent
          account
          teamMemberQuota
          yohoPercent
        }
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
      }
    }
  }
}
`;

export const CREATE_CHILD_INVESTOR_MUTATION = gql`
mutation CreateChildInvestor($input: CreateChildInvestorInput!) {
  investor {
    team {
      createChildInvestor(input: $input) {
        success
      }
    }
  }
}
`;

export const CHILD_INVESTOR_QUERY = gql`
query ChildInvestor($input: ChildInvestorInput!) {
  me {
    team {
      childInvestor(input: $input) {
        account
        netEasePercent
        teamMemberQuota
        yohoPercent
        alipayPercent
        alipayScanPercent
      }
    }
  }
}
`;

export const EDIT_CHILD_INVESTOR_MUTATION = gql`
mutation EditChildInvestor($input: EditChildInvestorInput!) {
  investor {
    team {
      editChildInvestor(input: $input) {
        success
      }
    }
  }
}
`;

export const SELF_PROFIT_REPORT_QUERY = gql`
query PersonalProfitReport($input: PersonalProfitReportInput!) {
  me {
    team {
      personalProfitReport(input: $input) {
        account
        purchaseOrderAmount
        purchaseBonus
        shippingOrderAmount
        shippingBonus
      }
    }
  }
}
`;

export const TEAM_PROFIT_REPORT_QUERY = gql`
query TeamMemberProfitReport($input: TeamMemberProfitReportInput!) {
  me {
    team {
      teamMemberProfitReport(input: $input) {
        account
        purchaseOrderAmount
        purchaseBonus
        shippingOrderAmount
        shippingBonus
      }
    }
  }
}
`;

export const ANNOUNCEMENTS_QUERY = gql`
query Announcements($input: AnnouncementsInput, $first: Int, $page: Int ) {
  announcements(input: $input, first: $first, page: $page) {
    data {
      content
      endTime
      id
      order
      startTime
      title
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
`;

export const BANNER_QUERY = gql`
query Banner($input: BannersInput, $first: Int, $page: Int ) {
  banner(input: $input, first: $first, page: $page) {
    data {
      destinationUrl
      endTime
      id
      imageUrl
      order
      startTime
      title
      urlOpenTarget
    }
    paginatorInfo {
      count
      hasMorePages
      firstItem
      currentPage
      lastItem
      lastPage
      perPage
      total
    }
  }
}
`;

export const TEAM_MEMBER_PROFIT_REPORTS_QUERY = gql`
query TeamMemberProfitReports($input: TeamMemberProfitReportsInput!, $first: Int, $page: Int ) {
  me{
    team {
      teamMemberProfitReports(input: $input, first: $first, page: $page) {
        data {
          account
          purchaseOrderAmount
          purchaseBonus
          shippingOrderAmount
          shippingBonus
        }
        paginatorInfo {
          count
          hasMorePages
          firstItem
          currentPage
          lastItem
          lastPage
          perPage
          total
        }
      }
    }
  }
}

`;

export const WITHDRAW_BONUS_MUTATION = gql`
mutation WithdrawBonus($input: WithdrawInvestorBonusInput!) {
  investor {
    finance {
      withdrawBonus(input: $input) {
        success
      }
    }
  }
}
`;

export const WITHDRAW_QUOTA_MUTATION = gql`
mutation WithdrawQuota($input: WithdrawInvestorQuotaInput!) {
  investor {
    finance {
      withdrawQuota(input: $input) {
        success
      }
    }
  }
}
`;

export const INVESTOR_CONFIG_QUERY = gql`
query InvestorConfig {
  config {
    investorConfig {
      canWithdrawAfterLastTransactionXHours
      investorWithdrawQuotaFeePercentWithoutAchieveAchievement
      investorWithdrawQuotaFeePercentWithAchieveAchievement
      investorWithdrawQuotaAchievementPercent
      authenticationMode
      isCompressImageBeforeUpload
    }
    currencyConfigs(input: {currencyTypes: CNY}) {
      currencyType
      id
      investorBonusWithdrawFee
      investorWithdrawMin
      investorQuotaWithdrawFeeMin
      investorRechargeQuotaMax
      investorRechargeQuotaMin
    }
  }

  me {
    financeInfo {
      levelGroup {
        purchaseOrderUploadImageEnabled
        shippingOrderConcurrentMax
      }
    }
    settings {
        transferQuotaEnabled
        convertBonusToQuotaEnabled
        marketDisplayed
        purchaseEnabled
    }
    canCreateSignupCode
  }
}
`;

export const REPORT_SHIPPING_ORDER_PROBLEM_MUTATION = gql`
mutation ReportShippingOrderProblem($input: ReportShippingOrderProblemInput!) {
  investor {
    reportShippingOrderProblem(input: $input) {
      success
    }
  }
}
`;

export const IDLE_CONFIG_QUERY = gql`
query IdleConfig {
  config {
    investorConfig {
      investorCloseShippingSeconds
      heartbeatIntervalSeconds
      confirmOnlineCountdownSeconds
    }
  }
}
`;

export const REPORT_HEARTBEAT_MUTATION = gql`
mutation ReportHeartbeat {
  investor {
    reportHeartbeat {
      success
    }
  }
}
`

export const SHIPPING_ORDER_QUERY = gql`
query ShippingOrder($input: InvestorShippingOrderInfoInput!) {
  me {
    orderInfo {
      shippingOrderInfo(input: $input) {
        _id
        amount
        commodity {
          id
          image
          name
          price
        }
        createdAt
        investorId
        investorBonus
        paymentType
        shippingFee
        status
        orderUserRealName
        payeePaymentAccount {
          bankId
          bankAccount
          bankAccountName
        }
        payeeAlipayPaymentAccount {
          alipayAccount
          alipayName
        }
        payeeAlipayScanAccount {
          alipayName
          alipayUid
          qrcodeImage
        }
      }
    }
  }
}

`

export const RECHARGE_QUOTA_MUTATION = gql`
  mutation RechargeQuota($input: RechargeInvestorQuotaInput!) {
    investor {
      finance {
        rechargeQuota(input: $input) {
          bankAccount
          bankAccountName
          bankBranchName
          bankName
          remark
          success
        }
      }
    }
  }
`;

export const MESSAGES_QUERY = gql`
query messages($input: InvestorMessagesInput!, $first: Int, $page: Int ) {
  me {
    messages(input: $input, first: $first, page: $page) {
      data {
        createdAt
        extraInfo {
          k
          v
        }
        id
        isRead
        title
        message
        type
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastPage
        lastItem
        perPage
        total
      }
    }
  }
}

`

export const NEW_MESSAGE_SUBSCRIPTION = gql`
subscription investorMessageCreated {
  investorMessageCreated {
    createdAt
    extraInfo {
      k
      v
    }
    id
    isRead
    message
    title
    type
  }
}`;

export const MARK_MESSAGES_AS_READED_MUTATION = gql`
mutation markMessageAsRead($input: MarkMessageAsReadInput!) {
  investor {
    markMessageAsRead(input: $input) {
      success
    }
  }
}
`;

export const GET_AUTHENTICATOR_QRCODE_MUTATION = gql`
mutation getAuthenticatorQrCode {
    investor {
        authenticator {
            getAuthenticatorQrCode {
                secret
                base64ImageUrl
            }
        }
    }
}
`;

export const BIND_AUTHENTICATOR_MUTATION = gql`
mutation bindAuthenticator ($input:BindAuthenticatorInput!){
    investor {
        authenticator {
            bindAuthenticator (input:$input){
                success
            }
        }
    }
}
`;

export const BONUS_SYSTEM_GROUP_QUERY = gql`
query rootBonusSystemGroup {
  me {
    rootBonusSystemGroup {
      netEasePercentReward {
        childPercentList
      }
      yohoPercentReward {
        childPercentList
      }
      alipayPercentReward {
        childPercentList
      }
      purchaseAlipayPercentReward {
        childPercentList
      }
      alipayScanPercentReward {
        childPercentList
      }
    }
  }
}
`;

export const SIGNUP_CODES_QUERY = gql`
query signupCodes($first: Int, $page: Int) {
  me {
    team {
      signupCodes(first: $first, page: $page) {
        data {
          id
          netEasePercent
          yohoPercent
          alipayPercent
          code
          signupCount
          remark
        }
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
      }
    }
  }
}
`;

export const CREATE_SIGNUP_CODE_MUTATION = gql`
mutation createSignupCode($input: CreateSignupCodeInput!) {
  investor {
    team {
      createSignupCode(input: $input) {
        success
      }
    }
  }
}
`;

export const DELETE_SIGNUP_CODE_MUTATION = gql`
mutation deleteSignupCode($input: DeleteSignupCodeInput!) {
  investor {
    team {
      deleteSignupCode(input: $input) {
        success
      }
    }
  }
}
`;

export const SIGNUP_BY_CODE_MUTATION = gql`
mutation signupByCode($input: SignupByCodeInput!) {
  signupByCode(input: $input) {
    success
  }
}
`;

// 未登入可以取的設定
export const WEBSITE_CONFIG_QUERY = gql`
query websiteConfig {
  website {
    iosAppDownloadUrl
    androidAppDownloadUrl
    customerServiceUrl
    investorAvailableShippingPaymentTypes
    investorAvailablePurchasePaymentTypes
  }
}
`;

export const LOGOUT_MUTATION = gql`
mutation logout {
  investor {
    logout {
      success
    }
  }
}
`;

export const TRANSFER_QUOTA_TO_OTHER_INVESTOR_MUTATION = gql`
  mutation TransferQuotaToOtherInvestor($input: TransferQuotaToOtherInvestorInput!) {
    investor {
      finance {
        transferQuotaToOtherInvestor(input: $input) {
          success
        }
      }
    }
  }
`;

export const CONVERT_BONUS_TO_QUOTA_MUTATION = gql`
mutation ConvertBonusToQuota($input: ConvertBonusToQuotaInput!) {
  investor {
    finance {
      convertBonusToQuota(input: $input) {
        success
      }
    }
  }
}`;

export const SHIPPING_ORDER_PROBLEM_TYPE_QUERY = gql`
  query ShippingOrderProblemType {
    enums {
      shippingOrderProblemType {
        description
        key
      }
    }
  }
`;

export const DELETE_PAYMENT_ACCOUNT_MUTATION = gql`
mutation DeletePaymentAccount($input: DeleteInvestorPaymentAccountInput!) {
  investor {
    finance {
      deletePaymentAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const DELETE_ALIPAY_PAYMENT_ACCOUNT_MUTATION = gql`
mutation DeleteAlipayPaymentAccount($input: DeleteInvestorAlipayPaymentAccountInput!) {
  investor {
    finance {
      deleteAlipayPaymentAccount(input: $input) {
        success
      }
    }
  }
}
`;

export const DELETE_ALIPAY_SCAN_ACCOUNT_MUTATION = gql`
mutation DeleteAlipayScanAccount($input: DeleteInvestorAlipayScanAccountInput!) {
  investor {
    finance {
      deleteAlipayScanAccount(input: $input) {
        success
      }
    }
  }
}
`

export const PASSWORD_INFO_QUERY = gql`
query{
  me{
    passwordInfo{
      isLoginPasswordWeak
      firstLoginPasswordChangedAtAfterFirstLogin
    }
  }
}
`;

export const WITHDRAW_ADDRESSES_QUERY = gql`
query {
  me {
    withdrawWalletAddresses {
      id
      address
    }
  }
}
`

export const ADD_INVESTOR_WITHDRAW_ADDRESS_MUTATION = gql`
mutation AddInvestorWithdrawAddress($input: AddInvestorWithdrawWalletAddressInput!) {
  investor {
    finance {
      addInvestorWithdrawWalletAddress(input: $input) {
        success
      }
    }
  }
}
`;

export const RECHARGE_QUOTA_BY_USDT_MUTATION = gql`
mutation RechargeQuotaByUsdt($input: RechargeQuotaByUsdtInput!) {
  investor {
    finance {
      rechargeQuotaByUsdt ( input: $input ) {
        success
        address
        amount
        remark
        orderUuid
      }
    }
  }
}
`;

export const CANCEL_RECHARGE_ORDER_MUTATION = gql`
mutation CancelRechargeOrder($input: CancelRechargeOrderInput!) {
  investor {
    finance {
      cancelRechargeOrder ( input: $input ) {
        success
      }
    }
  }
}
`;

export const WITHDRAW_QUOTA_BY_USDT_MUTATION = gql`
mutation WithdrawQuotaByUsdt($input: WithdrawQuotaByUsdtInput!) {
  investor {
    finance {
      withdrawQuotaByUsdt ( input: $input ) {
        success
      }
    }
  }
}
`;

export const EXCHANGE_WEBSITE_INFO = gql`
query exchangeWebsiteInfo($input: InvestorExchangeWebsiteMarketInvestorsInput, $first: Int, $page: Int) {
  me {
    exchangeWebsiteInfo {
      marketInvestors(input: $input, first: $first, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
        data {
          account
          usdtQuota
        }
      }
    }
  }
}
`;

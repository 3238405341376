import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { RegisterComponent } from './ui/register/register.component';
import { HomeComponent } from './ui/home/home.component';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { AdminComponent } from './shared/component/admin/admin.component';
import { FinanceComponent } from './ui/home/finance/finance.component';
import { TeamProfitComponent } from './shared/component/team-profit/team-profit.component';
import { RechargeComponent } from './shared/component/recharge/recharge.component';
import { WithdrawQuotaComponent } from './shared/component/withdraw/withdraw-quota/withdraw-quota.component';
import { PaymentComponent } from './shared/component/payment/payment.component';
import { OrderHistoryComponent } from './shared/component/order-history/order-history.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'reg', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'finance', component: FinanceComponent },
      { path: 'history', component: OrderHistoryComponent },
      { path: 'team', component: TeamProfitComponent },
      { path: 'recharge', component: RechargeComponent },
      { path: 'withdraw', component: WithdrawQuotaComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { WebsiteConfigService } from '../../services/website-config.service';
import { DeviceService } from 'lib';
import { TemplatePortal } from '@angular/cdk/portal';
import { F2eHelper } from 'lib';
import { platformConfig } from '../../../../platforms/platform';

@Component({
  selector: 'kr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  @ViewChild('twoFactor') twoFactor: TemplateRef<any>;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  platform = platformConfig.name;
  overlayRef?: OverlayRef;

  isSelectedUsername = false;
  isFillUsername = false;
  isSelectedPwd = false;
  isFillPwd = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authSer: AuthService,
    public websiteConfig: WebsiteConfigService,
    private deviceSer: DeviceService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {
    // redirect to home if already logged in
    if (this.authSer.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      authCode: [''],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  selectedUsername() {
    this.isSelectedUsername = true;
  }
  unselectedUsername() {
    this.isSelectedUsername = false;
  }
  fillUsername() {
    if (this.loginForm.controls.username.value) this.isFillUsername = true
    else this.isFillUsername = false
  }
  selectedPwd() {
    this.isSelectedPwd = true;
  }
  unselectedPwd() {
    this.isSelectedPwd = false;
  }
  fillPwd() {
    if (this.loginForm.controls.password.value) this.isFillPwd = true
    else this.isFillPwd = false
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid || this.loading) {
      return;
    }

    this.loading = true;
    this.authSer.login(this.f.username.value, this.f.password.value, this.f.authCode.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.loginForm.controls['authCode'].setValue('');
          this.loginForm.controls['authCode'].clearValidators();
          if (this.overlayRef == null && data?.errors?.[0].extensions?.code === 17 && this.loginForm.controls['authCode'].value === '') {
            this.loginForm.controls['authCode'].setValidators([Validators.required]);
            const strategy = this.overlay
              .position()
              .global()
              .centerHorizontally()
              .centerVertically()
              ;
            this.overlayRef = this.overlay.create({
              positionStrategy: strategy,
              hasBackdrop: true,
              disposeOnNavigation: true,
              // backdropClass: ['cdk-overlay-transparent-backdrop'],
            });
            this.overlayRef.attach(new TemplatePortal(this.twoFactor, this.viewContainerRef));
          }
          if (data?.data?.login) {
            this.overlayRef?.detach();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        }
      );
  }
  openCustomerServiceLink() {
    const url = this.websiteConfig.config?.value?.customerServiceUrl!;
    if (F2eHelper.isInApp) {
      this.deviceSer.openBrowser(url);
      return;
    }
    F2eHelper.openNewWindow(url);
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kr-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  _isShow = true;
  _adaptOutside = false;
  _customStyle: { [key: string]: string } = {};
  _customText: string = '暂无数据';

  @Input()
  set isShow(v: boolean) {
    this._isShow = v;
  }
  get isShow() {
    return this._isShow;
  }

  @Input()
  set adaptOutside(v: boolean) {
    this._adaptOutside = v;
  }
  get adaptOutside() {
    return this._adaptOutside;
  }

  @Input()
  set customStyle(style: { [key: string]: string }) {
    this._customStyle = style;
  }
  get customStyle() {
    return this._customStyle;
  }

  @Input()
  set customText(text: string) {
    this._customText = text;
  }
  get customText() {
    return this._customText;
  }

  constructor() { }

  ngOnInit() {
  }
}

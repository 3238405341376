import { BehaviorSubject } from 'rxjs';
import { EnvType, IConfig, IPlatformConfig, IPlatformConfigSetting } from '../types';
import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// @Injectable()
export class ConfigService {


  constructor(
    private envConfig: IConfig,
    private platformConfig: IPlatformConfig
  ) {
  }

  
  getPlatformName(): string {
    return this.platformConfig.name;
  }

  get envConfiguration(): IConfig {
    return this.envConfig;
  }

  get platformConfiguration(): {
    environmentSetting: IPlatformConfigSetting;
    theme: string | string[] | undefined;
  } {
    // 相容 web 平台有可能實作多樣版
    const extenTheme = this.platformConfig as { theme?: string, themes?: string[] };
    return {
      environmentSetting: this.platformConfig.platform[this.envConfiguration.envType],
      theme: extenTheme.themes ?? extenTheme.theme
    };
  }

  getUserLanguage(): string {
    const userLang: string = navigator['language'] || (navigator as any)['userLanguage'] || this.envConfiguration.lang;
    return userLang.toLocaleLowerCase();
  }

}

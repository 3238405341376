import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Website, Query } from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { WEBSITE_CONFIG_QUERY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class WebsiteConfigService {
  configQuery = this.apollo.watchQuery<Query>({
    query: WEBSITE_CONFIG_QUERY
  });
  config = new BehaviorSubject<Website | null>(null);

  constructor(
    private apollo: Apollo
  ) {
    this.getConfig();
  }

  getConfig() {
    this.configQuery.valueChanges.subscribe(resp => {
      this.config.next(resp.data.website!);
    });
  }

  refetch() {
    this.configQuery.refetch();
  }
}

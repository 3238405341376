interface Array<T> {
  limit(count: number): Array<T>;
  shuffle(): Array<T>;
  random(): T;
}

Array.prototype.limit = function(count: number) {
  return this.slice(0, count);
};

Array.prototype.shuffle = function() {
  if (this.length > 1) {
    for (let i = 0; i < this.length - 1; i++) {
      const r: number = Math.floor(Math.random() * (this.length - i));
      if (r > 0) {
        const tmp = this[i];
        this[i] = this[i + r];
        this[i + r] = tmp;
      }
    }
  }
  return this;
};

Array.prototype.random = function() {
  if (this.length > 0) {
    const rnd = Math.floor(Math.random() * this.length);
    return this[rnd];
  }
  return null;
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ggThousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(num: number): string {
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  }
}

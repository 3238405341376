export class SavePasswordInfo {
  enable: boolean;
  account?: string | null;
  password?: string | null;
  constructor() {
    this.enable = true;
    this.account = null,
    this.password = null;
  }
}

export class UserInfoItem {
  id: number;
  token: string;
  is_beta: boolean;
  account: string;
  is_bind_google_code?: boolean;
  is_set_hand_lock?: boolean;
  hand_lock_active?: boolean;
  has_bind_bank_card?: boolean;
  has_set_balance_password?: boolean;
  lang: string;
  device_id?: string;
}

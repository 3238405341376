import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecurityMechanismService } from '../../services/security-mechanism.service';

@Component({
  selector: 'kr-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  selectedTabIndex = 0;
  constructor(
    private route: ActivatedRoute,
    private securityMechanism: SecurityMechanismService
  ) {
    this.route.url.subscribe(url => {
      if (url?.[1]?.toString() === 'withdrawQuota') {
        this.selectedTabIndex = 0;
      } else {
        this.selectedTabIndex = 1;
      }
    })
  }

  ngOnInit(): void {
    this.securityMechanism.checkSecurityMechanism();
  }

}

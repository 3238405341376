import { Injectable } from '@angular/core';

export interface AudioSetting {
  winning_music_notice?: boolean; // 中獎音效
  lottery_music_notice?: boolean; // 開獎獎期內容音效
  cutoff_music_notice?: boolean;  // 封單音效
  chat_music_notice?: boolean; // 聊天通知音效
}

@Injectable()
export class AudioService {

  debugFlag = false;  // 測試用, 忽略用戶開關, 直接進行播放檢測

  private audioSetting: AudioSetting = {
    winning_music_notice: false,
    lottery_music_notice: false,
    cutoff_music_notice: false,
    chat_music_notice: false,
  };

  // audio file(blob) cache url mapping table
  private audioObjectURLs: {[url: string]: string};

  constructor() {
    // 預載, 讓他乾跑一次, 後續才不會斷片
    const preloadAudios = [
      '/assets/media/new_order_sound.mp3',
    ];
    // preloadAudios.forEach( f => this.playAudio(f, true));
    // 將 url/mp3 預先下載, 建成 cache, 爾後直接取用轉換後的 url, 可以節省些許流量, 也增加音效播放的流暢度 (免網路延遲)
    this.audioObjectURLs = {};
    preloadAudios.forEach( audioPath => {
      this.createAudioCache(audioPath);
    });

    // 註冊個測試用的函式
    (window as any)['audioTest'] = (force: boolean = false) => { this.test(force); };

    (window as any)['preloadAudio'] = () => {
      this.createAudioCache('assets/sounds/guoan.mp3');
    };
  }

  private createAudioCache(paht: string) {
    // set default path = original-path
    this.audioObjectURLs[paht] = paht;
    fetch(paht)
    .then(resp => {
      if (resp.ok) {
        return resp.blob();
      }
      throw new Error(`Fetch audio resource failed (${resp.status})`);
    })
    .then (blob =>  URL.createObjectURL(blob))
    .then (cacheUrl => {
      this.audioObjectURLs[paht] = cacheUrl;
    })
    .catch (err => console.error(err));
  }

  /** 做個簡單的測試, 不然一天到晚在有問題, 又分不出是誰的問題 */
  // tslint:disable-next-line:typedef
  test(force: boolean) {
    return new Promise<void>( async (resolve) => {
      this.debugFlag = force;
      console.log('測試: playStopVoice');
      await this.playStopVoice();
      console.log('測試: playWinNoticeVoice');
      await this.playWinNoticeVoice();
      console.log('測試: playOpenVoice');
      await this.playOpenVoice(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']);
      console.log('測試: playChatNoticeVoice');
      await this.playChatNoticeVoice();
      this.debugFlag = false;
      resolve();
    });
  }

  config(audioSetting: AudioSetting) {
    this.audioSetting = {...this.audioSetting, ...audioSetting};
    console.log('config:', this.audioSetting);
  }

  public playAudio(voicePath: string, dryRun = false): Promise<void> {
    return new Promise((resolve) => {
      const path = this.audioObjectURLs[voicePath] || voicePath;
      const audio = new Audio(path);
      audio.play().catch(e => {
        console.warn(e);
        resolve();
      });
      audio.addEventListener('ended', () => resolve());
      if (dryRun) {
        audio.addEventListener('play', () => {
          audio.pause();
        });
        audio.addEventListener('pause', () => {
          resolve();
        });
      }
    });
  }

  async playStopVoice() {
    if (this.audioSetting.cutoff_music_notice || this.debugFlag) {
      await this.playAudio('assets/sounds/guoan.mp3');
    } else {
      console.log('skip stop sounds');
    }
  }

  async playOpenVoice(balls: string[]) {
    if (this.audioSetting.lottery_music_notice || this.debugFlag) {
      await this.playAudio('assets/sounds/open.mp3');
      this.playOpenBallVoice(balls);
    } else {
      console.log('skip open sounds');
    }
  }

  private async playOpenBallVoice(balls: string[]) {
    if (!balls || balls.length === 0) {
      return;
    }

    for (const ball of balls) {
      const ballNumber = parseInt(ball, 10);
      await this.playAudio(`assets/sounds/m_${ballNumber}.mp3`);
    }
  }

  async playWinNoticeVoice() {
    console.log('win in lib', this.audioSetting);
    if (this.audioSetting.winning_music_notice || this.debugFlag) {
      await this.playAudio('assets/sounds/winning.mp3');
    } else {
      console.log('skip winning sounds');
    }
  }

  async playChatNoticeVoice() {
    if (this.audioSetting.chat_music_notice || this.debugFlag) {
      await this.playAudio('assets/sounds/chat.mp3');
    } else {
      console.log('skip chat sounds');
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'lib';
import { ModalOverlayRef } from 'lib';
import { finalize, map } from 'rxjs/operators';
import { CreateChildInvestorInput, Mutation, Query } from 'lib/src/types/schema';
import { BONUS_SYSTEM_GROUP_QUERY, CREATE_CHILD_INVESTOR_MUTATION } from '../../helpers/api';
import { BonusGroupService } from '../../services/bonus-group.service';

@Component({
  selector: 'kr-create-member',
  templateUrl: './create-member.component.html',
  styleUrls: ['./create-member.component.scss']
})
export class CreateMemberComponent implements OnInit {
  form: FormGroup;
  loading = false;
  inited = false;

  constructor(
    private overlayRef: ModalOverlayRef,
    private apollo: Apollo,
    private messageSer: MessageService,
    public bonusGroupSer: BonusGroupService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    await this.bonusGroupSer.ready;
    this.form = new FormGroup(
      {
        account: new FormControl('', {
          validators: [
            Validators.required,
          ]
        }),
        password: new FormControl('', {
          validators: [
            Validators.required,
          ]
        }),
        netEasePercent: new FormControl(this.bonusGroupSer.netEaseMin, {
          validators: [
            Validators.required,
            Validators.max(this.bonusGroupSer.netEaseMax),
            Validators.min(this.bonusGroupSer.netEaseMin),
          ]
        }),
        yohoPercent: new FormControl(this.bonusGroupSer.yohoMin, {
          validators: [
            Validators.required,
            Validators.max(this.bonusGroupSer.yohoMax),
            Validators.min(this.bonusGroupSer.yohoMin),
          ]
        }),
        alipayPercent: new FormControl(this.bonusGroupSer.alipayMin, {
          validators: [
            Validators.required,
            Validators.max(this.bonusGroupSer.alipayMax),
            Validators.min(this.bonusGroupSer.alipayMin),
          ]
        }),
        alipayScanPercent: new FormControl(this.bonusGroupSer.alipayScanMin, {
          validators: [
            Validators.required,
            Validators.max(this.bonusGroupSer.alipayScanMax),
            Validators.min(this.bonusGroupSer.alipayScanMin),
          ]
        }),
        teamMemberQuota: new FormControl(1, {
          validators: [
            Validators.required,
            Validators.min(1)
          ]
        }),
      }
    )
    this.inited = true;
  }

  get f() { 
    return this.form.controls;
  }

  submit() {
    if (this.loading === true) return;
    const input: CreateChildInvestorInput = {
      account: this.f.account.value,
      password: this.f.password.value,
      netEasePercent: this.f.netEasePercent.value.toString(),
      yohoPercent: this.f.yohoPercent.value.toString(),
      alipayPercent: this.f.alipayPercent.value.toString(),
      alipayScanPercent: this.f.alipayScanPercent.value.toString(),
      purchaseAlipayPercent: `${this.bonusGroupSer.purchaseAlipayMin}`,
      teamMemberQuota: this.f.teamMemberQuota.value,
    }
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: CREATE_CHILD_INVESTOR_MUTATION,
      variables: { input }
    }).pipe(finalize(() => this.loading = false))
    .subscribe(resp => {
      if (resp.data?.investor?.team?.createChildInvestor?.success) {
        this.messageSer.notify('创建下级成功');
        this.afterSubmit();
        this.close();
      }
    })
  }

  afterSubmit() {}

  close() {
    this.overlayRef.close();
  }

  netEasePercentChange(event: any): void {
    this.f.netEasePercent.setValue(parseFloat(this.f.netEasePercent.value.toFixed(2)));
  }
}

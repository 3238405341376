import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalService } from 'lib';
import { CurrencyTypeEnum, InvestorFinance_RechargeQuotaArgs, Mutation } from 'lib/src/types/schema';
import { finalize, map } from 'rxjs/operators';
import { RECHARGE_QUOTA_BY_USDT_MUTATION, RECHARGE_QUOTA_MUTATION } from '../../helpers/api';
import { InvestorConfigService } from '../../services/investor-config.service';
import { SmsAuthService } from '../../services/sms-auth.service';
import { WalletService } from '../../services/wallet.service';
import { RechargeInfoComponent } from './recharge-info/recharge-info.component';
import { InvestorFinance_RechargeQuotaByUsdtArgs, RechargeQuotaByUsdtInput, RechargeQuotaByUsdtResult } from 'lib/types/schema';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'kr-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss']
})
export class RechargeComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  loading = false;
  form!: FormGroup;
  isRechargeInfoVisible = false;
  rechargeInfo?: RechargeQuotaByUsdtResult;
  rechargeAddress = ''

  constructor(
    public smsAuthSer: SmsAuthService,
    private apollo: Apollo,
    private messageSer: MessageService,
    public walletSer: WalletService,
    public investorConfigSer: InvestorConfigService,
    private modalSer: ModalService,
    private formBuilder: FormBuilder,
    private clipbaord: Clipboard,
    ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      amount: ['', [Validators.required]],
      userAddress: ['', [Validators.required]],
    })
  }

  submit() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) return;
    if (this.loading === true) return;
    let variables: InvestorFinance_RechargeQuotaByUsdtArgs = {
      input: this.form.value
    };
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: RECHARGE_QUOTA_BY_USDT_MUTATION,
      variables
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.finance?.rechargeQuotaByUsdt?.success) {
          this.rechargeInfo = resp.data?.investor?.finance?.rechargeQuotaByUsdt;
          this.rechargeAddress = this.rechargeInfo.address ?? '';
          this.isRechargeInfoVisible = true;
          this.resetForm();
        }
      })
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }

  copy(text: string) {
    this.clipbaord.copy(text);
    this.messageSer.notify('已複製', 600);
  }
}

import { Injectable } from '@angular/core';
import { MessageService, ModalService } from 'lib';
import { InvestorAuthenticationModeEnum } from 'lib/src/types/schema';
import { SetSecurityPasswordComponent } from '../component/set-security-password/set-security-password.component';
import { InvestorConfigService } from './investor-config.service';
import { InvestorService } from './investor.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityMechanismService {

  constructor(
    private investorConfigSer: InvestorConfigService,
    private investorSer: InvestorService,
    private messageSer: MessageService,
    private modalSer: ModalService
  ) { }

  public checkSecurityMechanism() {
    const mode = this.investorConfigSer.config?.value?.authenticationMode;

    switch (mode) {
      case InvestorAuthenticationModeEnum.SecurityPassword:
        if (this.investorSer.hasSecurityPassword.value === false) {
          this.messageSer.alert({
            title: '温馨提示',
            text: '请先设置安全密码。',
            btnCloseTitle: '确认',
            onClose: () => this.showChangeSecurityPasswordModal()
          });
        }
        break;
      case InvestorAuthenticationModeEnum.PassAuthentication:
      default:
    }
    if (this.investorConfigSer.config?.value?.authenticationMode !== 'PASS_AUTHENTICATION') {

    }
  }

  showChangeSecurityPasswordModal() {
    this.modalSer.open(SetSecurityPasswordComponent,
      {
        hasBackdrop: true,
        width: '100%',
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Query } from 'lib/src/types/schema';
import { map } from 'rxjs/operators';
import { BONUS_SYSTEM_GROUP_QUERY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class BonusGroupService {
  ready = new Promise<void>((resolve) => {
    this.resolveReady = resolve;
  })
  resolveReady: () => void;
  netEaseOptions: string[];
  yohoOptions: string[];
  alipayOptions: string[];
  purchaseAlipayOptions: string[];
  alipayScanOptions: string[];
  netEaseMax: number;
  netEaseMin: number;
  yohoMax: number;
  yohoMin: number;
  alipayMax: number;
  alipayMin: number;
  purchaseAlipayMax: number;
  purchaseAlipayMin: number;
  alipayScanMax: number;
  alipayScanMin: number;

  constructor(
    private apollo: Apollo
  ) {
    this.getBonusGroup();
  }

  getBonusGroup() {
    this.apollo.query<Query>({
      query: BONUS_SYSTEM_GROUP_QUERY
    }).subscribe(resp => {
        this.netEaseOptions = resp.data.me?.rootBonusSystemGroup.netEasePercentReward?.childPercentList!;
        this.netEaseMax = parseFloat(this.netEaseOptions.slice(-1)[0]);
        this.netEaseMin = parseFloat(this.netEaseOptions[0]);
        this.yohoOptions = resp.data.me?.rootBonusSystemGroup.yohoPercentReward?.childPercentList!;
        this.yohoMax = parseFloat(this.yohoOptions.slice(-1)[0]);
        this.yohoMin = parseFloat(this.yohoOptions[0]);
        this.alipayOptions = resp.data.me?.rootBonusSystemGroup.alipayPercentReward?.childPercentList!;
        this.alipayMax = parseFloat(this.alipayOptions.slice(-1)[0]);
        this.alipayMin = parseFloat(this.alipayOptions[0]);
        this.purchaseAlipayOptions = resp.data.me?.rootBonusSystemGroup.purchaseAlipayPercentReward?.childPercentList!;
        this.purchaseAlipayMax = parseFloat(this.purchaseAlipayOptions.slice(-1)[0]);
        this.purchaseAlipayMin = parseFloat(this.purchaseAlipayOptions[0]);
        this.alipayScanOptions = resp.data.me?.rootBonusSystemGroup.alipayScanPercentReward?.childPercentList!;
        this.alipayScanMax = parseFloat(this.alipayScanOptions.slice(-1)[0]);
        this.alipayScanMin = parseFloat(this.alipayScanOptions[0]);
        this.resolveReady();
      })
  }
}

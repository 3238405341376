import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { MyLogService } from '../services';
import { F2eHelper } from '../helper';
import { MyLoggerLevelEnum } from '../types';

@Injectable()
export class MyErrorHandler extends ErrorHandler {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private myLogSer: MyLogService,
  ) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();
  }

  handleError(error: {message: string, stack: any}) {

    if (F2eHelper.isPlatformBrowser) {
      // const ua = new UAParser();
      const obj = {
        message: error.message,
        stack: error.stack
      };
      this.myLogSer.log(MyLoggerLevelEnum.error, obj);
    }

    if (F2eHelper.isPlatformBrowser) {
      if (error.message && typeof(error.message) === 'string' && error.message.indexOf('Error: Cannot match any routes') >= 0 ) {
        F2eHelper.windowRedirectUrl(`http://google.com.tw`);
      }
    }

    // delegate to the default handler
    super.handleError(error);
  }

}

import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Enums, Query } from 'lib/src/types/schema';
type KeyofEnum = keyof Enums;
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public langs: Record<string, Record<string, string>> = {};
  constructor(
    private apollo: Apollo
  ) {
    this.init();
  }

  init() {
    this.apollo.query<Query>({
      query: gql`
        query enums {
          enums {
            investorBonusChangeType {
              description
              key
            }
            investorQuotaChangeType {
              description
              key
            }
            merchantQuotaChangeType {
              description
              key
            }
            purchaseOrderStatus {
              description
              key
            }
            shippingOrderStatus {
              description
              key
            }
            exchangeWebsiteOrderStatus {
              description
              key
            }
          }
        }
      `
    }).subscribe(({ data }) => {
      const enums = data.enums!;
      
      const keys = Object.keys(data.enums!).filter(key => key !== '__typename');
      keys.forEach((key: any) => {
        this.langs[key as KeyofEnum] =  enums[key as KeyofEnum].reduce((acc: Record<string, string>, cur: any) => {
          acc[cur.key] = cur.description;
          return acc;
        }, {});
      });
      console.log(this.langs);
    });
  }

  t(group: KeyofEnum, key: string) {
    // const [group, key2] = key.split('.');
    return this.langs[group] && this.langs[group][key] ? this.langs[group][key] : key;
  }
}

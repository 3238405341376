import { Pipe, PipeTransform } from '@angular/core';
import { F2eHelper } from '../helper';

@Pipe({
  name: 'ggMoneyFormat'
})
export class MoneyFormatPipe implements PipeTransform {

  transform(value: number | string): number {
    // return this.ggMoneyFormat(value);
    return F2eHelper.ggMoneyFormat(value);
  }

  test() {
    const testSet = [-0, 0.0, 1.2, +123, '+123', null, undefined, '-123', '--123', '+-123', '1.2.3.4', '-.222', '11,111', '2,222,222,222'];
    for (const num of testSet) {
      console.log(`ggMoneyFormat Test: ${num} => ${this.transform(num!)}`);
    }
  }

}

import { NgModule } from '@angular/core';
import { DecodeURIPipe } from './decode-uri.pipe';
import { JsonParsePipe } from './json-parse.pipe';
import { MoneyFormatPipe } from './money-format.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ThousandSeparatorPipe } from './thousand-separator.pipe';
import { GGDateFormatPipe } from './date-format.pipe';
import { TimePeroidPipe } from './time-peroid.pipe';
import { TimePipe } from './time.pipe';

@NgModule({
  declarations: [
    DecodeURIPipe,
    JsonParsePipe,
    MoneyFormatPipe,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    ThousandSeparatorPipe,
    GGDateFormatPipe,
    TimePeroidPipe,
    TimePipe
  ],
  imports: [],
  exports: [
    DecodeURIPipe,
    JsonParsePipe,
    MoneyFormatPipe,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    ThousandSeparatorPipe,
    GGDateFormatPipe,
    TimePeroidPipe,
    TimePipe
  ]
})
export class PipesModule { }

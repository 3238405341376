import { Injectable, TemplateRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export enum MessageCode {
  Notification,
}

@Injectable({
  providedIn: 'root'
})
export class NotificationTempService {
  private templateMap = new Map<MessageCode, TemplateRef<any>>();

  constructor(private notification: NzNotificationService) { }

  // 初始化 Notification TemplateRef
  public initTemplate(message: MessageCode, ref: TemplateRef<any>): void {
    this.templateMap.set(message, ref);
  }

  public showMessage(messageCode: MessageCode, data: any, duration: number) {
    return this.notification.template(<TemplateRef<any>>this.templateMap.get(messageCode), {
      nzDuration: duration, // 持续时间(毫秒)，当设置为0时不消失
      nzData: data,
    });
  }

  public removeMessage(messageId?: string) {
    this.notification.remove(messageId);
  }
}

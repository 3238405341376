import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'ggDateFormat' })

export class GGDateFormatPipe implements PipeTransform {
  constructor() { }

  getMsgSendTime(value: dayjs.ConfigType) {
    const today = dayjs();
    const yesterday = today.subtract(24, 'hour');
    const sendTime = dayjs(value);

    if (this.isSameDate(sendTime, today)) {
      return sendTime.format('A h:mm').replace('AM', '上午').replace('PM', '下午');
    } else if (this.isSameDate(sendTime, yesterday)) {
      return '昨天';
    } else {
      return sendTime.format('YYYY-M-D');
    }
  }

  isSameDate(target: dayjs.ConfigType, basis?: dayjs.ConfigType) {
    const basisDayjs = basis ? dayjs(dayjs(basis).format('YYYY-MM-DD')) : dayjs();
    const targetDayjs = dayjs(dayjs(target).format('YYYY-MM-DD'));
    return basisDayjs.isSame(targetDayjs);
  }

  transform(value: string, ggFormat?: string): string {
    return dayjs(value).format(ggFormat);
    // return this.timeSer.format(value, ggFormat);
  }
}

import { style, animate, animation, transition, trigger, useAnimation, query, stagger } from '@angular/animations';

export const fadeInAnimation = animation([
  style({ opacity: 0, }),
  animate('{{timing}} ease-out', style({ opacity: 1 }))
]);

export const fadeOutAnimation = animation([
  style({ opacity: 1, }),
  animate('{{timing}} ease-out', style({ opacity: 0, }))
]);

export const slideAnimation = animation([
  style({
    opacity: '{{ opacityTo }}',
    transform: '{{ transformTo }}'
  }),
  animate('{{ timing }} {{ delay }} ease-in', style({
    opacity: '{{ opacityFrom }}',
    transform: '{{ transformFrom }}'
  }))
]);

export const fromRightIn = animation([
  style({
    opacity: .2,
    // transform: 'translateX(6%)'
  }),
  animate('.35s .15s ease-out', style({
    opacity: 1,
    transform: 'translateX(0%)'
  }))
]);

export const slideInAnimation = animation([
  style({
    transform: '{{propertyFrom}}',
    opacity: 0,
  }),
  animate('{{timing}} {{delay}} ease-out', style({
    transform: '{{propertyTo}}',
    opacity: 1,
  }))
]);

export const slideOutAnimation = animation([
  style({
    transform: '{{propertyFrom}}',
    opacity: 1,
  }),
  animate('{{timing}} {{delay}} ease-out', style({
    transform: '{{propertyTo}}',
    opacity: 0,
  }))
]);

// v2web 右側菜單收合動畫
export const rightSideMenuAnimation = [
  trigger('rightMenu', [
    transition(':enter', [useAnimation(slideInAnimation, {
      params:
        { propertyFrom: 'translateX(100%)', propertyTo: 'translateX(0)', timing: '0.25s', delay: '0s' }
    })]),
    transition(':leave', [useAnimation(slideOutAnimation, {
      params:
        { propertyFrom: 'translateX(0%)', propertyTo: 'translateX(100%)', timing: '0.25s', delay: '0s' }
    })]),
  ]),
  trigger('show', [
    transition(':enter', [useAnimation(slideInAnimation, {
      params: { propertyFrom: 'translateX(5%)', propertyTo: 'translateX(0)', timing: '0.2s', delay: '0s' }
    })]),
    transition(':leave', [useAnimation(slideOutAnimation, {
      params: { propertyFrom: 'translateX(0%)', propertyTo: 'translateX(5%)', timing: '0.2s', delay: '0s' }
    })]),
  ]),
];

// v2web 左側菜單動畫
export const leftSideMenuAnimation = [
  trigger('toggle', [
    transition(':enter', [
      style({
        'max-height': '0',
        opacity: 0
      }),
      animate('.2s ease-in', style({
        'max-height': '700px',
        opacity: 1
      }))
    ]),
    transition(':leave', [
      style({
        'max-height': '700px',
        opacity: 1
      }),
      animate('.2s ease-in', style({
        'max-height': '0',
        opacity: 0
      }))
    ]),
  ]),
  trigger('myFav', [
    transition(':enter', [
      query('.my-fav-item', [
        style({ opacity: 0, transform: 'translateX(-100px)' }),
        stagger(30, [
          animate('300ms ease-in', style({ opacity: 1, transform: 'none' }))
        ])
      ], { optional: true }),
    ])
  ])
];

// v2web 聊天室動畫大集合
export const chatAnimation = [
  trigger('enterBtn', [
    transition(':enter', [useAnimation(slideInAnimation, {
      params: {
        propertyFrom: 'translateY(100%)', propertyTo: 'translateY(0)', timing: '0.2s', delay: '0.2s'
      }
    })]),
    transition(':leave', [useAnimation(slideOutAnimation, {
      params: {
        propertyFrom: 'translateY(0)', propertyTo: 'translateY(100%)', timing: '0.2s', delay: '0s'
      }
    })]),
  ]),
  trigger(
    'lobbyShowBtn', [
    transition(':enter', [useAnimation(slideInAnimation, {
      params: {
        propertyFrom: 'translateX(-100%)', propertyTo: 'translateX(0)', timing: '0.2s', delay: '0.2s'
      }
    })]),
    transition(':leave', [useAnimation(slideOutAnimation, {
      params: {
        propertyFrom: 'translateX(0)', propertyTo: 'translateX(-100%)', timing: '0.2s', delay: '0s'
      }
    })]),
  ]),
  trigger(
    'lobby', [
    transition(':enter', [useAnimation(slideInAnimation, {
      params: {
        propertyFrom: 'translateY(100%)', propertyTo: 'translateY(0)', timing: '0.2s', delay: '0.2s'
      }
    })]),
    transition(':leave', [useAnimation(fadeOutAnimation, { params: { timing: '0.2s' } })]),
  ]),
  trigger(
    'chatRoom', [
    transition(':enter', [useAnimation(fadeInAnimation, { params: { timing: '0.2s' } })]),
    transition(':leave', [useAnimation(fadeOutAnimation, { params: { timing: '0.2s' } })]),
  ],
  ),
];

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { retry } from 'rxjs/operators';

export class GgTranslateHttpLoader extends TranslateLoader {

  private http: HttpClient;

  private mapper: {[key: string]: string};

  constructor(http: HttpClient, mapper: {[key: string]: string}) {
    super();
    this.http = http;
    this.mapper = mapper;
  }

  getTranslation(lang: string): Observable<any> {
    return new Observable( subscriber => {
      if (!(lang in this.mapper)) {
        subscriber.error(`undefined language file: ${lang}`);
        subscriber.complete();
        return;
      }
      const url = this.mapper[lang];
      this.http.get(url).pipe(retry(2)).subscribe( body => {
        subscriber.next(body);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef } from 'lib';
import { InvestorOrderInfo_ShippingOrderInfoArgs, InvestorShippingOrderInfo, Query } from 'lib/src/types/schema';
import { ConfirmShippingOrderInput } from 'lib/types/schema';
import { CONFIRM_SHIPPING_ORDER_MUTATION, SHIPPING_ORDER_QUERY } from '../../../shared/helpers/api';
import { MissionService } from '../../../shared/services/mission.service';

@Component({
  selector: 'kr-shipping-order-confirm-modal',
  templateUrl: './shipping-order-confirm-modal.component.html',
  styleUrls: ['./shipping-order-confirm-modal.component.scss']
})
export class ShippingOrderConfirmModalComponent implements OnInit {
  @Input() orderId: string;
  order: InvestorShippingOrderInfo;
  showReportOrder = false;
  name = '';
  amount: number;
  isUploading = false;

  constructor(
    private overlayRef: ModalOverlayRef,
    private apollo: Apollo,
    private messageSer: MessageService,
    private missionSer: MissionService
  ) { }

  async ngOnInit() {
    const variables: InvestorOrderInfo_ShippingOrderInfoArgs = {
      input: {
        orderUuid: this.orderId
      }
    }
    const resp = await this.apollo.query<Query>({query: SHIPPING_ORDER_QUERY, variables}).toPromise();
    if (resp.data) {
      this.order = resp.data.me?.orderInfo?.shippingOrderInfo!;
    }
  }

  yes() {
    if (this.name == '' || this.amount == null) {
      this.messageSer.notify('请填写完整');
      return;
    }

    let input: ConfirmShippingOrderInput = {
      orderUuid: this.orderId,
      name: this.name,
      amount: this.amount
    };
    this.apollo.mutate({
      mutation: CONFIRM_SHIPPING_ORDER_MUTATION,
      variables: { input }
    }).subscribe(resp => {
      this.messageSer.notify('已确认收款')
      this.overlayRef.close();
      this.missionSer.shippingOrdersQuery.refetch();
    });
  }

  no() {
    this.overlayRef.close();
  }

  showReportOrderConfirm() {
    this.messageSer.confirm({
      title: '温馨提示',
      text: '若15分钟以上仍未收到款项，请上传相关文件，以便客服人员及时处理',
      onYes: () => {
        this.showReportOrder = true;
      }
    })
  }

  chooseFile(e: any) {
    // const input: ApplyShippingOrderArbitrationInput = {
    //   orderUuid: this.orderId,
    //   files: e.target.files
    // }
    // if (e && e?.target && e?.target?.files) {
    //   this.isUploading = true;
    //   this.apollo.mutate({
    //     mutation: APPLY_SHIPPING_ORDER_ARBITRATION_MUTATION,
    //     variables: {input},
    //     context: {
    //       useMultipart: true
    //     }
    //   }).subscribe({
    //     next: resp => {
    //       this.isUploading = false;
    //       this.messageSer.notify({text: '异常订单回报/上传成功，审核中', type: 'success'});
    //       this.missionSer.ordersQuery.refetch();
    //       this.overlayRef.close();
    //     },
    //     error: error => {
    //       this.isUploading = false;
    //       console.log(error)
    //     }
    //   });
    // }
  }

  resetFile(e: any) {
    if (e) {
      e.target.value = '';
    }
  }

}

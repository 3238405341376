import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CurrencyConfig, InvestorConfig, LevelGroup, Query } from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { INVESTOR_CONFIG_QUERY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class InvestorConfigService {
  configQuery = this.apollo.watchQuery<Query>({
    query: INVESTOR_CONFIG_QUERY
  });

  config = new BehaviorSubject<InvestorConfig | null>(null);
  levelGroup = new BehaviorSubject<LevelGroup | null>(null);
  currencyConfig = new BehaviorSubject<CurrencyConfig | null>(null);
  transferQuotaEnabled = new BehaviorSubject<boolean>(false);
  marketDisplayed = new BehaviorSubject<boolean>(false);
  convertBonusToQuotaEnabled = new BehaviorSubject<boolean>(false);
  canCreateSignupCode = new BehaviorSubject<boolean>(false);
  ready = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo
  ) {
    this.getConfig();
    // this.getCurrencyConfig();
  }

  getConfig() {
    this.configQuery.valueChanges.subscribe(resp => {
      this.config.next(resp.data.config?.investorConfig!);
      this.levelGroup.next(resp.data.me?.financeInfo?.levelGroup!);
      this.currencyConfig.next(resp.data.config?.currencyConfigs[0]!);
      this.transferQuotaEnabled.next(resp.data.me?.settings?.transferQuotaEnabled!);
      this.convertBonusToQuotaEnabled.next(resp.data.me?.settings?.convertBonusToQuotaEnabled!);
      this.marketDisplayed.next(resp.data.me?.settings?.marketDisplayed!);
      this.canCreateSignupCode.next(resp.data.me?.canCreateSignupCode!);
      this.ready.next(true);
    });
  }
  // getCurrencyConfig() {
  //   this.currencyConfigQuery.valueChanges.subscribe(resp => {
  //     this.currencyConfig.next(resp.data.config?.currencyConfigs[0]!);
  //   });
  // }
  
  refetch() {
    this.configQuery.refetch();
  }
}

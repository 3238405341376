import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { MessageService } from 'lib';
import { Mutation } from 'projects/lib/src/types/schema/schema';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  passwordVisible = true;
  confirmedPasswordVisible = true;
  registerForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private router: Router,
    private messageSer: MessageService,
    private authSer: AuthService,
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.queryParams.code ?? ''

    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', this.confirmPasswordValidator],
      invitationCode: [code, Validators.required],
    });
  }

  confirmPasswordValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (this.registerForm.controls.password.value !== this.registerForm.controls.confirmPassword.value) {
      return { pattern: true, error: true };
    }
    return {};
  };

  get f() { return this.registerForm.controls; }

  onSubmit() {
    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].markAsDirty();
      this.registerForm.controls[i].updateValueAndValidity();
    }
    if (this.registerForm.invalid || this.loading) return;

    this.loading = true;
    this.register(this.f.username.value, this.f.password.value, this.f.invitationCode.value).subscribe((res) => {
      this.loading = false;

      if (res.data?.signupInExchangeWebsite?.success) {
        this.messageSer.notify('注册成功', 600);
        if (this.authSer.currentUserValue)
          this.authSer.logout();
        else
          this.router.navigate(['/login']);
      }
    },
      () => {
        this.loading = false;
      })
  }


  register(account: string, password: string, parentAccount: string) {
    const variables = {
      account, password, parentAccount
    }

    return this.apollo.mutate<Mutation>({
      mutation: gql`
      mutation($account: String!, $password: String!, $parentAccount: String!) {
        signupInExchangeWebsite(input: {account: $account, password: $password, parentAccount: $parentAccount}) {
          success
        }
      }
      `, variables
    })
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import Decimal from 'decimal.js';
import { MessageService } from 'lib';
import { InvestorPaymentTypeEnum, OrderTypeEnum } from 'lib/src/types/schema';
import { timer } from 'rxjs';
import { platformConfig } from '../../../platforms/platform';
import { MarketOnlineInvestorComponent } from '../../shared/component/market-online-investor/market-online-investor.component';
import { AuthService } from '../../shared/services/auth.service';
import { MissionService } from '../../shared/services/mission.service';
import { OrderSettingService } from '../../shared/services/order-setting.service';
import { SecurityCheckerService } from '../../shared/services/security-checker.service';
import { WalletService } from '../../shared/services/wallet.service';
import { WebsiteConfigService } from '../../shared/services/website-config.service';

enum TabListEnum {
  Market,
  Order,
}

interface Tab {
  id: TabListEnum | string;
  label: string;
}

interface CheckOption {
  label: string;
  value: InvestorPaymentTypeEnum;
  checked: boolean;
}

@Component({
  selector: 'kr-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('marketOnlineComp') marketOnlineComp!: MarketOnlineInvestorComponent;

  shippingEnabled: boolean = false;
  marketTags: string[] = [];
  checkOptions: CheckOption[] = [];
  allChecked: boolean = false;
  indeterminate: boolean = true;
  investorPaymentTypes: InvestorPaymentTypeEnum[] = [];

  platform = platformConfig.name;
  tabList = TabListEnum;
  tab: Tab[] = [
    { id: TabListEnum.Market, label: '市场' },
    { id: TabListEnum.Order, label: '订单' },
  ];
  logs: string[] = [];
  OrderTypeEnum = OrderTypeEnum;
  pageSize: number;
  showCountdown: boolean = true;
  countdown: number = 10;
  timer = timer(0, 1000).subscribe((n) => {
    if (this.countdown === 0) {
      this.countdown = 10;
      this.marketOnlineComp.query({ page: 1, first: this.pageSize });
    } else {
      this.countdown -= 1;
    }
  });

  constructor(
    private authSer: AuthService,
    public orderSettingSer: OrderSettingService,
    public walletSer: WalletService,
    private securitySer: SecurityCheckerService,
    public missionSer: MissionService,
    public websiteConfig: WebsiteConfigService,
    private messageSer: MessageService,
  ) { }

  get availableQuota() {
    if (this.walletSer.wallet?.value?.quota == null) return
    return new Decimal(this.walletSer.wallet?.value?.quota!).sub(this.walletSer.quota?.value?.retainedQuota!)
  }

  ngOnInit() {
    this.securitySer.validateStrongPassword();
    this.getShippingEnabled();
    this.getCheckOptions();
  }

  getShippingEnabled() {
    this.orderSettingSer.shippingEnabled.subscribe(resp => {
      this.shippingEnabled = resp;
    });
  }

  getCheckOptions() {
    let label: string = '';
    this.websiteConfig.config.subscribe(config => {
      if (config && config.investorAvailableShippingPaymentTypes.length > 0) {
        config.investorAvailableShippingPaymentTypes.forEach(item => {
          if (item === InvestorPaymentTypeEnum.BankAccount) label = '银行卡';
          if (item === InvestorPaymentTypeEnum.Alipay) label = '支付宝';
          if (item === InvestorPaymentTypeEnum.AlipayScan) label = '支付宝扫码';
          this.checkOptions.push({ value: item, label: label, checked: false });
        });
        if (this.checkOptions.length > 0) {
          this.orderSettingSer.activeShippingPaymentTypes.subscribe(paymentTypes => {
            this.checkOptions.forEach(item => {
              item.checked = paymentTypes.includes(item.value);
            });
            this.getMarketTags();
          });
        }
      }
    });
  }

  getMarketTags() {
    this.marketTags = [];
    this.checkOptions.forEach(item => {
      if (item.checked) this.marketTags.push(`${item.label}市场`);
    });
    this.updateSingleChecked();
  }

  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptions = this.checkOptions.map(item => {
        return { ...item, checked: true };
      });
    } else {
      this.checkOptions = this.checkOptions.map(item => {
        return { ...item, checked: false };
      });
    }
  }

  updateSingleChecked(): void {
    if (this.checkOptions.every(item => !item.checked)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.checkOptions.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
    this.setInvestorPaymentTypes();
  }

  nzTagColor() {
    switch(this.platform) {
      case 'pca4':
        return 'blue';
      case 'pca5':
        return 'purple';
    }
  }

  clickSwitch(orderType: OrderTypeEnum) {
    this.updateSingleChecked();
    if (!this.shippingEnabled && !this.indeterminate && !this.allChecked) {
      this.messageSer.notify('请选择市场');
      return;
    }
    this.shippingEnabled = !this.shippingEnabled;
    this.orderSettingSer.toggleOrderEnabled(this.shippingEnabled, orderType, this.investorPaymentTypes);
  }

  setInvestorPaymentTypes() {
    this.investorPaymentTypes = [];
    this.checkOptions.forEach(item => { if (item.checked) this.investorPaymentTypes.push(item.value) });
    this.orderSettingSer.investorShippingPaymentTypes.next(this.investorPaymentTypes);
  }

  /* tslint:disable-next-line:no-any */
  switchTab(id: number): void {
    switch (id) {
      case TabListEnum.Market:
        this.showCountdown = true;
        break;
      case TabListEnum.Order:
        this.showCountdown = false;
        break;
    }
  }

  logout() {
    this.authSer.logout();
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }
}

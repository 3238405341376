import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'timePeroid'
})
export class TimePeroidPipe implements PipeTransform {

  constructor() { }

  transform(value: string | number | Date | dayjs.Dayjs | undefined): any {
    return dayjs(value).format('A h:mm').replace('AM', '上午').replace('PM', '下午');
    // return this.timeSer.format(value, 'A h:mm').replace('AM', '上午').replace('PM', '下午');
  }
}

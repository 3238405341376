import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef } from 'lib';
import { Mutation } from 'lib/src/types/schema';
import { finalize } from 'rxjs/operators';
import { PASSWORD_INFO_QUERY, SET_LOGIN_PASSWORD_MUTATION } from '../../helpers/api';
import { InvestorConfigService } from '../../services/investor-config.service';
import { InvestorService } from '../../services/investor.service';
import { SecurityMechanismService } from '../../services/security-mechanism.service';
import { SmsAuthService } from '../../services/sms-auth.service';
import { Query } from 'lib/types/schema';

@Component({
  selector: 'kr-set-login-password',
  templateUrl: './set-login-password.component.html',
  styleUrls: ['./set-login-password.component.scss']
})
export class SetLoginPasswordComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  showCancel = true;
  isVisible: boolean = true;
  firstLoginPasswordChangedAtAfterFirstLogin: boolean | null = null;

  constructor(
    private overlayRef: ModalOverlayRef,
    public investorSer: InvestorService,
    public investorConfigSer: InvestorConfigService,
    private apollo: Apollo,
    private messageSer: MessageService,
    private formBuilder: FormBuilder,
    public smsAuthSer: SmsAuthService,
    private securityMechanism: SecurityMechanismService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      newPasswordConfirmation: ['', [this.newPasswordConfirmationValidator]],
      authCode: ['', [Validators.required]]
    })
    this.securityMechanism.checkSecurityMechanism();
    this.apollo.query<Query>({
      query: PASSWORD_INFO_QUERY,
      fetchPolicy: 'no-cache'
    }).subscribe((res) => {
      const { isLoginPasswordWeak, firstLoginPasswordChangedAtAfterFirstLogin } = res.data.me?.passwordInfo!;
      this.firstLoginPasswordChangedAtAfterFirstLogin = firstLoginPasswordChangedAtAfterFirstLogin;
      if(firstLoginPasswordChangedAtAfterFirstLogin === null) {
        this.form.removeControl('authCode');
      }
    })
  }

  newPasswordConfirmationValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.form.value.newPassword) {
      return { error: true, pattern: true };
    }
    return {};
  };

  submit() {
    this.loading = true;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    this.apollo.mutate<Mutation>({
      mutation: SET_LOGIN_PASSWORD_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.setLoginPassword?.success) {
          this.messageSer.notify('密码设置成功');
          this.close();
        }
      })
  }

  close() {
    this.resetForm();
    this.overlayRef.close();
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }
}

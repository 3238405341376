import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef, F2eHelper } from 'lib';
import { Mutation, GetAuthenticatorQrCodeResult, BindAuthenticatorInput } from 'lib/src/types/schema';
import { BIND_AUTHENTICATOR_MUTATION, GET_AUTHENTICATOR_QRCODE_MUTATION } from '../../helpers/api';
import { InvestorService } from '../../services/investor.service';

interface DownloadLink {
  label: string;
  url: string;
}

@Component({
  selector: 'kr-set-google-auth',
  templateUrl: './set-google-auth.component.html',
  styleUrls: ['./set-google-auth.component.scss']
})
export class SetGoogleAuthComponent implements OnInit {
  form!: FormGroup;
  authencator: GetAuthenticatorQrCodeResult;
  loading = false;
  isVisible: boolean = true;
  downloadLink: DownloadLink[] = [
    { label: '[Android下载]', url: 'http://www.ddooo.com/softdown/137802.htm'},
    { label: '[IOS下载]', url: 'https://itunes.apple.com/cn/app/google-authenticator/id388497605?mt=8'},
  ];
  secret: string;

  constructor(
    private apollo: Apollo,
    private clipboard: Clipboard,
    private messageSer: MessageService,
    private formBuilder: FormBuilder,
    private overlayRef: ModalOverlayRef,
    private investorSer: InvestorService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      secret: ['', [Validators.required]],
      authCode: ['', [Validators.required]]
    })
    this.apollo.mutate<Mutation>({
      mutation: GET_AUTHENTICATOR_QRCODE_MUTATION
    }).subscribe(resp => {
      this.authencator = resp.data?.investor?.authenticator?.getAuthenticatorQrCode!;
      this.secret = this.authencator?.secret!;
    })
  }
  
  close() {
    this.resetForm();
    this.overlayRef.close();
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }

  submit() {
    this.loading = true;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    this.apollo.mutate<Mutation>({
      mutation: BIND_AUTHENTICATOR_MUTATION,
      variables: { input: this.form.value }
    }).subscribe(resp => {
      if(resp.data?.investor?.authenticator?.bindAuthenticator?.success) {
        this.messageSer.notify('绑定成功');
        this.investorSer.investorInfoQuery.refetch();
        this.close();
      }
    }, err => {
      this.loading = false;
    })
  }

  copy() {
    this.clipboard.copy(this.authencator?.secret);
    this.messageSer.notify('已复制');
  }

  urlLink(url: string) {
    return F2eHelper.openNewWindow(url);
  }
}

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'lib';
import { PaginatorInfo, ShippingOrder, ShippingPaymentTypeEnum } from 'lib/src/types/schema';
import { LanguageService } from '../../../shared/services/language.service';
import { OrderHistoryService } from '../../services/order-history.service';
import { PaymentService } from '../../services/payment.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'kr-shipping-order-history',
  templateUrl: './shipping-order-history.component.html',
  styleUrls: ['./shipping-order-history.component.scss']
})
export class ShippingOrderHistoryComponent implements OnInit {
  orderHistory: ShippingOrder[];
  paginatorInfo: PaginatorInfo;
  currentPage: number;
  pageSize: number;
  pageSizeOptions: number[];
  total: number = 1;
  loading = false;

  constructor(
    private clipbaord: Clipboard,
    private messageSer: MessageService,
    public walletSer: WalletService,
    public lang: LanguageService,
    public paymentSer: PaymentService,
    public orderHistorySer: OrderHistoryService,
  ) { }

  ngOnInit(): void {
    this.currentPage = this.orderHistorySer.currentPage;
    this.pageSizeOptions = this.orderHistorySer.pageSizeOptions;
    this.pageSize = this.pageSizeOptions[0];
    this.orderHistorySer.shippingOrderHistory.subscribe(orderHistory => {
      this.orderHistory = orderHistory!;
    });
    this.orderHistorySer.shippingOrderPaginatorInfo.subscribe(paginatorInfo => {
      this.paginatorInfo = paginatorInfo!;
      this.currentPage = this.paginatorInfo?.currentPage! || 0;
      this.total = this.paginatorInfo?.total! || 0;
    })
  }

  market(data: ShippingOrder): string {
    switch (data.paymentType) {
      case ShippingPaymentTypeEnum.NetEase:
        return '银行卡';
      case ShippingPaymentTypeEnum.AlipayH5:
        return '支付宝';
      default:
        return '';
    }
  }

  payeeInformation(data: ShippingOrder) {
    switch (data.paymentType) {
      case ShippingPaymentTypeEnum.NetEase:
        const payeeBank = this.paymentSer.bankMap$.value[data.payeeBankId || '-']?.name || '';
        return `${payeeBank} ${data.payeeBankBranchName || ''} ${data.payeeBankAccount || ''} ${data.payeeBankAccountName || ''}`;
      case ShippingPaymentTypeEnum.AlipayH5:
        return `${data.payeeAlipayAccount || ''} ${data.payeeAlipayName || ''}`;
      default:
        return '';
    }
  }

  sizeChagne(first: number) {
    this.pageSize = first;
    this.orderHistorySer.getShippingOrder({ page: 1, first: this.pageSize });
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.orderHistorySer.getShippingOrder({ page: this.currentPage, first: this.pageSize });
  }

  copy(text: string) {
    this.clipbaord.copy(text);
    this.messageSer.notify('已複製', 600);
  }
}

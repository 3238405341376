import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var QRCode: any;

@Component({
  selector: 'kr-shared-components-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit, AfterViewInit {
  promoCode: string = '';
  _qrCode: string = '';
  _customWidth: number = 300;

  @Input()
  set qrCode(v: string) {
    this._qrCode = v;
    this.generateQrcode();
  }
  get qrCode() {
    return this._qrCode;
  }

  @Input()
  set customWidth(v: number) {
    this._customWidth = v;
  }
  get customWidth() {
    return this._customWidth;
  }

  @ViewChild('qrcodeImg', { static: false }) qrcodeImg: ElementRef<any> | null = null;

  constructor(
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.activeRoute.snapshot.params.promoCode) {
      const path = 'user-login-page/login-form/register/';
      this.promoCode = window.location.origin + path + this.activeRoute.snapshot.params.promoCode;
    }
  }

  ngAfterViewInit() {
    this.generateQrcode();
  }

  generateQrcode() {
    if (this.qrcodeImg?.nativeElement) {
      new QRCode(this.qrcodeImg.nativeElement, {
        text: this.promoCode ? this.promoCode : this.qrCode,
        // width: this.promoCode ? 300 : 256,
        // height: this.promoCode ? 300 : 256,
        width: this.customWidth,
        height: this.customWidth,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    }
  }
}

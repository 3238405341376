import { Directive, ViewContainerRef, TemplateRef } from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { LayoutService } from '../services/layout.service';

@Directive({
  selector: '[krLayoutTopNav]'
})
export class LayoutTopNavDirective extends CdkPortal {

  constructor(
    public templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    private layoutSer: LayoutService
  ) {
    super(templateRef, viewContainerRef);
    this.layoutSer.topPortal = this;
  }

}

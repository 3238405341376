import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Apollo } from 'apollo-angular';
import { ModalService } from 'lib';
import { EnableInvestorPaymentAccountInput } from 'lib/src/types/schema';
import { PaymentService } from '../../services/payment.service';
import { SecurityMechanismService } from '../../services/security-mechanism.service';
import { ENABLE_PAYMENT_ACCOUNT_MUTATION } from '../../helpers/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ADD_INVESTOR_WITHDRAW_ADDRESS_MUTATION } from '../../helpers/api';

@Component({
  selector: 'kr-withdraw-addresses-management',
  templateUrl: './withdraw-addresses-management.component.html',
  styleUrls: ['./withdraw-addresses-management.component.scss']
})
export class WithdrawAddressesManagementComponent implements OnInit {
  // public payments: Observable<InvestorPaymentAccount[]>;
  // public loading = true;
  isAddingCard = false;
  form!: FormGroup;

  constructor(
    private apollo: Apollo,
    private modalSer: ModalService,
    private securityMechanism: SecurityMechanismService,
    private formBuilder: FormBuilder,
    public paymentSer: PaymentService,
  ) { }

  ngOnInit(): void {
    // this.payments = this.paymentsQuery.valueChanges.pipe(
    //   tap(resp => this.loading = resp.loading),
    //   map(resp => resp.data.me?.paymentAccounts?.slice().sort(x => x ? -1 : 1)!)
    // )
    // this.paymentsQuery.refetch();
    this.paymentSer.updateBankCard();
    this.securityMechanism.checkSecurityMechanism();
    this.form = this.formBuilder.group({
      blockchain: [''],
      address: ['', [Validators.required]],
      authCode: ['', [Validators.required]]
    })
  }

  async enablePayment(input: EnableInvestorPaymentAccountInput, event: MatSlideToggleChange) {
    console.log(input);
    await this.apollo.mutate({
      mutation: ENABLE_PAYMENT_ACCOUNT_MUTATION,
      variables: { input }
    }).toPromise()
      .catch(_err => {
        event.source.checked = !event.source.checked
      });
    this.paymentSer.updateBankCard();
  }

  openAddAccountModal() {
    this.isAddingCard = true;
  }

  closeAddAccountModal() {
    this.isAddingCard = false;
    this.resetForm()
  }

  submitNewBankCardInfo() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) return;
    this.apollo.mutate({
      mutation: ADD_INVESTOR_WITHDRAW_ADDRESS_MUTATION,
      variables: {
        input: {
          walletAddress: this.form.controls.address.value,
          authCode: this.form.controls.authCode.value,
        }
      }
    }).subscribe(resp => {
      this.paymentSer.updateUSDTAddresses();
      this.closeAddAccountModal();
    }, err => {
      console.log(err);
    });
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }
}

import { NoDataComponent } from './component/no-data/no-data.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'lib';
import { InlineSVGModule } from 'ng-inline-svg';
import { OnsenModule } from 'ngx-onsenui';
import { AlertComponent } from './component/alert/alert.component';
import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from './component/card/card.component';
import { ConfirmComponent } from './component/confirm/confirm.component';
import { LoadingComponent } from './component/loading/loading.component';
import { NavTemplateComponent } from './component/nav-template/nav-template.component';
import { NotifierComponent } from './component/notifier/notifier.component';
import { PromptComponent } from './component/prompt/prompt.component';
import { QrcodeComponent } from './component/qrcode/qrcode.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DomChangeDirective } from './directives/dom-change.directive';
import { DragScrollDirective } from './directives/drag-scroll.directive';
import { LayoutTopNavDirective } from './directives/layout-top-nav.directive';
import { NumberMoreThanOneDirective } from './directives/number-more-than-one.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { SwipeForbidScrollDirective } from './directives/swipe-forbid-scroll.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MatButtonModule } from '@angular/material/button';
import { QueryOptionsComponent } from './component/query-options/query-options.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PagePickerComponent } from './component/page-picker/page-picker.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EmptyStateComponent } from './component/empty-state/empty-state.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SetLoginPasswordComponent } from './component/set-login-password/set-login-password.component';
import { MatInputModule } from '@angular/material/input';
import { SetSecurityPasswordComponent } from './component/set-security-password/set-security-password.component';
import { SetNameComponent } from './component/set-name/set-name.component';
import { SetQqAccountComponent } from './component/set-qq-account/set-qq-account.component';
import { SetMobileNumberComponent } from './component/set-mobile-number/set-mobile-number.component';
import { MatSelectModule } from '@angular/material/select';
import { SetPurchaseOrderAmountLimitComponent } from './component/set-purchase-order-amount-limit/set-purchase-order-amount-limit.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NouisliderModule } from 'ng2-nouislider';
import { MatIconModule } from '@angular/material/icon';
import { IdleModalComponent } from './component/idle-modal/idle-modal.component';
import { SetGoogleAuthComponent } from './component/set-google-auth/set-google-auth.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PurchaseOrderInfoModalComponent } from './component/purchase-order-info-modal/purchase-order-info-modal.component';
import { ShippingOrderInfoModalComponent } from './component/shipping-order-info-modal/shipping-order-info-modal.component';
import { ShippingOrderReportModalComponent } from './component/shipping-order-report-modal/shipping-order-report-modal.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatListModule } from '@angular/material/list';
import { WalletBlockComponent } from './component/wallet-block/wallet-block.component';
import { ShippingOrderConfirmModalComponent } from './component/shipping-order-comfirn-modal/shipping-order-confirm-modal.component';
import { ShippingOrderCardComponent } from './component/shipping-order-card/shipping-order-card.component';
import { AdminComponent } from './component/admin/admin.component';
import { AccountSettingComponent } from './component/account-setting/account-setting.component';
import { PaymentAccountsManagementComponent } from './component/payment-accounts-management/payment-accounts-management.component';
import { WithdrawAddressesManagementComponent } from './component/withdraw-addresses-management/withdraw-addresses-management.component';
import { QuotaHistoryComponent } from './component/quota-history/quota-history.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { ShippingOrderHistoryComponent } from './component/shipping-order-history/shipping-order-history.component';
import { TeamProfitComponent } from './component/team-profit/team-profit.component';
import { MemberProfitModalComponent } from './component/team-profit/member-profit-modal/member-profit-modal.component';
import { WithdrawComponent } from './component/withdraw/withdraw.component';
import { RechargeComponent } from './component/recharge/recharge.component';
import { RechargeInfoComponent } from './component/recharge/recharge-info/recharge-info.component';
import { WithdrawQuotaComponent } from './component/withdraw/withdraw-quota/withdraw-quota.component';
import { MatMenuModule } from '@angular/material/menu';
import { QrCodeModule } from 'ng-qrcode';
import { CreateMemberComponent } from './component/create-member/create-member.component';
import { MarketOnlineInvestorComponent } from './component/market-online-investor/market-online-investor.component';
import { OrderInfoComponent } from './component/order-info/order-info.component';
import { PurchaseOrderInfoComponent } from './component/payment/purchase-order-info/purchase-order-info.component';
import { PaymentComponent } from './component/payment/payment.component';
import { NzAntdModule } from './antdModule';

// ant-desing icons
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

// ng-zorro 需要本地語言數據
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { PurchaseOrderHistoryComponent } from './component/purchase-order-history/purchase-order-history.component';
import { OrderHistoryComponent } from './component/order-history/order-history.component';
registerLocaleData(zh);


const material = [
  MatButtonModule,
  MatTabsModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatCardModule,
  ClipboardModule,
  MatSlideToggleModule,
  MatInputModule,
  MatProgressBarModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatSliderModule,
  MatInputModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatToolbarModule,
  ScrollingModule,
  MatListModule,
  // MatDatepickerModule,
]

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    QrcodeComponent,
    CardComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardHeaderComponent,
    AlertComponent,
    ConfirmComponent,
    NotifierComponent,
    PromptComponent,
    DebounceClickDirective,
    AutofocusDirective,
    LoadingComponent,
    DomChangeDirective,
    DragScrollDirective,
    OnlyNumberDirective,
    NavTemplateComponent,
    LayoutTopNavDirective,
    SwipeForbidScrollDirective,
    NumberMoreThanOneDirective,
    NoDataComponent,
    MainLayoutComponent,
    QueryOptionsComponent,
    PagePickerComponent,
    EmptyStateComponent,
    SetLoginPasswordComponent,
    SetSecurityPasswordComponent,
    SetNameComponent,
    SetQqAccountComponent,
    SetMobileNumberComponent,
    SetPurchaseOrderAmountLimitComponent,
    IdleModalComponent,
    SetGoogleAuthComponent,
    PurchaseOrderInfoModalComponent,
    ShippingOrderInfoModalComponent,
    ShippingOrderReportModalComponent,
    WalletBlockComponent,
    ShippingOrderConfirmModalComponent,
    ShippingOrderCardComponent,
    AdminComponent,
    AccountSettingComponent,
    PaymentAccountsManagementComponent,
    WithdrawAddressesManagementComponent,
    QuotaHistoryComponent,
    ShippingOrderHistoryComponent,
    TeamProfitComponent,
    MemberProfitModalComponent,
    WithdrawComponent,
    RechargeComponent,
    RechargeInfoComponent,
    WithdrawQuotaComponent,
    CreateMemberComponent,
    MarketOnlineInvestorComponent,
    OrderInfoComponent,
    PaymentComponent,
    PurchaseOrderInfoComponent,
    PurchaseOrderHistoryComponent,
    OrderHistoryComponent,
  ],
  imports: [
    ...material,
    CommonModule,
    HttpClientModule, HttpClientJsonpModule,
    InlineSVGModule.forRoot(),
    RouterModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    OnsenModule,
    PortalModule,
    PipesModule,
    // TranslateModule,
    DragDropModule,
    NouisliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    QrCodeModule,
    NzAntdModule,
  ],
  exports: [
    ...material,
    HttpClientModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OnsenModule,
    PortalModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    DragDropModule,
    QrcodeComponent,
    CardComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardHeaderComponent,
    AlertComponent,
    ConfirmComponent,
    NotifierComponent,
    PromptComponent,
    DebounceClickDirective,
    AutofocusDirective,
    LoadingComponent,
    DomChangeDirective,
    DragScrollDirective,
    OnlyNumberDirective,
    NavTemplateComponent,
    LayoutTopNavDirective,
    SwipeForbidScrollDirective,
    NumberMoreThanOneDirective,
    NoDataComponent,
    MainLayoutComponent,
    PagePickerComponent,
    EmptyStateComponent,
    QueryOptionsComponent,
    WalletBlockComponent,
    ShippingOrderConfirmModalComponent,
    ShippingOrderCardComponent,
    AdminComponent,
    AccountSettingComponent,
    PaymentAccountsManagementComponent,
    WithdrawAddressesManagementComponent,
    QuotaHistoryComponent,
    ShippingOrderHistoryComponent,
    TeamProfitComponent,
    MemberProfitModalComponent,
    WithdrawComponent,
    RechargeComponent,
    RechargeInfoComponent,
    WithdrawQuotaComponent,
    MatMenuModule,
    QrCodeModule,
    CreateMemberComponent,
    MarketOnlineInvestorComponent,
    OrderInfoComponent,
    PurchaseOrderInfoComponent,
    NzAntdModule,
  ],
  providers: [
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
  entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class SharedModule { }

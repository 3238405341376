import { Component, Input, OnInit } from '@angular/core';
import { ModalOverlayRef } from 'lib';

@Component({
  selector: 'kr-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
  @Input() title: string = '';
  @Input() btnSubmitTitle: string = '';
  @Input() onSubmit: (value: string) => any = () => { };
  @Input() onCancel: () => any = () => { };
  @Input() placeHolder = '';
  @Input() type = '';

  promptInputValue = '';
  isVisible: boolean = true;
  isConfirmLoading: boolean = false;

  constructor(
    public overlayRef: ModalOverlayRef
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.overlayRef.close();
    setTimeout(() => {
      this.onCancel();
    }, 0);
  }

  submit() {
    this.overlayRef.close();
    setTimeout(() => {
      this.onSubmit(this.promptInputValue);
    }, 0);
  }
}

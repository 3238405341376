import { Component, Input, OnInit } from '@angular/core';
import { ModalOverlayRef } from 'lib';
import { IdleDetectionService } from '../../services/idle-detection.service';
import { OrderSettingService } from '../../services/order-setting.service';

@Component({
  selector: 'kr-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss']
})
export class IdleModalComponent implements OnInit {
  isVisible: boolean = true;

  constructor(
    private overlayRef: ModalOverlayRef,
    public idleSer: IdleDetectionService,
    private orderSetting: OrderSettingService 
  ) { }

  ngOnInit(): void {
  }

  yes() {
    // 更新開關狀態
    this.orderSetting.refetchSetting();
    this.close();
  }

  beforeClose() {
  }

  close() {
    this.orderSetting.refetchSetting();
    this.beforeClose();
    this.overlayRef.close();
  }

}

import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {setContext} from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Router } from '@angular/router';
import { createUploadLink } from 'apollo-upload-client';
import { DeviceService, F2eHelper, MessageService } from 'lib';
import { envConfig } from '../environments/environment';
import { EnvType } from 'lib/src/types';
import Echo from 'laravel-echo';
import { createLighthouseSubscriptionLink } from './shared/helpers/lighthouse-subscription-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
// import { createLighthouseSubscriptionLink } from "@thekonz/apollo-lighthouse-subscription-link";

const PUSHER_APP_KEY = envConfig.pusherKey;
const PUSHER_APP_CLUSTER = 'ap3';
console.log({PUSHER_APP_KEY})

let graphqlHost = '';
let echoAuthEndpoint = 'graphql/subscriptions/auth';
let userAccount: string = '';
let assetVer: string = '';

const echoClient = new Echo({
  broadcaster: 'pusher',
  key: PUSHER_APP_KEY,
  cluster: PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: echoAuthEndpoint
});

export const setGraphqlHost = (host: string) => {
  console.log(`host: ${host}`);
  // 同步修正 echoClient 的驗證位置
  echoClient.connector.pusher.config.authEndpoint = `${host}/graphql/subscriptions/auth`;
  graphqlHost = host;
}

export const setGraphqlUser = (account: string) => {
  userAccount = account;
}

export const getHost = (): string => {
  return graphqlHost;
}

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export function generateGraphqlUri(_operation: any) {
  let params: Record<string, string> = {
    ac: userAccount
  };
  params.pf = F2eHelper.isInApp ? 'native' : F2eHelper.isPWA ? 'pwa' : 'h5';  // app 就改為帶入 native/h5/pwa 等參數
  if ((window as any)?.myInfo?.sha) {
    params.v = (window as any)?.myInfo?.sha;
  }
  if (assetVer !== '1.0.0') {
    params.ab = assetVer;
  }
  const queryString = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');

  if (envConfig.envType === EnvType.local) {
    return `${graphqlHost}/graphql?t=${new Date().getTime().toString()}&XDEBUG_SESSION_START=GG&` + queryString;
  } else {
    return `${graphqlHost}/graphql?` + queryString;
  }
};

const LINK_OPTS = {
  uri: generateGraphqlUri
};


// const myhttpLink2 = createUploadLink({
//   uri: (_operation) => {
//     if (envConfig.envType === EnvType.local) {
//       return `${uri}?t=${new Date().getTime().toString()}&XDEBUG_SESSION_START=GG`;
//     } else {
//       return uri;
//     }
//   }
// });

const myhttpLink = ApolloLink.split(
  operation => {
    return operation.getContext().useMultipart
  },
  createUploadLink(LINK_OPTS),
  new BatchHttpLink(LINK_OPTS) as any
)

const errorLink = (router: Router, messageSer: MessageService) => {
  return onError(({ graphQLErrors, networkError, response, operation, forward }) => {
    graphQLErrors?.forEach(graphQLError => {
      console.log(graphQLError);
      messageSer.notify({text: graphQLError.message, type: 'error'});
      if(graphQLError.extensions?.code === 2 || graphQLError.extensions?.category === 'authentication') {
        localStorage.removeItem('currentUser');
        setTimeout(() => {
          (window as any).location = '/login';
        }, 2000)
        // router.navigate(['/login']);
      }
      forward(operation);
    })
  })
};

// export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
//   return {
//     link: httpLink
//   }
// }

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, router: Router, messageSer: MessageService, deviceSer: DeviceService) => {
        assetVer = deviceSer.getAssetVersion();
        return {
          cache: new InMemoryCache(),
          link: authLink
                  .concat(createLighthouseSubscriptionLink(echoClient) as any)
                  .concat(errorLink(router, messageSer))
                  .concat(myhttpLink)
        };
      },
      deps: [HttpLink, Router, MessageService, DeviceService],
    },
  ],
})
export class GraphQLModule {
}

import { Component } from '@angular/core';


@Component({
  selector: 'kr-card-header',
  styleUrls: ['./card-header.component.scss'],
  template: `<ng-content></ng-content>`,
})
export class CardHeaderComponent {
}

@Component({
  selector: 'kr-card-body',
  styleUrls: ['./card-body.component.scss'],
  template: `<ng-content></ng-content>`,
})
export class CardBodyComponent {
}


@Component({
  selector: 'kr-card-footer',
  styleUrls: ['./card-footer.component.scss'],
  template: `<div class="card-footer"><ng-content></ng-content></div>`,
})
export class CardFooterComponent {
}

@Component({
  selector: 'kr-card',
  styleUrls: ['./card.component.scss'],
  template: `
    <ng-content select="kr-card-header"></ng-content>
    <ng-content select="kr-card-body"></ng-content>
    <ng-content></ng-content>
    <ng-content select="kr-card-footer"></ng-content>
  `,
})
export class CardComponent {
}

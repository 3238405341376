import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'kr-page-picker',
  templateUrl: './page-picker.component.html',
  styleUrls: ['./page-picker.component.scss']
})
export class PagePickerComponent implements OnInit, OnDestroy {
  @Input() page = 1;

  private _pages = 1;
  @Input()
  set pages(val: number) {
    this._pages = val;
    this.updatePagesArr();
  }
  get pages() {
    return this._pages;
  }
  pagesArr: number[] = [];

  @Input() total = 0;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  clickSubject$: Subject<boolean> = new Subject();
  clickSubscription: Subscription | undefined;
  constructor() { }

  ngOnInit() {
    this.clickSubscription = this.clickSubject$.pipe(
      debounceTime(300)
    ).subscribe(() => this.pageChanged.emit(this.page));
  }
  ngOnDestroy() {
    this.clickSubscription?.unsubscribe();
  }

  updatePagesArr() {
    this.pagesArr = [];
    for (let i = 1; i <= this.pages; ++i) {
      this.pagesArr.push(i);
    }
  }

  turnToPage(page: number) {
    if (page < 1 || page > this.pages || page === this.page) {
      return;
    }
    this.page = page;
    this.clickSubject$.next(true);
  }

  turnToSpecificPage(event: any) {
    this.page = event.target.value;
    if (this.page < 1) {
      this.page = 1;
    }
    if (this.page > this.pages) {
      this.page = this.pages;
    }
    this.clickSubject$.next(true);
  }

  showPage(i: number) {
    const group = 3;
    const neighbor = Math.floor(group / 2);
    // 當前頁在頭，顯示 group 數量的頁面
    const ruleBegin = this.page < group && i <= group;
    // 當前頁在中，顯示 當前頁跟他的鄰居
    const ruleMid = this.page >= group && Math.abs(this.page - i) <= neighbor;
    // 當前頁在尾，顯示 由後往前算 group 數量的頁面
    const ruleEnd = this.page >= this.pages - neighbor && i >= this.pages - 3 + 1;

    return ruleBegin || ruleMid || ruleEnd;
  }

  showSpread(i: number) {
    const curr = this.page;
    const group = 3;
    const neighbor = Math.floor(group / 2);
    // 當前頁在頭，省略 group 數量之後的頁面
    const ruleBegin = curr < group && i === group + 1;
    // 當前頁在中，省略 當前頁跟他的鄰居之外的頁面
    const ruleMid = curr < this.pages - neighbor && curr >= group && Math.abs(curr - i) === neighbor + 1;
    // 當前頁在尾，省略 由後往前算 group 數量之前的頁面
    const ruleEnd = curr >= this.pages - neighbor && i === this.pages - group;

    return ruleBegin || ruleMid || ruleEnd;
  }
}

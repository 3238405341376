import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'lib';
import { ModalOverlayRef } from 'lib';
import { InvestorService } from '../../services/investor.service';
import { SET_NAME_MUTATION } from '../../helpers/api';
import { finalize } from 'rxjs/operators';
import { Mutation } from 'lib/src/types/schema';

@Component({
  selector: 'kr-set-name',
  templateUrl: './set-name.component.html',
  styleUrls: ['./set-name.component.scss']
})
export class SetNameComponent implements OnInit {
  form = new FormGroup(
    {
      name: new FormControl('', {
        validators: [
          Validators.required,
        ]
      }),
    }
  );
  loading = false;
  name = this.form.controls.name;

  constructor(
    private overlayRef: ModalOverlayRef,
    public investorSer: InvestorService,
    private apollo: Apollo,
    private messageSer: MessageService
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.loading === true) return;
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_NAME_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
    .subscribe(resp => {
      if (resp.data?.investor?.setName?.success) {
        this.messageSer.notify('设置成功');
        this.investorSer.investorInfoQuery.refetch();
        this.close();
      }
    })
  }

  close() {
    this.overlayRef.close();
  }
}

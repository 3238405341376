import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import Decimal from 'decimal.js-light';
import { MessageService } from 'lib';
import { CurrencyTypeEnum, InvestorFinance_WithdrawQuotaArgs, Mutation, Query } from 'lib/src/types/schema';
import { finalize, map } from 'rxjs/operators';
import {  WITHDRAW_BANK_ACCOUNT_QUERY, WITHDRAW_QUOTA_BY_USDT_MUTATION, WITHDRAW_QUOTA_MUTATION } from '../../../helpers/api';
import { InvestorConfigService } from '../../../services/investor-config.service';
import { SmsAuthService } from '../../../services/sms-auth.service';
import { WalletService } from '../../../services/wallet.service';
import { WithdrawQuotaByUsdtInput } from 'lib/types/schema';
import { PaymentService } from '../../../services/payment.service';

@Component({
  selector: 'kr-withdraw-quota',
  templateUrl: './withdraw-quota.component.html',
  styleUrls: ['./withdraw-quota.component.scss']
})
export class WithdrawQuotaComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  loading = false;
  // /**  币种  */
  // currencyType: CurrencyTypeEnum;
  // /**  金额  */
  // amount: Scalars['Float'];
  // /**  收款账号  */
  // investorPaymentAccountId: Scalars['String'];
  // /**  验证码  */
  // authCode?: Maybe<Scalars['String']>;
  form = this.initForm();
  amount = this.form.controls.amount;
  investorPaymentAccountId = this.form.controls.investorPaymentAccountId;
  authCode = this.form.controls.authCode;

  accounts = this.apollo.watchQuery<Query>({
    query: WITHDRAW_QUOTA_BY_USDT_MUTATION,
  }).valueChanges.pipe(map(resp => resp.data.me?.withdrawBankAccounts));

  constructor(
    public smsAuthSer: SmsAuthService,
    private apollo: Apollo,
    private messageSer: MessageService,
    public walletSer: WalletService,
    public investorConfigSer: InvestorConfigService,
    public paymentSer: PaymentService,
  ) {

  }

  ngOnInit(): void {
    
  }

  initForm() {
    return new FormGroup(
      {
        // currencyType: new FormControl(CurrencyTypeEnum.Cny, {
        //   validators: [
        //     Validators.required,
        //   ]
        // }),
        amount: new FormControl('', {
          validators: [
            Validators.required
          ]
        }),
        investorPaymentAccountId: new FormControl('', {
          validators: [
            Validators.required,
          ]
        }),
        authCode: new FormControl('', {
          validators: [
            Validators.required,
          ]
        }),
      }
    );
  }

  submit() {
    if (this.loading === true) return;
    let variables: InvestorFinance_WithdrawQuotaArgs = {
      input: this.form.value
    };
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: WITHDRAW_QUOTA_BY_USDT_MUTATION,
      variables
    }).pipe(finalize(() => this.loading = false))
    .subscribe(resp => {
      if (resp.data?.investor?.finance?.withdrawQuotaByUsdt?.success) {
        this.messageSer.notify('提现成功');
        this.clearForm();
      }
    })
  }

  private clearForm() {
    this.form = this.initForm();
    this.formGroupDirective.resetForm();
  }

  get fee() {

    // 达成业绩收取
    const investorWithdrawQuotaFeePercentWithAchieveAchievement = this.investorConfigSer.config?.value?.investorWithdrawQuotaFeePercentWithAchieveAchievement;
    // 未达业绩标准需提现
    const investorWithdrawQuotaFeePercentWithoutAchieveAchievement = this.investorConfigSer.config?.value?.investorWithdrawQuotaFeePercentWithoutAchieveAchievement;
    // 剩餘業績
    const achievementDeficit = this.walletSer.wallet.value?.achievementDeficit;
    // 最低手續費
    const feeMin = this.investorConfigSer.currencyConfig.value?.investorQuotaWithdrawFeeMin!;
    const quota = this.walletSer.wallet.value?.quota

    if (this.amount.value == '' || this.amount.value == null ||
      investorWithdrawQuotaFeePercentWithAchieveAchievement == null ||
      investorWithdrawQuotaFeePercentWithoutAchieveAchievement == null ||
      achievementDeficit == null) return 0;

    const fee = achievementDeficit! > 0 ?
      new Decimal(this.amount.value).mul(investorWithdrawQuotaFeePercentWithoutAchieveAchievement!).div(100)
      : new Decimal(this.amount.value).mul(investorWithdrawQuotaFeePercentWithAchieveAchievement!).div(100)
    
    return Math.max(fee.toNumber(), feeMin);
  }

}

import { transition, trigger, useAnimation } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { DeviceService, F2eHelper, MessageService, ModalService } from 'lib';
import { slideAnimation } from 'lib/src/animations';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { platformConfig } from '../../../../platforms/platform';
import { SetGoogleAuthComponent } from '../../component/set-google-auth/set-google-auth.component';
import { SetLoginPasswordComponent } from '../../component/set-login-password/set-login-password.component';
import { SetMobileNumberComponent } from '../../component/set-mobile-number/set-mobile-number.component';
import { AppConfigService } from '../../services/app-config.service';
import { AuthService } from '../../services/auth.service';
import { IdleDetectionService } from '../../services/idle-detection.service';
import { InvestorConfigService } from '../../services/investor-config.service';
import { InvestorService } from '../../services/investor.service';
import { LayoutService } from '../../services/layout.service';
import { MissionService } from '../../services/mission.service';
import { NotificationService } from '../../services/notification.service';
import { WalletService } from '../../services/wallet.service';
import { WebsiteConfigService } from '../../services/website-config.service';

enum SiderListEnum {
  Home = 'home',
  Admin = 'admin',
  Payment = 'payment',
  Finance = 'finance',
  History = 'history',
  Team = 'team',
  Recharge = 'recharge',
  Withdraw = 'withdraw',
}

interface Sider {
  id: SiderListEnum | string;
  routerLink: string;
  label: string;
  type: string;
  theme: string;
}

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('routeAnimations', [
      // 由右到左
      transition(':increment', [
        useAnimation(slideAnimation,
          {
            params: {
              opacityTo: 0, opacityFrom: 1, transformTo: 'translateX(8%)',
              transformFrom: 'translateX(0)', timing: '0.2s', delay: '0.15s'
            }
          })
      ]),
      // 由左到右
      transition(':decrement,* => *', [
        useAnimation(slideAnimation,
          {
            params: {
              opacityTo: 0, opacityFrom: 1, transformTo: 'translateX(-8%)',
              transformFrom: 'translateX(0)', timing: '0.2s', delay: '0.15s'
            }
          })
      ]),
    ]),
    trigger('bottom', [
      transition(':enter', [
        useAnimation(slideAnimation, {
          params: {
            opacityTo: 0, opacityFrom: 1, transformTo: 'translateY(100%)',
            transformFrom: 'translateY(0)', timing: '0.15s', delay: '0s'
          }
        })
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            opacityTo: 1, opacityFrom: 0, transformTo: 'translateY(0)',
            transformFrom: 'translateY(100%)', timing: '0.15s', delay: '0s'
          }
        })
      ])
    ])
  ]
})
export class MainLayoutComponent implements OnInit {
  @ViewChild('content') contentRef: ElementRef<any> = null!;

  platform = platformConfig.name;
  mobileQuery: MediaQueryList;
  innerHeight = window.innerHeight;
  siderList = SiderListEnum;
  sider: Sider[] = [
    { id: SiderListEnum.Home, routerLink: '/home', label: '交易中心', type: 'bank', theme: 'fill' },
    { id: SiderListEnum.Admin, routerLink: '/admin', label: '帐户数据', type: 'idcard', theme: 'fill' },
    { id: SiderListEnum.Payment, routerLink: '/payment', label: '代付水池', type: 'credit-card', theme: 'fill' },
    { id: SiderListEnum.Finance, routerLink: '/finance', label: '钱包纪录', type: 'wallet', theme: 'fill' },
    { id: SiderListEnum.History, routerLink: '/history', label: '历史订单', type: 'history', theme: 'outline' },
    { id: SiderListEnum.Team, routerLink: '/team', label: '团队报表', type: 'team', theme: 'outline' },
    { id: SiderListEnum.Recharge, routerLink: '/recharge', label: '充值', type: 'money-collect', theme: 'fill' },
    { id: SiderListEnum.Withdraw, routerLink: '/withdraw', label: '提現', type: 'transaction', theme: 'outline' },
  ];
  isCollapsed: any = false;
  isVisible: boolean = false;

  get pageName(): string {
    let pageName: string = '';
    this.sider.forEach(item => {
      if (item.routerLink === this.router.url) pageName = item.label;
    })
    return pageName!;
  }

  constructor(
    public layoutSer: LayoutService,
    public appConfigSer: AppConfigService,
    public authSer: AuthService,
    public investorSer: InvestorService,
    private modalSer: ModalService,
    private idleDetection: IdleDetectionService,
    public walletSer: WalletService,
    private notificationSer: NotificationService, // ※重要，呼叫推播
    public missionSer: MissionService,
    private deviceSer: DeviceService,
    private websiteConfig: WebsiteConfigService,
    changeDetectorRef: ChangeDetectorRef,
    private messageSer: MessageService,
    private investorConfigSer: InvestorConfigService,
    media: MediaMatcher,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges()
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.checkMobileNumber();
  }

  async ngOnInit() {
    await this.investorConfigSer.ready.pipe(filter(ready => ready), take(1)).toPromise();
    if (this.investorConfigSer.marketDisplayed.value) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/admin']);
    }
  }

  private _mobileQueryListener: () => void;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.isActivated && outlet.activatedRoute && outlet.activatedRouteData.animation;
  }

  private checkMobileNumber() {
    this.authSer.currentUser.pipe(
      filter(user => user != null),
      switchMapTo(this.investorSer.mobileNumber),
      filter(mobileNumber => this.investorSer.ready.value == true && mobileNumber == null),
      take(1)
    ).subscribe(_ => {
      this.showSetMobileNumberModal();
    })
  }

  private showSetMobileNumberModal() {
    this.modalSer.open(SetMobileNumberComponent,
      {
        hasBackdrop: true,
      }
    );
  }

  openCustomerServiceLink() {
    const url = this.websiteConfig.config?.value?.customerServiceUrl!;
    if (F2eHelper.isInApp) {
      this.deviceSer.openBrowser(url);
      return;
    }
    F2eHelper.openNewWindow(url);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleCollapsed(isMobile: boolean): void {
    if (isMobile) {
      this.isVisible = !this.isVisible;
    } else {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  showSiderItem(id: string): boolean {
    switch (id) {
      case SiderListEnum.Home:
        if (this.investorSer.marketDisplayed.value) return true;
        return false;
      case SiderListEnum.Payment:
        if (this.investorSer.purchaseEnabled.value) return true;
        return false;
      case SiderListEnum.Team:
        if (this.investorSer.createChildEnabled.value) return true;
        return false;
      case SiderListEnum.Withdraw:
        return false;
      default:
        return true;
    }
  }

  logout() {
    this.messageSer.confirm({
      title: '温馨提示',
      text: '确认登出？',
      onYes: () => {
        this.authSer.logout();
      }
    })
  }

  showChangeLoginPasswordModal() {
    this.modalSer.open(SetLoginPasswordComponent,
      {
        hasBackdrop: true,
      }
    );
  }

  showGoogleAuthModal() {
    if (this.investorSer.hasAuthenticator.value === true) return;
    this.modalSer.open(SetGoogleAuthComponent,
      {
        hasBackdrop: true,
      }
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeURI'
})
export class DecodeURIPipe implements PipeTransform {

  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    try {
      return decodeURIComponent(value);
    } catch (err) {
      return value;
    }
  }

}

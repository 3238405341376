import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Maybe, Query } from 'projects/lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { setGraphqlUser } from '../../graphql.module';
import { INVESTOR_INFO_QUERY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class InvestorService {
  /* service 初始完成 */
  ready = new BehaviorSubject<boolean>(false);
  /* 帳號 */
  account = new BehaviorSubject<string | null>(null);
  /* 暱稱 */
  name = new BehaviorSubject<string | null>(null);
  /* 手機 */
  mobileNumber = new BehaviorSubject<string | null>(null);
  /* 備用手機 */
  mobileNumber2nd = new BehaviorSubject<string | null>(null);
  /* QQ號 */
  qqAccount = new BehaviorSubject<string | null>(null);
  /* 使否設定交易密碼 */
  hasSecurityPassword = new BehaviorSubject<boolean | null>(null);
  hasAuthenticator = new BehaviorSubject<boolean | null>(null);
  marketDisplayed = new BehaviorSubject<boolean | null>(null);
  purchaseEnabled = new BehaviorSubject<boolean | null>(null);
  createChildEnabled = new BehaviorSubject<boolean | null>(null);
  investorInfoQuery = this.apollo.watchQuery<Query>({
    query: INVESTOR_INFO_QUERY
  });
  constructor(
    private apollo: Apollo
  ) {
    this.getInvestorInfo();
  }

  getInvestorInfo() {
    this.investorInfoQuery.valueChanges.subscribe(resp => {
      if (resp.data == null) return;
      const me = resp.data.me;
      this.account.next(me?.account!);
      setGraphqlUser(me!.account);
      this.mobileNumber.next(me?.mobileNumber!);
      this.name.next(me?.name!);
      this.mobileNumber2nd.next(me?.mobileNumber2nd!);
      this.qqAccount.next(me?.qqAccount!);
      this.hasSecurityPassword.next(me?.hasSecurityPassword!);
      this.hasAuthenticator.next(me?.hasAuthenticator!);
      this.marketDisplayed.next(me?.settings?.marketDisplayed!);
      this.purchaseEnabled.next(me?.settings?.purchaseEnabled!);
      this.createChildEnabled.next(me?.settings?.createChildEnabled!);
      this.ready.next(true);
    });
  }
}

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { DeviceService, MessageService, ModalOverlayRef, ModalService } from 'lib';
import { ConfirmShippingOrderInput, InvestorOrderInfo_ShippingOrderInfoArgs, InvestorShippingOrderInfo, Query, ReportShippingOrderProblemInput, ShippingOrder, ShippingOrderProblemTypeEnum } from 'lib/src/types/schema';
import { EnumItem } from 'projects/lib/src/types/schema';
import { CONFIRM_SHIPPING_ORDER_MUTATION, REPORT_SHIPPING_ORDER_PROBLEM_MUTATION, SHIPPING_ORDER_PROBLEM_TYPE_QUERY, SHIPPING_ORDER_QUERY } from '../../helpers/api';
import { LanguageService } from '../../services/language.service';
import { MissionService } from '../../services/mission.service';
import { ShippingOrderReportModalComponent } from '../shipping-order-report-modal/shipping-order-report-modal.component';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'kr-shipping-order-info-modal',
  templateUrl: './shipping-order-info-modal.component.html',
  styleUrls: ['./shipping-order-info-modal.component.scss']
})
export class ShippingOrderInfoModalComponent implements OnInit {
  @Input() order: ShippingOrder;
  orderInfo: InvestorShippingOrderInfo;
  name = '';
  amount: number;

  showReportOrder = false;
  isUploading = false;
  previewImage: SafeUrl;
  problemImage: File;
  problem: ShippingOrderProblemTypeEnum;
  problemRemark: string;
  problems?: EnumItem[];

  constructor(
    private overlayRef: ModalOverlayRef,
    private clipboard: Clipboard,
    private messageSer: MessageService,
    public lang: LanguageService,
    private apollo: Apollo,
    private missionSer: MissionService,
    private modalSer: ModalService,
    private sanitizer: DomSanitizer,
    private deviceSer: DeviceService,
    public paymentSer: PaymentService,
  ) { }

  async ngOnInit() {
    
    this.getOrder();
    const resp = await this.apollo.query<Query>({query: SHIPPING_ORDER_PROBLEM_TYPE_QUERY, fetchPolicy: 'no-cache'}).toPromise();
    if (resp.data) {
      this.problems = resp.data.enums?.shippingOrderProblemType;
    }
    
  }

  private async getOrder() {
    const variables: InvestorOrderInfo_ShippingOrderInfoArgs = {
      input: {
        orderUuid: this.order._id
      }
    }
    const resp = await this.apollo.query<Query>({query: SHIPPING_ORDER_QUERY , variables, fetchPolicy: 'no-cache'}).toPromise();
    if (resp.data) {
      this.orderInfo = resp.data.me?.orderInfo?.shippingOrderInfo!;
    }
  }

  async yes() {
    if (this.name === '' || this.amount == null) {
      this.messageSer.notify('请填写完整');
      return;
    }
    const smsStr = await this.deviceSer.getSMS(Math.floor(Date.parse(this.order.createdAt) / 1000));

    let input: ConfirmShippingOrderInput = {
      orderUuid: this.order._id,
      name: this.name,
      amount: Number(this.amount),
      mobileMessage: smsStr
    };
    this.apollo.mutate({
      mutation: CONFIRM_SHIPPING_ORDER_MUTATION,
      variables: { input }
    }).subscribe(resp => {
      this.messageSer.notify('已确认收款')
      this.overlayRef.close();
      this.missionSer.shippingOrdersQuery.refetch();
    });
  }

  no() {
    this.overlayRef.close();
  }

  copy(text: string) {
    this.clipboard.copy(text);
    this.messageSer.notify('已複製', 600);
  }

  report() {
    this.modalSer.open(ShippingOrderReportModalComponent, {}, c => {

    })
  }
  
  close() {
    this.overlayRef.close();
  }

  showReportOrderConfirm() {
    this.messageSer.confirm({
      title: '温馨提示',
      text: '若金额错误、打款人错误、支付方式错误、或长时间未收到款项，请上传相关文件，以便客服人员及时处理',
      onYes: () => {
        this.showReportOrder = true;
      }
    })
  }

  chooseFile(e: any) {
    this.problemImage = e.target.files[0];
    this.previewImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e.target.files[0]));
  }

  reportProblem() {
    const input: ReportShippingOrderProblemInput = {
      orderUuid: this.order._id,
      problemType: this.problem,
      files: [this.problemImage],
      remark: this.problemRemark
    }
    if (this.problem == null || this.problemImage == null) {
      this.messageSer.notify('请填写完整');
      return;
    }
    this.isUploading = true;
    this.apollo.mutate({
      mutation: REPORT_SHIPPING_ORDER_PROBLEM_MUTATION,
      variables: {input},
      context: {
        useMultipart: true
      }
    }).subscribe({
      next: resp => {
        this.isUploading = false;
        this.messageSer.notify({text: '异常订单回报/上传成功，审核中', type: 'success'});
        this.missionSer.shippingOrdersQuery.refetch();
        this.overlayRef.close();
      },
      error: error => {
        this.isUploading = false;
        console.log(error)
      }
    });
  }

  resetFile(e: any) {
    if (e) {
      e.target.value = '';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { F2eHelper, MessageService, isCompressionImageNotContainIOSQuark } from 'lib';
import { Bank, InvestorAlipayPaymentAccountStatusEnum, InvestorAlipayScanAccountStatusEnum, InvestorPaymentBankAccountStatusEnum, InvestorPaymentTypeEnum } from 'lib/src/types/schema';
import { ADD_INVESTOR_ALIPAY_PAYMENT_ACCOUNT_MUTATION, ADD_INVESTOR_ALIPAY_SCAN_ACCOUNT_MUTATION, ADD_INVESTOR_PAYMENT_ACCOUNT_MUTATION, ENABLE_ALIPAY_PAYMENT_ACCOUNT_MUTATION, ENABLE_ALIPAY_SCAN_ACCOUNT_MUTATION, ENABLE_PAYMENT_ACCOUNT_MUTATION } from '../../helpers/api';
import { InvestorConfigService } from '../../services/investor-config.service';
import { PaymentService } from '../../services/payment.service';
import { SecurityMechanismService } from '../../services/security-mechanism.service';
import { SmsAuthService } from '../../services/sms-auth.service';
import { WebsiteConfigService } from '../../services/website-config.service';

interface PaymentList {
  type: InvestorPaymentTypeEnum;
  label: string;
}

@Component({
  selector: 'kr-payment-accounts-management',
  templateUrl: './payment-accounts-management.component.html',
  styleUrls: ['./payment-accounts-management.component.scss']
})
export class PaymentAccountsManagementComponent implements OnInit {
  paymentsType = InvestorPaymentTypeEnum;
  paymentsList: PaymentList[] = [];

  isAddingCard: boolean = false;
  bnakForm!: FormGroup;
  paymentStatus = InvestorPaymentBankAccountStatusEnum;
  showDelete: boolean = false;
  bankName: string;
  filteredOptions: Bank[] = [];

  isAddAlipayCard: boolean = false;
  alipayForm!: FormGroup;
  alipayStatus = InvestorAlipayPaymentAccountStatusEnum;
  alipayShowDelete: boolean = false;

  isAddAlipayScanCard: boolean = false;
  alipayScanForm!: FormGroup;
  alipayScanStatus = InvestorAlipayScanAccountStatusEnum;
  alipayScanShowDelete: boolean = false;
  problemImage: File | Blob | null;
  previewImage: SafeUrl;
  isUploadDisabled = false;

  isShowQRCode: boolean = false;
  qrcodeUrl: string = '';

  constructor(
    private apollo: Apollo,
    private securityMechanism: SecurityMechanismService,
    private messageSer: MessageService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private investorConfigSer: InvestorConfigService,
    public paymentSer: PaymentService,
    public smsAuthSer: SmsAuthService,
    public websiteConfig: WebsiteConfigService,
  ) { }

  ngOnInit(): void {
    this.updateCardList(InvestorPaymentTypeEnum.BankAccount);
    this.updateCardList(InvestorPaymentTypeEnum.Alipay);
    this.updateCardList(InvestorPaymentTypeEnum.AlipayScan);
    this.securityMechanism.checkSecurityMechanism();

    this.bnakForm = this.formBuilder.group({
      bankAccountName: ['', [this.bankAccountNameValidator]],
      bankAccount: ['', [this.bankAccountValidator]],
      bankId: [undefined, [this.bankIdValidator]],
      bankBranchName: ['', [this.bankAccountNameValidator]],
      authCode: ['', [Validators.required]],
    });

    this.alipayForm = this.formBuilder.group({
      alipayName: ['', [this.alipayNameValidator]],
      alipayAccount: ['', [this.alipayAccountValidator]],
      authCode: ['', [Validators.required]],
    });

    this.alipayScanForm = this.formBuilder.group({
      alipayName: ['', [this.alipayScanNameValidator]],
      alipayUid: ['', [this.alipayScanUIDValidator]],
      authCode: ['', [Validators.required]],
    });

    this.websiteConfig.config.subscribe(resp => {
      let label: string = '';
      if (resp && resp.investorAvailableShippingPaymentTypes) {
        resp.investorAvailableShippingPaymentTypes.forEach(item => {
          switch (item) {
            case InvestorPaymentTypeEnum.BankAccount:
              label = '银行卡帐户';
              break;
            case InvestorPaymentTypeEnum.Alipay:
              label = '支付宝帐户';
              break;
            case InvestorPaymentTypeEnum.AlipayScan:
              label = '支付宝收款码';
              break;
            default:
              return;
          }
          this.paymentsList.push({ type: item, label: label });
        });
      }
    });
  }

  bankAccountNameValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!this.isChinese(control.value)) {
      return { pattern: true, error: true };
    }
    return {};
  };

  bankAccountValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!F2eHelper.regBankCardAccount.test(control.value)) {
      return { pattern: true, error: true };
    }
    return {};
  };

  bankIdValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!this.haveBank(control.value)) {
      return { error: true, pattern: true };
    }
    return {};
  }

  alipayNameValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!this.isChinese(control.value)) {
      return { pattern: true, error: true };
    }
    return {};
  }

  alipayAccountValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!F2eHelper.regAlipayAccount.test(control.value)) {
      return { pattern: true, error: true };
    }
    return {};
  }

  alipayScanNameValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (!this.isChinese(control.value)) {
      return { pattern: true, error: true };
    }
    return {};
  }

  alipayScanUIDValidator = (control: FormControl) => {
    if (!this.problemImage) {
      if (!control.value) {
        return { error: true, required: true };
      } else if (!F2eHelper.regAlipayScanUIDAccount.test(control.value)) {
        return { pattern: true, error: true };
      }
    }
    return {};
  }

  isChinese(input: string): boolean {
    const chinesePattern = /^[\u4e00-\u9fa5]+$/;
    return chinesePattern.test(input);
  }

  haveBank(input: string): boolean {
    let haveBank: boolean = false;
    if (this.filteredOptions) {
      this.filteredOptions.forEach(item => {
        if (input === item.name) haveBank = true;
      })
    }
    return haveBank;
  }

  updateCardList(type: InvestorPaymentTypeEnum) {
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        this.paymentSer.updateBankCard();
        break;
      case InvestorPaymentTypeEnum.Alipay:
        this.paymentSer.updateAlipayCard();
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        this.paymentSer.updateAlipayScanCard();
        break;
      default:
        return;
    }
  }

  // 启用
  async enablePayment(switchStatus: boolean, paymentAccountId: string, type: InvestorPaymentTypeEnum) {
    const input = {
      paymentAccountId,
      enabled: switchStatus,
    }
    let mutation;
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        mutation = ENABLE_PAYMENT_ACCOUNT_MUTATION;
        break;
      case InvestorPaymentTypeEnum.Alipay:
        mutation = ENABLE_ALIPAY_PAYMENT_ACCOUNT_MUTATION;
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        mutation = ENABLE_ALIPAY_SCAN_ACCOUNT_MUTATION;
        break;
    }
    await this.apollo.mutate({
      mutation: mutation,
      variables: { input }
    }).toPromise()
      .catch(err => {
        console.log(err);
      });
    this.updateCardList(type);
  }

  openAddBankAccountModal(type: InvestorPaymentTypeEnum) {
    this.showDelete = false;
    this.alipayShowDelete = false;
    this.alipayScanShowDelete = false;
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        this.isAddingCard = true;
        this.filteredOptions = this.paymentSer.bankOptions;
        break;
      case InvestorPaymentTypeEnum.Alipay:
        this.isAddAlipayCard = true;
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        this.isAddAlipayScanCard = true;
        break;
      default:
        return;
    }
  }

  closeAddBankAccountModal(type: InvestorPaymentTypeEnum) {
    this.isAddAlipayScanCard = false;
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        this.isAddingCard = false;
        break;
      case InvestorPaymentTypeEnum.Alipay:
        this.isAddAlipayCard = false;
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        this.isAddAlipayScanCard = false;
        break;
      default:
        return;
    }
    this.resetForm(type)
  }

  deleteCard(type: InvestorPaymentTypeEnum) {
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        this.alipayShowDelete = false;
        this.alipayScanShowDelete = false;
        this.showDelete = !this.showDelete;
        break;
      case InvestorPaymentTypeEnum.Alipay:
        this.showDelete = false;
        this.alipayScanShowDelete = false;
        this.alipayShowDelete = !this.alipayShowDelete;
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        this.showDelete = false;
        this.alipayShowDelete = false;
        this.alipayScanShowDelete = !this.alipayScanShowDelete;
        break;
      default:
        return;
    }
  }

  // 刪除
  deletePaymentAccount(paymentAccountId: string, type: InvestorPaymentTypeEnum) {
    let deleteText: string = '確認刪除銀行卡？';
    if (type === InvestorPaymentTypeEnum.Alipay) deleteText = '確認刪除支付宝？';
    if (type === InvestorPaymentTypeEnum.AlipayScan) deleteText = '確認刪除支付宝收款码？';
    this.messageSer.confirm({
      title: '溫馨提示',
      text: deleteText,
      onYes: async () => {
        switch (type) {
          case InvestorPaymentTypeEnum.BankAccount:
            this.paymentSer.deletePaymentAccount(paymentAccountId).subscribe(() => {
              this.showDelete = !this.showDelete;
            });
            break;
          case InvestorPaymentTypeEnum.Alipay:
            this.paymentSer.deleteAlipayPaymentAccount(paymentAccountId).subscribe(() => {
              this.alipayShowDelete = !this.alipayShowDelete;
            });
            break;
          case InvestorPaymentTypeEnum.AlipayScan:
            this.paymentSer.deleteAlipayScanAccount(paymentAccountId).subscribe(() => {
              this.alipayScanShowDelete = !this.alipayScanShowDelete;
            });
            break;
          default:
            return;
        }
        this.updateCardList(type);
      }
    });
  }

  // 新增
  submitNewBankCardInfo(type: InvestorPaymentTypeEnum) {
    let mutation;
    let input;

    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        for (const i in this.bnakForm.controls) {
          this.bnakForm.controls[i].markAsDirty();
          this.bnakForm.controls[i].updateValueAndValidity();
        }
        if (this.bnakForm.invalid) return;
        this.setFormBankId();
        mutation = ADD_INVESTOR_PAYMENT_ACCOUNT_MUTATION;
        input = { input: this.bnakForm.value };
        break;
      case InvestorPaymentTypeEnum.Alipay:
        for (const i in this.alipayForm.controls) {
          this.alipayForm.controls[i].markAsDirty();
          this.alipayForm.controls[i].updateValueAndValidity();
        }
        if (this.alipayForm.invalid) return;
        mutation = ADD_INVESTOR_ALIPAY_PAYMENT_ACCOUNT_MUTATION;
        input = { input: this.alipayForm.value };
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        for (const i in this.alipayScanForm.controls) {
          this.alipayScanForm.controls[i].markAsDirty();
          this.alipayScanForm.controls[i].updateValueAndValidity();
        }
        if (this.alipayScanForm.invalid) return;
        mutation = ADD_INVESTOR_ALIPAY_SCAN_ACCOUNT_MUTATION;
        input = { input: { ...this.alipayScanForm.value, qrcodeImage: this.problemImage } };
        break;
      default:
        return;
    }

    this.apollo.mutate({
      mutation: mutation,
      variables: input,
      context: {
        useMultipart: true
      }
    }).subscribe(resp => {
      this.updateCardList(type);
      this.closeAddBankAccountModal(type);
    }, err => {
      console.log(err);
    });
  }

  setFormBankId() {
    this.filteredOptions.forEach(item => {
      if (item.name === this.bankName) this.bnakForm.value.bankId = item.id;
    })
  }

  resetForm(type: InvestorPaymentTypeEnum): void {
    switch (type) {
      case InvestorPaymentTypeEnum.BankAccount:
        this.bnakForm.reset();
        for (const key in this.bnakForm.controls) {
          this.bnakForm.controls[key].markAsPristine();
          this.bnakForm.controls[key].updateValueAndValidity();
        }
        break;
      case InvestorPaymentTypeEnum.Alipay:
        this.alipayForm.reset();
        for (const key in this.alipayForm.controls) {
          this.alipayForm.controls[key].markAsPristine();
          this.alipayForm.controls[key].updateValueAndValidity();
        }
        break;
      case InvestorPaymentTypeEnum.AlipayScan:
        this.isUploadDisabled = false;
        this.resetFile();
        this.alipayScanForm.reset();
        for (const key in this.alipayScanForm.controls) {
          this.alipayScanForm.controls[key].markAsPristine();
          this.alipayScanForm.controls[key].updateValueAndValidity();
        }
        break;
      default:
        return;
    }
  }

  onChange(value: string): void {
    if (value) {
      this.filteredOptions = this.paymentSer.bankOptions.filter(option => option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.filteredOptions = this.paymentSer.bankOptions;
    }
  }

  async chooseFile(e: any) {
    if (isCompressionImageNotContainIOSQuark(this.investorConfigSer.config?.value?.isCompressImageBeforeUpload!)) {
      const compressionImage: Blob = await F2eHelper.compressionUploadImage(e.target.files[0]);
      if (compressionImage) this.problemImage = compressionImage;
    } else {
      this.problemImage = e.target.files[0];
    }

    this.previewImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e.target.files[0]));
    this.alipayScanForm.get('alipayUid')!.disable();
  }

  resetFile(e?: any) {
    if (e) {
      e.target.value = '';
    } else {
      this.problemImage = null;
      this.previewImage = '';
      this.alipayScanForm.get('alipayUid')!.enable();
    }
  }

  checkChooseOne(event: any) {
    this.isUploadDisabled = false;
    if (event.target.value) this.isUploadDisabled = true;
  }

  showQRCode(qrcodeUrl: string) {
    this.isShowQRCode = !this.isShowQRCode;
    this.qrcodeUrl = qrcodeUrl;
  }

  closeShowQRCode() {
    this.isShowQRCode = !this.isShowQRCode;
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { InvestorHistory_PurchaseOrderHistoryArgs, InvestorHistory_ShippingOrderHistoryArgs, Maybe, PaginatorInfo, PurchaseOrder, Query, ShippingOrder } from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { PURCHASE_ORDER_HISTORY, SHIPPING_ORDER_HISTORY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {
  shippingOrderHistory = new BehaviorSubject<ShippingOrder[] | undefined>(undefined);
  shippingOrderPaginatorInfo = new BehaviorSubject<PaginatorInfo | undefined>(undefined);

  purchaseOrderHistory = new BehaviorSubject<PurchaseOrder[] | undefined>(undefined);
  purchaseOrderPaginatorInfo = new BehaviorSubject<PaginatorInfo | undefined>(undefined);

  currentPage: number = 1;
  pageSize: number;
  pageSizeOptions: number[] = [10, 20];

  constructor(
    private apollo: Apollo,
  ) { }

  getShippingOrder(args: InvestorHistory_ShippingOrderHistoryArgs = {}) {
    args = {
      page: this.currentPage,
      first: this.pageSize ? this.pageSize : this.pageSizeOptions[0],
      ...args,
    };
    this.apollo.query<Query>({
      query: SHIPPING_ORDER_HISTORY,
      variables: args,
      fetchPolicy: 'no-cache'
    }).subscribe(resp => {
      this.shippingOrderHistory.next(resp.data.me?.histories?.shippingOrderHistory?.data);
      this.shippingOrderPaginatorInfo.next(resp.data.me?.histories?.shippingOrderHistory!.paginatorInfo!);
    });
  }

  getPurchaseOrder(args: InvestorHistory_PurchaseOrderHistoryArgs) {
    args = {
      page: this.currentPage,
      first: this.pageSize ? this.pageSize : this.pageSizeOptions[0],
      ...args,
    };
    this.apollo.query<Query>({
      query: PURCHASE_ORDER_HISTORY,
      variables: args,
      fetchPolicy: 'no-cache'
    }).subscribe(resp => {
      this.purchaseOrderHistory.next(resp.data.me?.histories?.purchaseOrderHistory?.data!);
      this.purchaseOrderPaginatorInfo.next(resp.data.me?.histories?.purchaseOrderHistory!.paginatorInfo!);
    });
  }
}
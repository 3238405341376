import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';
import { flatMap, pairwise, takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[ggDragScroll]'
})
export class DragScrollDirective implements OnDestroy {
  private subscription: Subscription;
  constructor(el: ElementRef) {
    const elem = el.nativeElement;
    elem.style.userSelect = 'none';
    this.subscription = fromEvent(elem, 'mousedown').pipe(
      flatMap(e => fromEvent(document, 'mousemove').pipe(
        throttleTime(0, animationFrame),
        pairwise(), takeUntil(fromEvent(document, 'mouseup')))
      ),
    )
      .subscribe((e: [MouseEvent | any, MouseEvent | any]) => {
        elem.scrollBy(e[0].pageX - e[1].pageX, e[0].pageY - e[1].pageY);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

import { Route, Router } from '@angular/router';
import { BehaviorSubject, from } from 'rxjs';
import { bufferCount, filter } from 'rxjs/operators';
import {v4 as uuid4} from 'uuid';
import { Query } from '../types/schema';
import { BrowserType, DeviceType, RedirectWindowConfig } from '../types';
import Decimal from 'decimal.js-light';
import { Maybe } from '../types/schema';
import { Type } from '@angular/core';
import Compressor from 'compressorjs';

export class F2eHelper {
  // environment varriables
  static isPlatformBrowser = true;
  static isInApp = false;
  static environment = 'prod';
  static isCrossSiteMode = false;

  static ob = new BehaviorSubject(null);

  static regPositiveNumber = new RegExp(`^[1-9][0-9]*$`);
  static regPositiveFloat = new RegExp(`^[1-9][0-9]*[\.]?[0-9]{0,3}$`);
  static regChineseWord = new RegExp(`^[\u4E00-\u9FA5A-z.•·]+$`);
  static regBankCardAccount = new RegExp(`^\\d{12,30}$`);
  static regAlipayScanUIDAccount = new RegExp(`^\\d{16}$`);
  static regEmail = new RegExp(`^[A-Za-z0-9._%-]+@([A-Za-z0-9-]+.)+[A-Za-z]{2,4}$`);
  static regAlipayAccount = new RegExp(`^[0-9]*$|^[A-Za-z0-9._%-]+@([A-Za-z0-9-]+.)+[A-Za-z]{2,4}$`); // 手机号和邮箱

  // 載 angular 編譯 lib 時, static 屬性的 attr/method, 不允許使用 /^$/ 形式撰寫寫 regexp, 或是 lamba 形式的匿名涵式寫法
  // 錯誤訊息: Metadata collected contains an error that will be reported at runtime: Expression form not supported.
  // 錯誤範例:
  //   EX. static xxx = /^\d+$/;
  //   Ex. static yyy() { return () => {}; }
  // 調整方式:
  //   static xxx = new RegExp('^\\d+$');
  //   static yyy() { const f = () => {}; return f; }

  static getMobileOperatingSystem(): DeviceType {
    if (F2eHelper.isPlatformBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return DeviceType.WindowsPhone;
      }
      if (/android/i.test(userAgent)) {
        return DeviceType.Android;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return DeviceType.IOS;
      }
    }
    return DeviceType.Unknown;
  }

  static getBrowserSystem(): BrowserType {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/Quark/i.test(userAgent)) {
      return BrowserType.Quark;
    }
    return BrowserType.Unknown;
  }

  static secToHHMMSS(secs: number): string {
    if (secs <= 0) { return '00:00:00'; }
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;
    return [hours, minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .join(':');
  }

  // static mappingLanguage(data: Query, keySet: { [name: string]: string }): QueryWithLang | null {
  //   if (!data) {
  //     return null;
  //   }
  //   const props = Object.keys(data);
  //   if (!props.length) {
  //     return null;
  //   }
  //   const [prop] = props.filter(item => item !== 'Language');

  //   return {
  //     [prop]: this.translateLanguage((data as any)[prop], data.Language!, keySet)
  //   };
  // }

  // static translateLanguage(
  //   data: { [name: string]: any },
  //   language: Language,
  //   keySet: { [name: string]: string }
  // ): any {
  //   const dictionary: { [name: string]: { [name: string]: string } } = this.makeDictionary(language);
  //   const newData = this.recursiveDeepCopy(data); // JSON.parse(JSON.stringify(data));
  //   this.lookUpDictionary(newData, dictionary, keySet);
  //   return newData;
  // }

  static getUUID4(): string {
    return uuid4();
  }

  static getF2eWindowMyInfo(): {
    date: string;
    sha: string;
  } {
    if (!F2eHelper.isPlatformBrowser || !(window as any)['myInfo']) {
      return {
        date: '',
        sha: ''
      };
    }
    return (window as any)['myInfo'];
  }

  static async retryFunc(times: number, waitingMillisecond: number, fun: () => boolean | Promise<boolean>): Promise<void> {
    if (times <= 0) {
      return;
    }
    const bo = await fun();
    if (bo) {
      return;
    }
    await F2eHelper.sleep(waitingMillisecond);
    const t = times - 1;
    await F2eHelper.retryFunc(t, waitingMillisecond, fun);
  }

  static async sleep(waitingMillisecond: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, waitingMillisecond);
    });
  }


  static createRange(n: number): number[] {
    const items: number[] = [];
    for (let i = 0; i < n; i++) {
      items.push(i);
    }
    return items;
  }

  static replaceAsURL(url: string, host: string): string {
    if (url.startsWith('javascript:')) {
      return url;
    }
    try {
      const tmp = new URL(url);
      return url;
    } catch (e) {
      if (url.startsWith('/')) {
        return `${host}${url}`;
      } else {
        return `${host}/${url}`;
      }
    }
  }

  static openNewWindow(target: string | RedirectWindowConfig): void {
    if (F2eHelper.isPlatformBrowser) {
      if (typeof (target) === 'string') {
        window.open(target, '_blank', '');  // 'location=yes,scrollbars=yes,status=yes'
      } else {
        const isBlank = target.isBlank === false ? '_self' : '_blank';
        const features = target.openTab === false ? (target.strWindowFeatures || 'location=yes,scrollbars=yes,status=yes') : '';
        const win = window.open(target.url, isBlank, features);
        if (target.openTab !== false) {
          win?.focus();
        }
      }
    }
  }

  static openNewWindowWithRelativeUrl(relativeUrl: string): void {
    const fullUrl = `/${relativeUrl}`;
    if (F2eHelper.isPlatformBrowser) {
      window.open(fullUrl, '_blank', 'location=yes,scrollbars=yes,status=yes');
    }
  }

  static openNewWindowWitOptions(url: string, w: number, h: number, left = 0, top = 0): void {
    if (F2eHelper.isPlatformBrowser) {
      window.open(url, '_blank', `location=yes,scrollbars=yes,status=yes,width=${w},height=${h},left=${left},top=${top}`);
    }
  }

  static redirectWithRouter(url: string, router: Router): void {
    const location = window.location.origin;
    const newUrl = new URL(url, location);
    const params: any = {};
    if (newUrl.search) { // 如果有帶搜尋引數
      newUrl.searchParams.forEach((value, key) => {
        params[key] = value;
      });
    }
    router.navigate([newUrl.pathname], { queryParams: params });
  }

  static windowRedirectUrl(url: string, extra?: { hash: string }): void {
    if (F2eHelper.isPlatformBrowser) {
      if (extra && extra.hash) {
        url = `${url}#${extra.hash}`;
      }

      if (`${location.pathname}${location.search}${location.hash}` === url) {
        return;
      }

      location.href = url;
    }
  }

  static recursiveDeepCopy<T = any>(o: any): T {
    let newO: any;
    if (typeof o !== 'object') {
      return o;
    }
    if (!o) {
      return o;
    }

    if ('[object Array]' === Object.prototype.toString.apply(o)) {
      newO = [];
      for (let i = 0; i < o.length; i += 1) {
        newO[i] = this.recursiveDeepCopy(o[i]);
      }
      return newO;
    }

    newO = {};
    for (const i in o) {
      if (o.hasOwnProperty(i)) {
        newO[i] = this.recursiveDeepCopy(o[i]);
      }
    }
    return newO;
  }

  // 将原本的 Language kv 转成 object 的 kv
  // example: {k: "All", v: "全部"} conver to {All: "全部}
  // 并新增到当前的 object
  // private static makeDictionary(language: Language): {} {
  //   const dictionary: any = {};
  //   for (const prop in language) {
  //     if (language[prop as keyof typeof language] && Array.isArray(language[prop as keyof typeof language])) {
  //       dictionary[prop] = {};
  //       language[prop as keyof typeof language]?.forEach((item) => dictionary[prop][item?.k!] = item?.v);
  //     }
  //   }
  //   // console.log(dictionary);
  //   return dictionary;
  // }

  // data[prop]: string array => 查找语言包
  // data[prop]: object(array) => 往下深度遍历
  // data[prop]: string => 查找语言包
  private static lookUpDictionary(
    /*
      data 是 query 传回来的资料
      dictionary 是转换后的语言物件
      keySet 要转换的资料，key 为资料栏位名称， value 为语系 type 栏位名称
     */
    data: { [name: string]: any },
    dictionary: { [name: string]: { [name: string]: string } },
    keySet: { [name: string]: string }
  ): void {
    for (const prop in data) {
      if (!data[prop]) {
        continue;
      }

      if (Array.isArray(data[prop]) && data[prop].length > 0 && (typeof data[prop][0] === 'string' || typeof data[prop][0] === 'number')) {
        data[prop] = data[prop].map((a: any) => a.toString());
        const dictKey = keySet[prop]; // 欲转换的语系类型在语系中的名称(因为栏位名称可能与语系中的栏位名称不同)
        const dictKeySet = dictionary[dictKey]; // 转换后的语系中指定特定语系类型

        if (dictKey && dictKeySet) {
          data[prop] = data[prop].map((item: any) => ({
            option: item,
            display: dictKeySet[item]
          }));
          // console.log(data);
        }
      } else if (typeof data[prop] === 'object') {
        this.lookUpDictionary(data[prop], dictionary, keySet);
      } else if (keySet[prop]) {
        const dictKey = keySet[prop];
        const dictKeySet = dictionary[dictKey];
        if (dictKey && dictKeySet) {
          data[`${prop}_lang`] = dictKeySet[data[prop].toString()];
        }
      }
    }
  }

  // static ggMoneyFormat(value: number | string, digit = 3) {
  //   // null, undefined, '' => 0
  //   if (!value) {
  //     console.warn(`Invalid input: (${typeof value}) ${value}`);
  //     return 0;
  //   }
  //   // filter comma, ex. 11,111 => 11111
  //   const num = value.toString().split(',').join('');
  //   // 參考精度12可以解決大多數問題 https://github.com/camsong/blog/issues/9
  //   const prec12 = (val: number | string) => Number(Number(val).toPrecision(12));
  //   const formatBase = Math.pow(10, digit);
  //   return prec12(Math.floor(prec12(prec12(num) * formatBase)) / formatBase);
  // }

  static ggMoneyFormat(value: number | string, digit = 3) {
    // null, undefined, '' => 0
    if (value === null || value === undefined) {
      console.warn(`Invalid input: (${typeof value}) ${value}`);
      return 0;
    }

    // filter comma, ex. 11,111 => 11111
    if (typeof value === 'string') {
      value = value.replace(/[+,]/g, '');
    }
    try {
      return new Decimal(value).toDecimalPlaces(digit, Decimal.ROUND_DOWN).toNumber();
    } catch (e) {
      console.warn(`Invalid input: (${value}): ${e.message}`);
      return 0;
    }
  }

  static thousandSeprator(num: number): string {
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  }

  static moneyStringToNumber(str = ''): number {
    return Number(str.split(',').join('')) || 0;
  }

  static padLeft(str: string, lenght: number, paddingChar = '0'): string {
    if (str.length >= lenght) {
      return str;
    } else {
      return this.padLeft(paddingChar + str, lenght, paddingChar);
    }
  }

  static addAnimateCSS(elem: any, animationName: string, callback = () => { }) {
    elem.classList.add('animated', animationName);

    function handleAnimationEnd(): void {
      elem.classList.remove('animated', animationName);
      elem.removeEventListener('animationend', handleAnimationEnd);
      callback();
    }

    elem.addEventListener('animationend', handleAnimationEnd);
  }

  // 過濾物件中無效成員
  static filterEmptyProperty<T>(object: T & {[key: string]: any}): T {
    for (const prop of Object.keys(object)) {
      // null, undefined, ''
      if ((!object[prop] && typeof object[prop] !== 'boolean') || object[prop] === 'null') {
        delete object[prop];
      }
      // [], [null, undefined, '']
      if (Array.isArray(object[prop]) && (object[prop].length === 0 || object[prop].filter((elem: any) => !!elem).length === 0)) {
        delete object[prop];
      }
    }
    return object;
  }

  /** 遲來的將來, 先來一杯咖啡, 再吧! 等到 testMethod 回傳 true */
  static async waitingFor(testMethod: () => any, timeoutMillis: number, frequencyMillis: number = 10): Promise<boolean> {
    const delay = frequencyMillis < 1 ? 10 : frequencyMillis;
    const timeoutMillisTs = Date.now() + timeoutMillis;
    while (Date.now() < timeoutMillisTs) {
      if (testMethod() === true) {
        return true;
      }
      if (Date.now() + delay > timeoutMillisTs) {
        return false;
      }
      await new Promise<void>(r => setTimeout(() => r(), delay));
    }
    return false;
  }

  static makeStepArray(val1: string | number, val2: string | number, step: string | number) {
    const [numVal1, numVal2, numStep] = [Number(val1), Number(val2), Number(step)];
    const [min, max] = [Math.min(numVal1, numVal2), Math.max(numVal1, numVal2)];
    const length = Math.round((max - min) / numStep) + 1;
    return [...new Array(length)].map((_value, index) => Number((index * numStep + min).toPrecision(12)));
  }

  static recursiveRouteComponentReplace(
    r: Route,
    componentFactory: { [name: string]: any },
    originSelectorName: string,
    targetSelectorName: string
  ): void {
    if (r.children && r.children.length > 0) {
      for (const cr of r.children) {
        F2eHelper.recursiveRouteComponentReplace(cr, componentFactory, originSelectorName, targetSelectorName);
      }
    }
    if (
      r.component &&
      (r.component as any)['identityName'] === originSelectorName
    ) {
      const t = componentFactory[targetSelectorName];
      r.component = t;
    }
  }

  static get isPWA() {
    return !!(navigator && (navigator as any)['standalone']);
  }

  // 壓縮圖片
  static compressionUploadImage = async (file: File): Promise<any> => {
    return new Promise<Blob>((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        convertSize: 10000,
        success: resolve,
        error: reject,
      });
    }).catch((err) => {
      console.error("Compress error: " + err.message);
    });
  }
}

export const deepClone = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
}

export function isCompressionImageNotContainIOSQuark(configSwitchBoolean: boolean = true): boolean {
  const isApple = F2eHelper.getMobileOperatingSystem() === DeviceType.IOS;
  const isQuark = F2eHelper.getBrowserSystem() === BrowserType.Quark;

  return configSwitchBoolean && !(isApple && isQuark);
}
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ModalService } from 'lib';
import { Query } from 'lib/types/schema';
import { PASSWORD_INFO_QUERY } from '../helpers/api';
import { SetLoginPasswordComponent } from '../component/set-login-password/set-login-password.component';

@Injectable({ providedIn: 'root' })
export class SecurityCheckerService {

    constructor(
      private apollo: Apollo,
      private modalSer: ModalService,
    ) {
    }

    validateStrongPassword() {
      this.apollo.query<Query>({
        query: PASSWORD_INFO_QUERY,
        fetchPolicy: 'no-cache'
      }).subscribe((res) => {
        const { isLoginPasswordWeak, firstLoginPasswordChangedAtAfterFirstLogin } = res.data.me?.passwordInfo!;
        if (firstLoginPasswordChangedAtAfterFirstLogin === null || isLoginPasswordWeak === true) {
          this.modalSer.open(SetLoginPasswordComponent,
            {
              hasBackdrop: true,
              width: '100%',
            },
            c => {
              c.showCancel = false;
            }
          );
        }
      })
    }
}
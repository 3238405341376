import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Investor as User, LoginResult, Maybe } from 'lib/src/types/schema';
import { Apollo, gql } from 'apollo-angular';
import { LOGOUT_MUTATION } from '../helpers/api';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<Maybe<User>>;
    public currentUser: Observable<Maybe<User>>;

    constructor(private apollo: Apollo) {
        this.currentUserSubject = new BehaviorSubject<Maybe<User>>(JSON.parse(localStorage.getItem('currentUser')!));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Maybe<Maybe<User>> {
        return this.currentUserSubject.value;
    }

    login(account: string, password: string, authCode?: string) {
      return this.apollo.mutate<{login: LoginResult}>({
        mutation: gql`
          mutation($account: String!, $password: String!, $authCode: String) {
            login(input: {account: $account, password: $password, authCode: $authCode}) {
              token
              investor {
                id
                account
              }
            }
          }
        `,
        variables: {account, password, authCode},
        errorPolicy: 'all',
      }).pipe(
        map(resp => {
          if (resp.errors) {
            return resp
          }
          localStorage.setItem('currentUser', JSON.stringify(resp.data?.login.investor));
          localStorage.setItem('token', resp.data?.login.token!);
          this.currentUserSubject.next(resp.data?.login.investor || null);
          return resp
        },
      ))
    }

    async logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        await this.apollo.mutate({mutation: LOGOUT_MUTATION}).toPromise();
        this.currentUserSubject.next(null);
        (window as any).location = '/login';
    }
}
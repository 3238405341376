import { Component, OnInit } from '@angular/core';
import { MessageService } from 'lib';
import { CurrencyTypeEnum, InvestorPaymentTypeEnum, OrderTypeEnum } from 'lib/src/types/schema';
import { MissionService } from '../../services/mission.service';
import { OrderSettingService } from '../../services/order-setting.service';
import { WalletService } from '../../services/wallet.service';
import { WebsiteConfigService } from '../../services/website-config.service';
import { PurchaseBadge } from './purchase-order-info/purchase-order-info.component';

export enum TabListEnum {
  PurchaseOrder,
  MyPurchaseOrder,
}

export interface Tab {
  id: TabListEnum;
  label: string;
}

interface CheckOption {
  label: string;
  value: InvestorPaymentTypeEnum;
  checked: boolean;
}

@Component({
  selector: 'kr-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  purchaseEnabled: boolean = false;
  marketTags: string[] = [];
  checkOptions: CheckOption[] = [];
  allChecked: boolean = false;
  indeterminate: boolean = true;
  investorPaymentTypes: InvestorPaymentTypeEnum[] = [];

  min?: number = 0;
  max?: number = 0;
  tabList = TabListEnum;
  tab: Tab[] = [
    { id: TabListEnum.PurchaseOrder, label: '代付水池' },
    { id: TabListEnum.MyPurchaseOrder, label: '我的代付' },
  ];
  purchaseOrdersCount: number | string;
  showPurchaseBadge: boolean = false;
  myPurchaseOrdersCount: number | string;
  showMyPurchaseBadge: boolean = false;
  OrderTypeEnum = OrderTypeEnum;

  constructor(
    public walletSer: WalletService,
    public orderSettingSer: OrderSettingService,
    public missionSer: MissionService,
    private websiteConfig: WebsiteConfigService,
    private messageSer: MessageService,
  ) { }

  ngOnInit(): void {
    switch(this.walletSer.currencyType!) {
      case CurrencyTypeEnum.Cny:
        this.walletSer.wallet.subscribe(resp => {
          this.min = resp?.purchaseOrderAmountMin || 0;
          this.max = resp?.purchaseOrderAmountMax || 0;
        });
        break;
      case CurrencyTypeEnum.Usdt:
        this.walletSer.usdtWallet.subscribe(resp => {
          this.min = resp?.purchaseOrderAmountMin || 0;
          this.max = resp?.purchaseOrderAmountMax || 0;
        });
        break;
    }
    this.getPurchaseEnabled();
    this.getCheckOptions();
  }

  getPurchaseEnabled() {
    this.orderSettingSer.purchaseEnabled.subscribe(resp => {
      this.purchaseEnabled = resp;
    });
  }

  getCheckOptions() {
    let label: string = '';
    this.websiteConfig.config.subscribe(config => {
      if (config && config.investorAvailablePurchasePaymentTypes) {
        config.investorAvailablePurchasePaymentTypes.forEach(item => {
          if (item === InvestorPaymentTypeEnum.BankAccount) label = '银行卡';
          if (item === InvestorPaymentTypeEnum.Alipay) label = '支付宝';
          if (item === InvestorPaymentTypeEnum.AlipayScan) label = '支付宝扫码';
          this.checkOptions.push({ value: item, label: label, checked: false });
        });
        if (this.checkOptions.length > 0) {
          this.orderSettingSer.activePurchasePaymentTypes.subscribe(paymentTypes => {
            this.checkOptions.forEach(item => {
              item.checked = paymentTypes.includes(item.value);
            });
            this.getMarketTags();
          });
        }
      }
    });
  }

  getMarketTags() {
    this.marketTags = [];
    this.checkOptions.forEach(item => {
      if (item.checked) this.marketTags.push(`${item.label}市场`);
    });
    this.updateSingleChecked();
  }

  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptions = this.checkOptions.map(item => {
        return { ...item, checked: true };
      });
    } else {
      this.checkOptions = this.checkOptions.map(item => {
        return { ...item, checked: false };
      });
    }
  }

  updateSingleChecked(): void {
    if (this.checkOptions.every(item => !item.checked)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.checkOptions.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
    this.setInvestorPaymentTypes();
  }

  edit(event: any, orderType: OrderTypeEnum) {
    this.orderSettingSer.showSetPurchaseOrderAmountLimit(event, orderType, []);
  }

  clickSwitch(orderType: OrderTypeEnum) {
    this.updateSingleChecked();
    if (!this.purchaseEnabled && !this.indeterminate && !this.allChecked) {
      this.messageSer.notify('请选择市场');
      return;
    }
    this.purchaseEnabled = !this.purchaseEnabled;
    this.orderSettingSer.toggleOrderEnabled(this.purchaseEnabled, orderType, this.investorPaymentTypes);
  }

  setInvestorPaymentTypes() {
    this.investorPaymentTypes = [];
    this.checkOptions.forEach(item => { if (item.checked) this.investorPaymentTypes.push(item.value) });
    this.orderSettingSer.investorPurchasePaymentTypes.next(this.investorPaymentTypes);
  }

  /* tslint:disable-next-line:no-any */
  switchTab(id: number): void {
    switch (id) {
      case TabListEnum.PurchaseOrder:
        break;
      case TabListEnum.MyPurchaseOrder:
        break;
    }
  }

  purchaseCount(purchaseBadge: PurchaseBadge) {
    this.purchaseOrdersCount = purchaseBadge.countDisplay;
    this.showPurchaseBadge = purchaseBadge.isShowBadge;
  }

  myPurchaseCount(myPurchaseBadge: PurchaseBadge) {
    this.myPurchaseOrdersCount = myPurchaseBadge.countDisplay;
    this.showMyPurchaseBadge = myPurchaseBadge.isShowBadge;
  }
}

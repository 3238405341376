import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef } from 'lib';
import { Mutation } from 'lib/src/types/schema';
import { finalize } from 'rxjs/operators';
import { SET_SECURITY_PASSWORD_MUTATION } from '../../helpers/api';
import { InvestorService } from '../../services/investor.service';
import { SmsAuthService } from '../../services/sms-auth.service';

@Component({
  selector: 'kr-set-security-password',
  templateUrl: './set-security-password.component.html',
  styleUrls: ['./set-security-password.component.scss']
})
export class SetSecurityPasswordComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  isVisible: boolean = true;
  hasSecurityPassword: boolean;

  constructor(
    private overlayRef: ModalOverlayRef,
    public investorSer: InvestorService,
    private apollo: Apollo,
    private messageSer: MessageService,
    public smsAuthSer: SmsAuthService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.investorSer.hasSecurityPassword.subscribe(data => {
      this.hasSecurityPassword = data!;
    });
    this.form = this.formBuilder.group({
      oldPassword: ['', [...(this.hasSecurityPassword === true ? [Validators.required] : [])]],
      newPassword: ['', [Validators.required]],
      newPasswordConfirmation: ['', [this.newPasswordConfirmationValidator]],
      authCode: ['', [Validators.required]]
    });
  }

  newPasswordConfirmationValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.form.value.newPassword) {
      return { error: true, pattern: true };
    }
    return {};
  };

  submit() {
    if (this.loading === true) return;
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_SECURITY_PASSWORD_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.setSecurityPassword?.success) {
          this.messageSer.notify('密码设置成功');
          // 更新 hasTransactionPassword 欄位
          this.investorSer.hasSecurityPassword.next(true);
          this.close();
        }
      })
  }

  close() {
    this.overlayRef.close();
  }
}

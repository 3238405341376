import { Subscription } from 'rxjs';
import { BaseObservableComponent } from './base-observable-component.component';

declare module 'rxjs/internal/Subscription' {
  interface Subscription {
    toUnsubscription(
      this: Subscription,
      boc: BaseObservableComponent
    ): void;
  }
}
Subscription.prototype.toUnsubscription =
  function( this: Subscription, boc: BaseObservableComponent ): void {
    boc.sub.add(this);
  };

import { Component, Input } from '@angular/core';
import { WalletService } from '../../../shared/services/wallet.service';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'kr-wallet-block',
  templateUrl: './wallet-block.component.html',
  styleUrls: ['./wallet-block.component.scss']
})
export class WalletBlockComponent {
  Decimal = Decimal;

  constructor(
    public walletSer: WalletService
  ) { }

  get availableQuota() {
    if (this.walletSer.wallet?.value?.quota == null) return 0
    return new Decimal(this.walletSer.usdtWallet?.value?.quota!)
      .sub(this.walletSer.usdtQuota?.value?.retainedQuota!)
      .toNumber()
  }

  get usdt() {
    return new Decimal(this.walletSer.usdtWallet.value?.quota ?? 0).toNumber()
    // (this.walletSer.wallet | async)?.quota! * (walletSer.rechargeExchangeRate.value!)
  }

  get cny() {
    return new Decimal(this.walletSer.usdtWallet.value?.quota ?? 0).mul(this.walletSer.defaultGatewayRate.value ?? 0).toNumber()
  }

  get inProgress() {
    if (this.walletSer.wallet.value?.purchasingAmount == null) return
    return new Decimal(this.walletSer.wallet.value?.purchasingAmount!)
      .add(this.walletSer.wallet.value?.shippingAmount!)
      .add(this.walletSer.wallet.value?.frozenQuota!)
      .add(this.walletSer.wallet.value?.frozenBonus!);

  }

  get frozen() {
    // (shippingAmount + purchasingAmount + frozenQuota + frozenBonus) / defaultGatewayRate + (frozenQuotaUSDT + frozenBonusUSDT)
    const cnyFrozen = new Decimal(this.walletSer.wallet.value?.shippingAmount ?? 0)
      .add(this.walletSer.wallet.value?.purchasingAmount ?? 0)
      .add(this.walletSer.wallet.value?.frozenQuota ?? 0)
      .add(this.walletSer.wallet.value?.frozenBonus ?? 0)
      .div(this.walletSer.defaultGatewayRate.value ?? 0)
    const usdtFrozen = new Decimal(this.walletSer.usdtWallet.value?.frozenQuota ?? 0)
      .add(this.walletSer.usdtWallet.value?.frozenBonus ?? 0)

    return cnyFrozen.add(usdtFrozen).toNumber()
  }

  refresh() {
    this.walletSer.refetchWallets();
  }
}
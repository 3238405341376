import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef } from 'lib';
import { CurrencyTypeEnum, Mutation, SetPurchaseOrderAmountLimitInput } from 'lib/src/types/schema';
import { finalize } from 'rxjs/operators';
import { SET_PURCHASE_ORDER_AMOUNT_LIMIT } from '../../helpers/api';
import { WalletService } from '../../services/wallet.service';
import { WebsiteConfigService } from '../../services/website-config.service';

@Component({
  selector: 'kr-set-purchase-order-amount-limit',
  templateUrl: './set-purchase-order-amount-limit.component.html',
  styleUrls: ['./set-purchase-order-amount-limit.component.scss']
})
export class SetPurchaseOrderAmountLimitComponent implements OnInit {
  isVisible: boolean = true;
  loading = false;
  isDisabled: boolean = false;
  form!: FormGroup;
  currencyType: CurrencyTypeEnum;
  amountMin: number;
  amountMax: number;
  purchaseOrderAmountMin?: number;
  purchaseOrderAmountMax?: number;
  amountRange: string;

  constructor(
    public walletSer: WalletService,
    private overlayRef: ModalOverlayRef,
    private apollo: Apollo,
    private messageSer: MessageService,
    private formBuilder: FormBuilder,
    private websiteConfig: WebsiteConfigService,
  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      amountMin: ['', [this.amountMinValidator]],
      amountMax: ['', [this.amountMaxValidator]],
    });
    // 重新拿进货最大订单金额、进货最小订单金额限值
    await this.walletSer.refetchWallets();
    this.currencyType = this.walletSer.currencyType!;
    // 判断币别
    switch(this.currencyType) {
      case CurrencyTypeEnum.Cny:
        this.amountMin = this.form.value.amountMin = this.walletSer.wallet.value?.purchaseOrderAmountMin!;
        this.amountMax = this.form.value.amountMax = this.walletSer.wallet.value?.purchaseOrderAmountMax!;
        this.purchaseOrderAmountMin = this.walletSer.quota?.value?.purchaseOrderAmountMin!;
        this.purchaseOrderAmountMax = this.walletSer.quota?.value?.purchaseOrderAmountMax!;
        break;
      case CurrencyTypeEnum.Usdt:
        this.amountMin = this.form.value.amountMin = this.walletSer.usdtWallet.value?.purchaseOrderAmountMin!;
        this.amountMax = this.form.value.amountMax = this.walletSer.usdtWallet.value?.purchaseOrderAmountMax!;
        this.purchaseOrderAmountMin = this.walletSer.usdtQuota?.value?.purchaseOrderAmountMin!;
        this.purchaseOrderAmountMax = this.walletSer.usdtQuota?.value?.purchaseOrderAmountMax!;
        break;
    }
    this.amountRange = `${this.purchaseOrderAmountMin} ~ ${this.purchaseOrderAmountMax}`;
    this.onCheck();
  }

  amountMinValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (Number(control.value) < Number(this.purchaseOrderAmountMin)) {
      return { error: true, pattern: true };
    } else if (Number(control.value) > Number(this.form.value.amountMax)) {
      return { error: true, maxlength: true };
    }
    return {};
  };

  amountMaxValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (Number(control.value) > Number(this.purchaseOrderAmountMax!)) {
      return { error: true, pattern: true };
    } else if (Number(control.value) < Number(this.form.value.amountMin)) {
      return { error: true, minlength: true };
    }
    return {};
  };

  onCheck() {
    this.isDisabled = true;
    if (Number(this.form.value.amountMin) && Number(this.form.value.amountMax) && (Number(this.form.value.amountMax) > Number(this.form.value.amountMin))) this.isDisabled = false;
  }

  submit() {
    if (this.loading === true) return;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) return;
    const input: SetPurchaseOrderAmountLimitInput = {
      currencyType: this.currencyType,
      amountMax: Number(this.form.value.amountMax),
      amountMin: Number(this.form.value.amountMin),
    }
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_PURCHASE_ORDER_AMOUNT_LIMIT,
      variables: { input }
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.setPurchaseOrderAmountLimit?.success) {
          this.messageSer.notify('设置成功');
          this.walletSer.refetchWallets();
          this.afterSuccess();
          this.close();
        }
      })
  }

  afterSuccess() { }

  afterCancel() { }

  cancel(): void {
    this.afterCancel();
    this.overlayRef.close();
  }

  close(): void {
    this.overlayRef.close();
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ggNumberMoreThanOne]'
})
export class NumberMoreThanOneDirective {

  constructor(private el: ElementRef) { }
  @HostListener('blur', ['$event'])
  onBlur(e: any) {
    // 避免輸入為空或輸入開頭為0的數值
    const value = e.target['value'];
    e.target['value'] = value === '' || parseFloat(value) < 1 ? 1 : parseFloat(value);

    /*
      避免 input value 改變沒有更新至 twoway binding 或 reactive form
      參考: https://stackoverflow.com/questions/36639486/angular2-manually-firing-click-event-on-particular-element
    */
    const changeEvent: Event = document.createEvent('Event');
    changeEvent.initEvent('input', true, true);
    Object.defineProperty(changeEvent, 'target', { value: this.el.nativeElement, enumerable: true });
    this.el.nativeElement.dispatchEvent(changeEvent);
  }

}

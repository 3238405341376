export default {
  CoreModule: {
    messageSer: {
      defaultTitle: '温馨提示',
      defaultCloseBtn: '关闭',
      defaultConfirmBtn: '确定',
      defaultSubmitBtn: '提交'
    },
  },
};

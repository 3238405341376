import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Config, Query } from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { CONFIG_QUERY } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  configQuery = this.apollo.watchQuery<Query>({
    query: CONFIG_QUERY
  });
  config = new BehaviorSubject<Config | null>(null);

  constructor(
    private apollo: Apollo
  ) {
    this.getConfig();
  }

  getConfig() {
    this.configQuery.valueChanges.subscribe(resp => {
      this.config.next(resp.data.config!);
    });
  }
  
  refetch() {
    this.configQuery.refetch();
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MessageCode, NotificationTempService } from '../../services/notification-temp.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild('notificationTemp') notificationTempRef!: TemplateRef<any>;

  constructor(
    public notificationTempService: NotificationTempService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.notificationTempService.initTemplate(MessageCode.Notification, this.notificationTempRef);
  }

}

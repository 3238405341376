import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AudioService, ConfigService, DeviceService, GgTranslateHttpLoader, MessageService, ModalService, MyErrorHandler, MyLogService, StorageService } from 'lib';
import { envConfig } from '../environments/environment';
import { platformConfig } from '../platforms/platform';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { LoginComponent } from './shared/components/login/login.component';
import { InitializerService } from './shared/services/initializer.service';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './ui/home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { FinanceComponent } from './ui/home/finance/finance.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { RegisterComponent } from './ui/register/register.component';
import { NotificationComponent } from './shared/component/notification/notification.component';

const moment = _rollupMoment || _moment;

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  const mapper = {
    'zh-cn': require('../assets/i18n/app/zh-cn.json').default,
    en: require('../assets/i18n/app/en.json').default,
  };
  return new GgTranslateHttpLoader(http, mapper);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FinanceComponent,
    RegisterComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    GraphQLModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    MatDatepickerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: MyErrorHandler
    },
    {
      provide: ConfigService,
      useFactory: () => {
        return new ConfigService(envConfig, platformConfig);
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerSer: InitializerService) => () => appInitializerSer.initApp(),
      deps: [InitializerService],
      multi: true
    },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    { provide: StorageService },
    { provide: DeviceService },
    { provide: AudioService },
    { provide: MyLogService },
    { provide: ModalService },
    { provide: MessageService },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY/MM/DD',
        },
        display: {
          dateInput: 'YYYY/MM/DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export enum EnvType {
  none = 0,
  local,
  dev,
  staging,
  prod,
}

export enum VisionType {
  web,
  app
}

export enum VisionModeType {
  none = '',
  h5 = 'h5',
  native = 'native',
  pwa = 'pwa'
}

export enum MyLoggerLevelEnum {
  info,
  warn,
  error,
}

export enum enumCommandKey {
  none = 0,
  passAItemValue,
  userInfo,

  showCustomerServiceButtom,

  showModalConfirm,
  showModalReload,
  showModalGameProcessor,

  showMOdalBandCardAdding,
  reloadBandCardList,

  showModalTest,
  showLotteryrConfrimModal,
  showLotteryrTwoSideConfrimModal,

  getUserInfo,

  showModalFinanceDetails,
  dynamicModal,
  dynamicSlideInPage,
  loading,
}

export enum DeviceType {
  Android = 'Android',
  WindowsPhone = 'windows phone',
  IOS = 'iOS',
  Unknown = 'unknown'
}

export enum BrowserType {
  Quark = 'Quark',
  Unknown = 'unknown',
}

export enum LocalStorageKey {
  Domain = 'domains',
  UserInfo = 'userInfo',
  MemoryGameType = 'MemoryGameType', // web 記憶玩法
  QuickAmount = 'quickAmount',
  DeviceId = 'deviceId',
  GeoipCache = 'geoipCache',
  UserSkin = 'userSkin',
  UserEditMenu = 'userEditMenu',
  MyMemoryGameTypeNewApp = 'MyMemoryGameTypeNewApp', // v2-app 收藏玩法
  MemoryNewApp = 'MemoryNewApp', // app 記憶玩法
  SavePassword = 'savePassword',
  ThemeApp = 'ThemeApp',
  MemoryBetGameTypeNewApp = 'MemoryBetGameTypeNewApp' // v2-app 投注過記憶玩法
}

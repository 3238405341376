import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { ClientPurchaseOrderStatusEnum, ClientShippingOrderStatusEnum, InvestorHistory_PurchaseOrderHistoryArgs, InvestorHistory_ShippingOrderHistoryArgs, Maybe } from 'lib/src/types/schema';
import { LanguageService } from '../../services/language.service';
import { OrderHistoryService } from '../../services/order-history.service';

export enum TabListEnum {
  ShippingOrderHistory,
  PurchaseOrderHistory,
}

export interface Tab {
  id: TabListEnum;
  label: string;
}

@Component({
  selector: 'kr-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  status?: Maybe<ClientPurchaseOrderStatusEnum & ClientShippingOrderStatusEnum> | '' = ''
  date = [dayjs().startOf('month').toISOString(), dayjs().startOf('day').toISOString()];
  tabList = TabListEnum;
  tab: Tab[] = [
    { id: TabListEnum.ShippingOrderHistory, label: '卖币' },
    { id: TabListEnum.PurchaseOrderHistory, label: '买币' },
  ];
  tabId = TabListEnum.ShippingOrderHistory;

  constructor(
    public lang: LanguageService,
    public orderHistorySer: OrderHistoryService,
  ) { }

  ngOnInit(): void {
    this.query();
  }

  async query(args: InvestorHistory_ShippingOrderHistoryArgs & InvestorHistory_PurchaseOrderHistoryArgs = {}) {
    args.input = {
      /**  订单建立开始时间  */
      startTime: dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00'),
      /**  订单建立结束时间  */
      endTime: dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59'),
      orderStatus: this.status ? this.status : undefined,
      // ...args.input
    };
    if (this.tabId === TabListEnum.ShippingOrderHistory) {
      this.orderHistorySer.getShippingOrder(args);
    } else {
      this.orderHistorySer.getPurchaseOrder(args);
    }
  }

  switchTab(id: number): void {
    this.tabId = id;
    this.query();
  }
}

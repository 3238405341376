import { Injectable } from '@angular/core';
import { ConfigService } from 'lib';
import dayjs from 'dayjs';

// Document dayjs: https://github.com/iamkun/dayjs

export enum TimePeriod {
  Today = 'today',
  Yesterday = 'yesterday',
  BeforeYesterday = 'beforeYesterday',
  Week = 'week',
  HalfMonth = 'halfMonth',
  LastHalfMonth = 'lastHalfMonth',
  Month = 'month',
  LastMonth = 'lastMonth',
}

/**
 * 判斷 basis 的時間是否早於 target 的時間
 * @param basis default is today.
 */
export function isBefore(target: dayjs.ConfigType, basis?: dayjs.ConfigType) {
  const basisDayjs = basis ? dayjs(basis) : dayjs();
  return basisDayjs.isBefore(dayjs(target));
}

/**
 * 判斷 basis 的時間是否晚於 target 的時間
 * @param basis default is today.
 */
export function isAfter(target: dayjs.ConfigType, basis?: dayjs.ConfigType) {
  const basisDayjs = basis ? dayjs(basis) : dayjs();
  return basisDayjs.isAfter(dayjs(target));
}

/**
 * 判斷 basis 的時間是否相同於 target 的時間
 * @param basis default is today.
 */
export function isSameDate(target: dayjs.ConfigType, basis?: dayjs.ConfigType) {
  const basisDayjs = basis ? dayjs(dayjs(basis).format('YYYY-MM-DD')) : dayjs();
  const targetDayjs = dayjs(dayjs(target).format('YYYY-MM-DD'));
  return basisDayjs.isSame(targetDayjs);
}

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  optionTimePeriod = [
    { option: TimePeriod.Today, display: '今天' },
    { option: TimePeriod.Yesterday, display: '昨天' },
    // { option: TimePeriod.Month, display: '本月' },
  ];
  lang = {
    timeToday: null,
    timeYesterday: null,
    timeAm: null,
    timePm: null,
    timeThisWeek: null,
    timeThisMonth: null,
    timeBeforeYesterday: null,
    timeCurrentHalfMonth: null,
    timeLastHalfMonth: null,
    timeLastMonth: null,
  };
  constructor(
    private configServ: ConfigService,
  ) {
    // this.initLang();
  }
  private async initLang() {
    // for (const item of Object.keys(this.lang)) {
    //   this.lang[item] = await this.translate.get(this.translations.chat[item]).toPromise();
    // }

    // switch (this.configServ.envConfiguration.vision) {
    //   case VisionType.web:
    //     switch (this.configServ.getPlatformName()) {
    //       case 'pjp':
    //       case 'pjt':
    //         this.optionTimePeriod = [
    //           { option: TimePeriod.Today, display: this.lang.timeToday },
    //           { option: TimePeriod.Yesterday, display: this.lang.timeYesterday },
    //           { option: TimePeriod.Month, display: this.lang.timeThisMonth },
    //         ];
    //         break;
    //       default:
    //         this.optionTimePeriod = [
    //           { option: TimePeriod.Today, display: this.lang.timeToday },
    //           { option: TimePeriod.Yesterday, display: this.lang.timeYesterday },
    //           { option: TimePeriod.BeforeYesterday, display: this.lang.timeBeforeYesterday },
    //           { option: TimePeriod.Week, display: this.lang.timeThisWeek },
    //           { option: TimePeriod.HalfMonth, display: this.lang.timeCurrentHalfMonth },
    //           { option: TimePeriod.LastHalfMonth, display: this.lang.timeLastHalfMonth },
    //           { option: TimePeriod.Month, display: this.lang.timeThisMonth },
    //           { option: TimePeriod.LastMonth, display: this.lang.timeLastMonth },
    //         ];
    //         break;
    //     }
    //     break;
    //   case VisionType.app:
    //     this.optionTimePeriod = [
    //       { option: TimePeriod.Today, display: this.lang.timeToday },
    //       { option: TimePeriod.Yesterday, display: this.lang.timeYesterday },
    //       { option: TimePeriod.Month, display: this.lang.timeThisMonth },
    //     ];
    //     break;
    // }
  }

  private get dateLine() {
    return '00:00:00';
  }

  private get defaultDateFormat() {
    return `YYYY-MM-DD ${this.dateLine}`;
  }

  private getOffsetDayjs(offset: number) {
    // "03:00:00" => ["03", "00", "00"] => [3, 0, 0]
    const [offsetHour, offsetMinute, offsetSecond] = this.dateLine.split(':').map(val => parseInt(val, 10));
    return dayjs()
      .subtract(offsetHour, 'hour')
      .subtract(offsetMinute, 'minute')
      .subtract(offsetSecond, 'second')
      .add(offset * 24, 'hour');
  }

  getOffsetDateFormatted(offset: number, format = this.defaultDateFormat) {
    return this.getOffsetDayjs(offset).format(format);
  }

  format(target: dayjs.ConfigType, format = this.defaultDateFormat) {
    return dayjs(target).format(format).replace('AM', this.lang.timeAm || '上午').replace('PM', this.lang.timePm || '下午');
  }

  getTimePeriod(period: TimePeriod) {
    let startTime: string;
    let endTime: string;
    switch (period) {
      case TimePeriod.Today:
        startTime = this.getOffsetDateFormatted(0);
        endTime = this.getOffsetDateFormatted(1);
        break;
      case TimePeriod.Yesterday:
        startTime = this.getOffsetDateFormatted(-1);
        endTime = this.getOffsetDateFormatted(0);
        break;
      case TimePeriod.BeforeYesterday:
        startTime = this.getOffsetDateFormatted(-2);
        endTime = this.getOffsetDateFormatted(-1);
        break;
      case TimePeriod.Week: {
        const today = this.getOffsetDayjs(0);
        startTime = this.format(today.add((1 - (today.day() || 7)) * 24, 'hour'));
        endTime = this.format(dayjs(startTime).add(1, 'week'));
        break;
      }
      case TimePeriod.HalfMonth: {
        const today = this.getOffsetDayjs(0);
        // 如果今天是 16 號到下個月之前
        if (today.date() > 15) {
          // 起始時間是 16 號
          startTime = this.format(dayjs(today.set('date', 16)));
          // 結束時間是 下個月 1 號
          endTime = this.format(dayjs(today.startOf('month').add(1, 'month')));
        } else {
          // 如果今天是 1 ~ 15 號
          // 起始時間是 1 號
          startTime = this.format(dayjs(today.startOf('month')));
          // 結束時間是 16 號
          endTime = this.format(dayjs(today.set('date', 16)));
        }
        break;
      }
      case TimePeriod.LastHalfMonth: {
        const today = this.getOffsetDayjs(0);
        // 如果今天是 16 號到下個月之前
        if (today.date() > 15) {
          // 起始時間是 1 號
          startTime = this.format(dayjs(today.startOf('month')));
          // 結束時間是 16 號
          endTime = this.format(dayjs(today.set('date', 16)));
        } else {
          // 如果今天是 1 ~ 15 號
          // 起始時間是 上個月 16 號
          startTime = this.format(dayjs(today.set('date', 16).subtract(1, 'month')));
          // 結束時間是 當月 1 號
          endTime = this.format(dayjs(today.startOf('month')));
        }
        break;
      }
      case TimePeriod.Month:
        startTime = this.format(this.getOffsetDayjs(0).startOf('month'));
        endTime = this.format(this.getOffsetDayjs(0).add(1, 'month').startOf('month'));
        break;
      case TimePeriod.LastMonth:
        startTime = this.format(this.getOffsetDayjs(0).subtract(1, 'month').startOf('month'));
        endTime = this.format(this.getOffsetDayjs(0).startOf('month'));
        break;
    }
    return { startTime, endTime };
  }

  getMsgSendTime(value: dayjs.ConfigType) {
    const today = dayjs();
    const yesterday = today.subtract(24, 'hour');
    const sendTime = dayjs(value);

    if (isSameDate(sendTime, today)) {
      return sendTime.format('A h:mm').replace('AM', this.lang.timeAm || '上午').replace('PM', this.lang.timePm || '下午');
    } else if (isSameDate(sendTime, yesterday)) {
      return this.lang.timeYesterday;
    } else {
      return sendTime.format('YYYY-M-D');
    }
  }
}

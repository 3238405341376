import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CdkPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  showBottom = new BehaviorSubject(false);
  showTop = new BehaviorSubject(false);
  topPortal: CdkPortal | undefined;

  constructor(router: Router, route: ActivatedRoute) {
    router.events.pipe(
    ).subscribe(e => {
      switch (e.constructor) {
        case NavigationStart:
          // console.log('NavigationStart this.topPortal = null;');
          // 換頁清除上方 nav
          // this.topPortal = null;
          break;
        case NavigationEnd:
          // console.log('NavigationEnd.');
          // filter slash. /home -> home
          const path = router.url.substring(1);
          const currRoute = this.getCurrentPathRoute(path, route.root);

          this.showBottom.next(currRoute.showBottom);
          break;
      }
    });
  }

  // 尾遞迴找出當前路由的路由配置
  getCurrentPathRoute(path: string, route: ActivatedRoute): { showBottom: boolean } {
    if (route.firstChild) {
      return this.getCurrentPathRoute(path, route.firstChild);
    } else {
      return { showBottom: false, ...(route.snapshot.data || {}) };
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalOverlayRef } from 'lib';
import { notifierType } from 'lib/types/interface.message';

@Component({
  selector: 'kr-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {
  @Input() text: string = '';
  @Input() type: notifierType = '';

  constructor(
    public modalOverlayRef: ModalOverlayRef
  ) { }

  ngOnInit() {
  }

  close() {
    this.modalOverlayRef.close();
  }
}

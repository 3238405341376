import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ModalService } from 'lib';
import { Query } from 'lib/src/types/schema';
import { interval, Subscription, timer } from 'rxjs';
import { IdleModalComponent } from '../component/idle-modal/idle-modal.component';
import { IDLE_CONFIG_QUERY, REPORT_HEARTBEAT_MUTATION } from '../helpers/api';
import { OrderSettingService } from './order-setting.service';

@Injectable({
  providedIn: 'root'
})
export class IdleDetectionService {
  private nextTick: number; 
  private idleAt: number | null;
  idleCountDown: string;
  idleCountDownSeconds: number;
  private isModalShow = false;
  private timer: Subscription;

  config = {
    idleTime: 30,
    showIdleModalSeconds: 10,
    hearbeatsInterval: 5
  }

  constructor(
    private modalSer: ModalService,
    private apollo: Apollo,
    private orderSettingSer: OrderSettingService
  ) {
    this.init();
  }

  async init() {
    await this.loadConfig();

    this.resetTimer.bind(this);
    // DOM Events
    window.onmousemove = this.resetTimer.bind(this);
    window.onmousedown = this.resetTimer.bind(this);  // catches touchscreen presses as well      
    window.ontouchstart = this.resetTimer.bind(this); // catches touchscreen swipes as well 
    window.onclick = this.resetTimer.bind(this);      // catches touchpad clicks as well
    window.onkeydown = this.resetTimer.bind(this);   
    window.addEventListener('scroll', this.resetTimer.bind(this), true); // improved; see comments

    interval(1000).subscribe(() => {
      this.updateCountdown();

      if (this.nextTick == null) {
        this.setNextTick();
      } else if (Date.now() >= this.nextTick) {
        this.setNextTick();
        if (!this.isModalShow) this.ping();
      }
    })

  }

  private async loadConfig() {
    const resp = await this.apollo.query<Query>({ query: IDLE_CONFIG_QUERY }).toPromise();
    if (resp.data) {
      let config = resp.data.config?.investorConfig;
      this.config = {
        idleTime: (config?.investorCloseShippingSeconds || 0) + 10!,
        showIdleModalSeconds: config?.confirmOnlineCountdownSeconds!,
        hearbeatsInterval: config?.heartbeatIntervalSeconds!
      };
    }
    console.log(this.config);
  }

  private updateCountdown() {
    if (this.idleAt == null) return; 
    let countdownSec = this.config.idleTime - Math.floor((Date.now() - this.idleAt) / 1000);
    if (countdownSec <= 0) {
      countdownSec = 0;
      this.idleAt = null;
    }
    const minutes = Math.floor((countdownSec % (60 * 60)) / 60);
    const seconds = Math.floor((countdownSec % 60));
    // console.log(this.idleCountDown);
    this.idleCountDownSeconds = countdownSec;
    this.idleCountDown = `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  }

  private setNextTick() {
    this.nextTick = Date.now() + this.config.hearbeatsInterval * 1000;
  }

  private async ping() {
    console.log('Ping');
    await this.apollo.mutate({mutation: REPORT_HEARTBEAT_MUTATION}).toPromise()
  }

  private showIdleModal() {
    if (this.isModalShow) return;
    this.idleAt = Date.now();
    console.log('顯示閒置視窗');
    this.ping();
    this.modalSer.open(IdleModalComponent, {}, (component) => {
      this.isModalShow = true;
      component.beforeClose = () => {
        this.isModalShow = false;
      }
    });
  }

  private resetTimer() {
    this.timer?.unsubscribe();
    this.timer = timer(this.config.showIdleModalSeconds * 1000).subscribe(async () => {
      console.log('ShowIdleModal');
      await this.orderSettingSer.refetchSetting();
      if (this.orderSettingSer.shippingEnabled.value === true || this.orderSettingSer.purchaseEnabled.value === true) {
        this.showIdleModal();
      }
    })
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvType, MyLoggerLevelEnum, VisionType, IMyLogger, ILogInfoProvider } from '../types';
import { ConfigService } from './config.service';

interface IWindow {
  myInfo: any;
  GGVersion: any;
}

@Injectable()
export class MyLogService implements IMyLogger {

  private provider?: ILogInfoProvider;

  constructor(
    private httpClient: HttpClient,
    private configSer: ConfigService,
  ) {
  }

  set infoProvider(provider: ILogInfoProvider) {
    this.provider = provider;
  }

  async log(level: MyLoggerLevelEnum, obj: object, iterateLimit: number = 30) {

    // curl -X POST \
    //   https://log-server-cloudflare.goodgaming.org \
    //   -H 'Content-Type: application/json' \
    //   -d '{
    //   "projectName": "bj",
    //   "level": "info",
    //   "tag": "bj-app",
    //   "msg": "abccccccc"
    // }'

    const win = window as unknown as IWindow;
    const proj = this.configSer.getPlatformName();
    const vision = VisionType[this.configSer.envConfiguration.vision];
    const mode = this.configSer.envConfiguration.vision === VisionType.web ? '' : `-${this.configSer.envConfiguration.visionMode}`;
    const env = EnvType[this.configSer.envConfiguration.envType];

    const report = {
      ua: navigator.userAgent,
      account: this.provider ? this.provider.account() : 0,
      devid: this.provider ? await this.provider.device_id() : '',
      build: win ? win.myInfo : '',
      native: win && win.GGVersion ? win.GGVersion : '',
      upath: window.location.href,
      msg: obj
    };
    const msg = JSON.stringify(report, this.censor(report, iterateLimit));
    const logServerInfo = this.configSer.envConfiguration.logServer;
    const option = {
      projectName: proj,
      tag: `${proj}-${vision}${mode}-${env}`,
      level: MyLoggerLevelEnum[level],
      msg
    };
    this.httpClient.post(logServerInfo.url, option, { responseType: 'text' }).toPromise();
  }

  private censor(censor: any, limit: number) {
    // https://stackoverflow.com/questions/4816099/chrome-sendrequest-error-typeerror-converting-circular-structure-to-json
    let i = 0;
    return (key: any, value: any) => {
      if (i !== 0 && typeof (censor) === 'object' && typeof (value) === 'object' && censor === value) {
        return '[Circular]';
      }
      if (limit > 0 && i >= limit) {
        return '[Unknown]';
      }
      ++i;
      return value;
    };
  }
}

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'lib';
import { PaginatorInfo, PurchaseOrder, PurchasePaymentTypeEnum } from 'lib/src/types/schema';
import { LanguageService } from '../../../shared/services/language.service';
import { OrderHistoryService } from '../../services/order-history.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'kr-purchase-order-history',
  templateUrl: './purchase-order-history.component.html',
  styleUrls: ['./purchase-order-history.component.scss']
})
export class PurchaseOrderHistoryComponent implements OnInit {
  purchaseHistory: PurchaseOrder[];
  paginatorInfo: PaginatorInfo;
  currentPage: number;
  pageSize: number;
  pageSizeOptions: number[];
  total: number = 1;
  loading = false;

  constructor(
    private clipbaord: Clipboard,
    private messageSer: MessageService,
    public lang: LanguageService,
    public paymentSer: PaymentService,
    public orderHistorySer: OrderHistoryService,
  ) { }

  ngOnInit(): void {
    this.currentPage = this.orderHistorySer.currentPage;
    this.pageSizeOptions = this.orderHistorySer.pageSizeOptions;
    this.pageSize = this.pageSizeOptions[0];
    this.orderHistorySer.purchaseOrderHistory.subscribe(purchaseHistory => {
      this.purchaseHistory = purchaseHistory!;
    });
    this.orderHistorySer.purchaseOrderPaginatorInfo.subscribe(paginatorInfo => {
      this.paginatorInfo = paginatorInfo!;
      this.currentPage = this.paginatorInfo?.currentPage! || 0;
      this.total = this.paginatorInfo?.total! || 0;
    })
  }

  market(data: PurchaseOrder): string {
    switch (data.paymentType) {
      case PurchasePaymentTypeEnum.Yoho:
        return '银行卡';
      case PurchasePaymentTypeEnum.AlipayH5Purchase:
        return '支付宝';
      default:
        return '';
    }
  }

  payeeInformation(data: PurchaseOrder) {
    switch (data.paymentType) {
      case PurchasePaymentTypeEnum.Yoho:
        const payeeBank = this.paymentSer.bankMap$.value[data.payeeBankId || '-']?.name || '';
        return `${payeeBank} ${data.payeeBankAccount || ''} ${data.payeeBankAccountName || ''}`;
      case PurchasePaymentTypeEnum.AlipayH5Purchase:
        return `${data.payeeAlipayAccount || ''} ${data.payeeAlipayName || ''}`;
      default:
        return '';
    }
  }

  payerInformation(data: PurchaseOrder) {
    switch (data.paymentType) {
      case PurchasePaymentTypeEnum.Yoho:
        const payerBank = this.paymentSer.bankMap$.value[data.payerBankId || '-']?.name || '';
        return `${payerBank} ${data.payerBankBranchName || ''} ${data.payerBankAccount || ''} ${data.payerBankAccountName || ''}`;
      case PurchasePaymentTypeEnum.AlipayH5Purchase:
        return `${data.payerAlipayAccount || ''} ${data.payerAlipayName || ''}`;
      default:
        return '';
    }
  }

  sizeChagne(first: number) {
    this.pageSize = first;
    this.orderHistorySer.getPurchaseOrder({ page: 1, first: this.pageSize });
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.orderHistorySer.getPurchaseOrder({ page: this.currentPage, first: this.pageSize });
  }

  copy(text: string) {
    this.clipbaord.copy(text);
    this.messageSer.notify('已複製', 600);
  }
}

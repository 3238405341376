import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'lib';
import { SEND_AUTH_CODE_MUTATION } from '../helpers/api';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SmsAuthService {
  resendCountDown = 0;
  constructor(
    private apollo: Apollo,
    private appConfigSer: AppConfigService,
    private messageSer: MessageService
  ) {
    setInterval(() => {
      if (this.resendCountDown > 0) this.resendCountDown -= 1;
    }, 1000);
  }

  // 不傳號碼預設傳到當前登入帳號已綁定的手機
  sendSmsAuthCode(mobileNumber: String = '') {
    if(this.resendCountDown > 0) return;
    this.resendCountDown = this.appConfigSer.config.value?.smsConfig?.resendSeconds || 30;
    this.apollo.mutate({
      mutation: SEND_AUTH_CODE_MUTATION,
      variables: {
        input: {
          mobileNumber
        }
      }
    }).subscribe(resp => {
      this.messageSer.notify("已發送");
    })
  }
}

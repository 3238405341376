import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalService } from 'lib';
import { InvestorPaymentTypeEnum, Mutation, OrderTypeEnum, Query, SetOrderEnabledInput } from 'lib/src/types/schema';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SetPurchaseOrderAmountLimitComponent } from '../component/set-purchase-order-amount-limit/set-purchase-order-amount-limit.component';
import { SETTING_QUERY, SET_ORDER_ENABLED_MUTATION } from '../helpers/api';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class OrderSettingService {
  shippingEnabled = new BehaviorSubject<boolean>(false);
  purchaseEnabled = new BehaviorSubject<boolean>(false);
  activeShippingPaymentTypes = new BehaviorSubject<InvestorPaymentTypeEnum[]>([]);
  activePurchasePaymentTypes = new BehaviorSubject<InvestorPaymentTypeEnum[]>([]);
  investorShippingPaymentTypes = new BehaviorSubject<InvestorPaymentTypeEnum[]>([]);
  investorPurchasePaymentTypes = new BehaviorSubject<InvestorPaymentTypeEnum[]>([]);
  orderEnableLoading = {
    [OrderTypeEnum.PurchaseOrder]: false,
    [OrderTypeEnum.ShippingOrder]: false,
  };
  private settingQuery = this.apollo.watchQuery<Query>({
    query: SETTING_QUERY,
    pollInterval: 15000
  });
  constructor(
    private apollo: Apollo,
    private modalSer: ModalService,
    private paymentSer: PaymentService,
    private messageSer: MessageService
  ) {
    this.settingQuery.valueChanges.subscribe(resp => {
      const me = resp.data.me;
      this.purchaseEnabled.next(me?.purchaseEnabled!);
      this.shippingEnabled.next(me?.shippingEnabled!);
      this.activeShippingPaymentTypes.next(me?.activeShippingPaymentTypes!);
      this.activePurchasePaymentTypes.next(me?.activePurchasePaymentTypes!);
    })
  }

  refetchSetting() {
    return this.settingQuery.refetch();
  }

  toggleOrderEnabled(event: any, orderType: OrderTypeEnum, investorPaymentTypes: InvestorPaymentTypeEnum[]) {
    if (this.orderEnableLoading[orderType]) return;
    this.setOrderEnableLoading(true, orderType);
    this.changeEnabledState(event, orderType);
    if (orderType === OrderTypeEnum.PurchaseOrder && event === true) {
      this.showSetPurchaseOrderAmountLimit(event, orderType, investorPaymentTypes);
    } else {
      this.setOrderEnabled({ orderType: orderType, enabled: event, investorPaymentTypes: investorPaymentTypes }).subscribe({
        complete: () => { this.setOrderEnableLoading(false, orderType); }
      });
    }
  }

  showSetPurchaseOrderAmountLimit(event: any, orderType: OrderTypeEnum, investorPaymentTypes: InvestorPaymentTypeEnum[]) {
    this.modalSer.open(SetPurchaseOrderAmountLimitComponent,
      { hasBackdrop: true },
      (compoenent) => {
        compoenent.afterSuccess = () => {
          if (event) {
            this.setOrderEnabled({ orderType: orderType, enabled: event, investorPaymentTypes: investorPaymentTypes }).subscribe({
              complete: () => { this.setOrderEnableLoading(false, orderType); }
            });
          } else {
            this.setOrderEnableLoading(false, orderType);
          }
        };
        compoenent.afterCancel = () => {
          this.changeEnabledState(false, orderType);
          this.setOrderEnableLoading(false, orderType);
        }
      }
    );
  }

  private setOrderEnableLoading(state: boolean, orderType: OrderTypeEnum): void {
    this.orderEnableLoading[orderType] = state;
  }

  // 接單開關
  private setOrderEnabled(input: SetOrderEnabledInput) {
    if (input.enabled === true) {
      const paymentType: InvestorPaymentTypeEnum[] = input.investorPaymentTypes;
      if (
        paymentType.includes(InvestorPaymentTypeEnum.BankAccount) &&
        paymentType.includes(InvestorPaymentTypeEnum.Alipay) &&
        paymentType.includes(InvestorPaymentTypeEnum.AlipayScan)
      ) {
        if (!this.hasAnyEnabledPaymentAccount() && !this.hasAnyEnabledAlipayPaymentAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.messageSer.notify('请先启用银行卡和启用支付宝');
          return of('请先绑定银行卡和绑定支付宝');
        } else if (!this.hasAnyEnabledPaymentAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.paymentMsg();
          return of('请先绑定银行卡');
        } else if (!this.hasAnyEnabledAlipayPaymentAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.alipayPaymentMsg();
          return of('请先绑定支付宝');
        } else if (!this.hasAnyEnabledAlipayScanAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.alipayScanMsg();
          return of('请先绑定支付宝收款码');
        }
      } else if (paymentType.includes(InvestorPaymentTypeEnum.BankAccount)) {
        if (!this.hasAnyEnabledPaymentAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.paymentMsg();
          return of('请先绑定银行卡');
        }
      } else if (paymentType.includes(InvestorPaymentTypeEnum.Alipay)) {
        if (!this.hasAnyEnabledAlipayPaymentAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.alipayPaymentMsg();
          return of('请先绑定支付宝');
        }
      } else if (paymentType.includes(InvestorPaymentTypeEnum.AlipayScan)) {
        if (!this.hasAnyEnabledAlipayScanAccount()) {
          setTimeout(() => {
            this.changeEnabledState(false, input.orderType);
          }, 500);
          this.alipayScanMsg();
          return of('请先绑定支付宝收款码');
        }
      }
    }
    return this.apollo.mutate<Mutation>({
      mutation: SET_ORDER_ENABLED_MUTATION,
      variables: { input },
    }).pipe(
      tap((resp) => { }),
      catchError(error => {
        this.changeEnabledState(!(input.enabled), input.orderType);
        return of(error);
      })
    )
  }

  paymentMsg() {
    this.messageSer.notify('请先启用银行卡');
  }

  alipayPaymentMsg() {
    this.messageSer.notify('请先启用支付宝');
  }

  alipayScanMsg() {
    this.messageSer.notify('请先启用支付宝收款码');
  }

  private changeEnabledState(state: boolean, type: OrderTypeEnum) {
    switch (type) {
      case OrderTypeEnum.PurchaseOrder:
        this.purchaseEnabled.next(state);
        break;
      case OrderTypeEnum.ShippingOrder:
        this.shippingEnabled.next(state);
        break;
    }
  }

  private hasAnyEnabledPaymentAccount(): boolean {
    return this.paymentSer.paymentAccounts?.some(payment => payment.status === 'ENABLED');
  }

  private hasAnyEnabledAlipayPaymentAccount(): boolean {
    return this.paymentSer.alipayPaymentAccounts?.some(payment => payment.status === 'ENABLED');
  }

  private hasAnyEnabledAlipayScanAccount(): boolean {
    return this.paymentSer.alipayScanAccounts?.some(payment => payment.status === 'ENABLED');
  }
}

import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { CurrencyTypeEnum, CurrencyTypeInput, InvestorWallet, InvestorWalletsInput, LevelGroupQuota, Maybe, Query } from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { WALLETS_QUERY } from '../helpers/api';


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private walletsQueryRef: QueryRef<Query>;
  currencyType = CurrencyTypeEnum.Cny;
  wallet = new BehaviorSubject<Maybe<InvestorWallet>>(null);
  usdtWallet = new BehaviorSubject<Maybe<InvestorWallet>>(null);
  quota = new BehaviorSubject<Maybe<LevelGroupQuota>>(null);
  usdtQuota = new BehaviorSubject<Maybe<LevelGroupQuota>>(null);
  defaultGatewayRate = new BehaviorSubject<Maybe<number>>(0);
  defaultGatewaySellRate = new BehaviorSubject<Maybe<number>>(0);
  defaultGatewayDepositRate = new BehaviorSubject<Maybe<number>>(0);
  loading = false;

  constructor(
    private apollo: Apollo,
  ) {
    const currencyTypes = {currencyTypes: [CurrencyTypeEnum.Cny, CurrencyTypeEnum.Usdt]};
    this.walletsQueryRef = this.walletsQuery(currencyTypes, currencyTypes);
    this.walletsQueryRef.valueChanges.subscribe(resp => {
      // 先寫死抓CNY錢包
      if (resp.data.me?.wallets[0]) {
        const cnyWallet = resp.data.me?.wallets?.find(x => x?.currencyType === CurrencyTypeEnum.Cny);
        const usdtWallet = resp.data.me?.wallets?.find(x => x?.currencyType === CurrencyTypeEnum.Usdt);
        this.wallet.next(cnyWallet!);
        this.usdtWallet.next(usdtWallet!);
      }
      if (resp.data.me?.financeInfo?.levelGroupQuotas[0]) {
        const cnyQuota = resp.data.me?.financeInfo?.levelGroupQuotas?.find(x => x?.currencyType === CurrencyTypeEnum.Cny);
        const usdtQuota = resp.data.me?.financeInfo?.levelGroupQuotas?.find(x => x?.currencyType === CurrencyTypeEnum.Usdt);
        this.quota.next(cnyQuota!);
        this.usdtQuota.next(usdtQuota!);
      }
      if (resp.data.me?.exchange) {
        this.defaultGatewayRate.next(resp.data.me?.exchange.defaultGatewayRate!);
        this.defaultGatewaySellRate.next(resp.data.me?.exchange.defaultGatewaySellRate!);
        this.defaultGatewayDepositRate.next(resp.data.me?.exchange.defaultGatewayDepositRate!);
      }
    });
  }

  async refetchWallets() {
    this.setLoadingTimeout(true);
    try {
      await this.walletsQueryRef.refetch();
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoadingTimeout(false);
    }
  }

  private setLoadingTimeout(state: boolean) {
    setTimeout(() => {
      this.loading = state;
    }, 100);
  }

  private walletsQuery(input: InvestorWalletsInput, infoInput: CurrencyTypeInput) {
    return this.apollo.watchQuery<Query>({
      query: WALLETS_QUERY,
      variables: { input, infoInput },
      pollInterval: 10000,
      fetchPolicy: 'no-cache'
    })
  }
}

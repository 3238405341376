import { IConfig } from 'lib/src/types';
import { EnvType } from 'lib/src/types';
import { configBase } from './environment.base';

const config = {
  isTestEnv: false,
  envType: EnvType.prod,
  pusherKey: 'd9b6917b0b64c0ccc64e',
};

const c = Object.assign({}, configBase, config);

export const envConfig = c as IConfig;

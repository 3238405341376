import { Component, Input, OnInit } from '@angular/core';
import { ModalOverlayRef } from 'lib';

@Component({
  selector: 'kr-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() textAsHtml: boolean = false;
  @Input() btnCloseTitle: string = '';
  @Input() btnConfirmTitle: string = '';
  @Input() onYes: () => any = () => { };
  @Input() onNo: () => any = () => { };

  isVisible: boolean = true;
  isConfirmLoading: boolean = false;

  constructor(
    private overlayRef: ModalOverlayRef
  ) { }

  ngOnInit() { }

  no() {
    this.onNo();
    this.overlayRef.close();
  }

  async yes() {
    await this.onYes();
    this.overlayRef.close();
  }
}
